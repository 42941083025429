import { observer } from 'mobx-react-lite';
import React, {  useEffect, useState } from 'react';
import {  Button, Grid, GridColumn, GridRow,Table, Image, PaginationProps } from 'semantic-ui-react';
import { useStore } from '../../../api/stores/store';
import { UserProfile } from '../../../api/models/userProfile';
import LoadingComponent from '../../UICommon/LoadingComponent';
import UserForm from './UserForm';
import ServerMsgBar from '../../UIItems/ServerMsgBar';
import { ServerResult  } from '../../../api/models/common';
import FormPaging from '../../UIItems/FormPaging';
import { PagingParams } from '../../../api/models/pagination';


export default observer(function UserList()
{
    const {userProfileStore,globalStore} = useStore();
    const {activeUser,getIsAdmin} = globalStore
    const{getUserProfile,selectedUser,userProfiles,loadUserProfiles,applySort,unselectUser,usersSortingParams,usersPagination,setPagingParams} = userProfileStore;
    const [showUserForm, setShowUserForm,] = useState(false);
    const [responseState, setResponseState] = useState<ServerResult  | undefined>(undefined );
    const isAdmin: boolean = getIsAdmin();
    const [loadingNext, setLoadingNext] = useState(false);
    const addNewUser = () => {
        unselectUser()
        setShowUserForm(true);
    };

    const userSettings = (id:string) => {
        getUserProfile(id)
        setShowUserForm(true);
    };
    function handleCloseUserForm ()
    {
        setShowUserForm(false);
    }
    function setResponseStateReceived (responseState: ServerResult |null)
    {
        if (responseState)
            setResponseState(responseState);
    }
   
    function hideResponseState()
    {
        if (responseState)
        setResponseState( undefined);
    }
    useEffect(()=>{ 
        window.scrollTo(0, 0)
        if(userProfiles.length === 0 ) 
        {
            loadUserProfiles();
        };
    },[])
  
    function runPaging(
        event: React.MouseEvent<HTMLAnchorElement>,
        data: PaginationProps
      ) {
        var activePage: number = +data.activePage!;
        setLoadingNext(true);
        setPagingParams(new PagingParams(activePage));
        loadUserProfiles().then(() => setLoadingNext(false));
      }
    
    if(userProfileStore.loadinInitial) return <LoadingComponent content='Loading App'/>

    return(
<>
        <Grid className='mainPageGrid' onClick={hideResponseState}>
                <GridRow  >
                        <GridColumn width='11' className='pageTitleColumn'>
                                   <span className='pageTitle'>Users</span> 
                                   {isAdmin &&  (
                                        <Button   type='button' content=' + Add User' className={showUserForm?'activeAddButton':'addButton'} onClick ={addNewUser} />
                                   )}
                          </GridColumn>
                </GridRow>
                <GridRow >
                        <GridColumn >
                                <>
                                <Table   stackable   sortable    >
                                    <Table.Header >
                                            <Table.Row   >
                                                <Table.HeaderCell    className="firstColumn anchor"
                                                        sorted={usersSortingParams.sortField === 'displayName' ? usersSortingParams.sortAscending === 'true'? 'ascending':'descending' : undefined}
                                                        onClick={() => applySort('displayName') } > 
                                                        Display Name
                                                </Table.HeaderCell>
                                                <Table.HeaderCell  
                                                        sorted={usersSortingParams.sortField === 'companyName' ? usersSortingParams.sortAscending === 'true'? 'ascending':'descending' : undefined}
                                                        onClick={() => applySort('companyName') } >
                                                        Company
                                                    </Table.HeaderCell>
                                                <Table.HeaderCell  
                                                        sorted={usersSortingParams.sortField === 'Level' ? usersSortingParams.sortAscending === 'true'? 'ascending':'descending' : undefined}
                                                        onClick={() => applySort('Level') } >
                                                        Level
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell  className='nonSortable'>
                                                        Email
                                                    </Table.HeaderCell> 
                                                    <Table.HeaderCell  className='nonSortable'>
                                                         Phone
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell className="lastColumn nonSortable"></Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                        {userProfiles.map((user:UserProfile) => (
                                            <Table.Row key={user.id} className='tableRow' >
                                            <Table.Cell width={4}>
                                                     {user.displayName}
                                                </Table.Cell>
                                                <Table.Cell width={4}>{user.companyName}</Table.Cell>
                                                <Table.Cell width={4}>{user.levelName}</Table.Cell>
                                                <Table.Cell width={5}>{user.email}</Table.Cell>
                                                <Table.Cell width={3}> {user.phoneNumber} </Table.Cell>
                                                <Table.Cell style={{ width: '19px' }}>
                                                     {activeUser && ((activeUser.level < user.level) || (activeUser.id === user.id)) &&
                                                            <Image
                                                                src="/img/edit.svg"
                                                                className="hidenIcon"
                                                                onClick={() =>userSettings(user.id)}  /> }
                                                </Table.Cell>
                                        </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                                </>
                 
                        </GridColumn>
                </GridRow>          
                <GridRow>
                <FormPaging
                    currentPage={usersPagination.currentPage}
                    totalPages={usersPagination.totalPages}
                    onPageChange={runPaging}
          />
        </GridRow>
                {responseState &&  (
                <ServerMsgBar runActionResult={responseState}/>
              )}   
        </Grid>
              {showUserForm &&(
                <UserForm 
                    hideBox={()=>handleCloseUserForm()}    
                    newUser={selectedUser === undefined  }
                    setResponseState={setResponseStateReceived}/>
               )}                    
</>

    )
})