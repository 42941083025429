import { observer } from 'mobx-react-lite';
import React, { useEffect, useState }  from 'react'
import {   Grid,GridRow ,Label, Icon, Input, GridColumn, Button} from 'semantic-ui-react'
import { useStore } from '../../../api/stores/store';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchTextBox from '../../UIItems/SearchTextBox';
import { CruTextOptions, StatusOptions } from '../../../api/models/enums';
import { FORMAL_DAY_FORMAT } from '../../../api/options/constants';
import { FilterState } from '../../../api/models/common';

type DevicesFilterProperties = {
  hideFilter: () => void;
  filtersState: FilterState;
  setFiltersState: React.Dispatch<React.SetStateAction<FilterState>>;
};
export default observer(function DevicesFilter(filterProps: DevicesFilterProperties) {
   
const { deviceStore ,filterStore} = useStore();
const [showSearchCRU,setShowSearchCRU] = useState(false);
const {setOptionFilterItem,resetSentFilter,setFilterItem, loadDevices} = deviceStore
const {handleStatusChanged,handlFromDate, handlToDate,clearFilter,getFormatedDate} = filterStore;
const [inputValue, setInputValue] = useState('');

function onChangeSelectedText(id: number, type: number, name:string) {
  setInputValue(name);
  filterStore.handlSelectedText(id,type,name,filterProps.setFiltersState);
  if (type !== CruTextOptions.CompanyName) {
      filterProps.filtersState.deviceId = id;
  }
  setShowSearchCRU(false);
}

function applyFilter()
{
      resetSentFilter();
      if (filterProps.filtersState.frequencyList)
      {
        filterProps.filtersState.frequencyList.forEach((element) => {
              setOptionFilterItem('frequency',element.toString());
        });
      }
      if (filterProps.filtersState.statusList)
      {
        filterProps.filtersState.statusList.forEach((element) => {
              setOptionFilterItem('statusList',element.toString());
        });
      }
      if (filterProps.filtersState.fromDate) setFilterItem('fromDate', getFormatedDate(filterProps.filtersState.fromDate));
      if (filterProps.filtersState.toDate) setFilterItem('toDate',  getFormatedDate(filterProps.filtersState.toDate));
      
      if (filterProps.filtersState.deviceId) 
        setFilterItem('id', filterProps.filtersState.deviceId);
      if (filterProps.filtersState.searchTxt) 
          setFilterItem('searchTxt', filterProps.filtersState.searchTxt);
      if (filterProps.filtersState.companyId) 
          setFilterItem('companyId', filterProps.filtersState.companyId);

      loadDevices();
      filterProps.hideFilter();
}

function clearDevicesFilter()
{
      clearFilter(filterProps.setFiltersState);
      setInputValue('');
      resetSentFilter();
      loadDevices();
}
useEffect(() => {
  if (filterProps.filtersState.searchTxt)
       setInputValue(filterProps.filtersState.searchTxt)
 }, [])
  return ( <>
      <Grid className='filterPanel small' columns={2}  >
                <GridRow >
                    <Grid.Column width={13} >
                              <Label basic className='filtersLabel'>
                                <Icon name='sliders'></Icon>
                                Filters 
                                </Label>
                   </Grid.Column>
                   <Grid.Column  width={3} >
                              <Icon name='close' onClick={()=>filterProps.hideFilter()} className='closeWindow'></Icon>
                    </Grid.Column>
                </GridRow>
                <GridRow >
                    <Grid.Column  width={16} >
                            <Input 
                                    iconPosition='left' 
                                    name='searchImput'   
                                    className='searchText'  
                                    placeholder='Search for SN or computer name' 
                                    onChange={(event, data) => {
                                      setInputValue(data.value);
                                      filterStore. searchByText(data.value, setShowSearchCRU, filterProps.setFiltersState);
                                    }}
                                    value={inputValue}>
                            <Icon name='search' />
                                <input />
                           </Input>          
                  </Grid.Column>
                  {showSearchCRU &&(<SearchTextBox handlSelectUnit={onChangeSelectedText} />)}
                <br/>
                </GridRow >
                <GridRow >  <Grid.Column  width={16} ><span className='filtersTitle'>By Status</span></Grid.Column></GridRow>
                <GridRow>  
                    <Grid.Column   width={6}>
                            <Button
                                      icon type="button"
                                      className={
                                      `filtersOption ${filterProps.filtersState.statusList!.indexOf(StatusOptions.GeneratedSN) !== -1  ? ' selected' : ''  }`}
                                      onClick={()=>handleStatusChanged(StatusOptions.GeneratedSN, filterProps.filtersState,filterProps.setFiltersState)} >
                                      <Icon name="circle" className='statusGeneratedSN' />
                                      GeneratedSN
                          </Button>

                      </Grid.Column>      
                      <Grid.Column   width={6}>
                          <Button
                                      icon type="button"
                                      className={
                                      `filtersOption ${filterProps.filtersState.statusList!.indexOf(StatusOptions.Published) !== -1  ? ' selected' : ''  }`}
                                      onClick={()=>handleStatusChanged(StatusOptions.Published, filterProps.filtersState,filterProps.setFiltersState)} >
                                      <Icon name="circle" className='statusPublished' />
                                      Published
                          </Button>                         
                       </Grid.Column>      
                    
                  </GridRow>       
                  <GridRow >
                         <Grid.Column width={6}>
                         <Button
                                      icon type="button"
                                      className={
                                      `filtersOption ${filterProps.filtersState.statusList!.indexOf(StatusOptions.Registered) !== -1  ? ' selected' : ''  }`}
                                      onClick={()=>handleStatusChanged(StatusOptions.Registered, filterProps.filtersState,filterProps.setFiltersState)} >
                                      <Icon name="circle" className='statusRegistered' />
                                      Registered
                          </Button>                          

                        </Grid.Column>      
                        <Grid.Column   width={8}>
                        <Button
                                      icon type="button"
                                      className={
                                      `filtersOption ${filterProps.filtersState.statusList!.indexOf(StatusOptions.RegistrationFailed) !== -1  ? ' selected' : ''  }`}
                                      onClick={()=>handleStatusChanged(StatusOptions.RegistrationFailed, filterProps.filtersState,filterProps.setFiltersState)} >
                                      <Icon name="circle" className='statusRegistrationFailed' />
                                      Registration Failed
                          </Button>                            

                         </Grid.Column>
                  </GridRow>
                <GridRow> 
                     <Grid.Column  width={16} ><span className='filtersTitle'>Published</span></Grid.Column></GridRow>
                <GridRow > 
                  <GridColumn  width={8} > 
                          <Label basic className='filtersLabel'>From</Label>
                          <DatePicker className='calendarImput'
                                          selected={filterProps.filtersState.fromDate}
                                            onChange={(date) => handlFromDate(date,filterProps.setFiltersState)}
                                            isClearable
                                            dateFormat={FORMAL_DAY_FORMAT}
                                            placeholderText={FORMAL_DAY_FORMAT.toUpperCase()}
                                            selectsStart
                                            startDate={filterProps.filtersState.fromDate}
                                            endDate={filterProps.filtersState.toDate}
                                            maxDate={filterProps.filtersState.toDate}
                                      />
                   </GridColumn>
                   <GridColumn  width={8} > 
                          <Label basic className='filtersLabel'>To</Label>
                          <DatePicker className='calendarImput' 
                              selected={filterProps.filtersState.toDate} 
                              isClearable
                              dateFormat={FORMAL_DAY_FORMAT}   
                              onChange={(date) => handlToDate(date,filterProps.setFiltersState)}  
                              placeholderText={FORMAL_DAY_FORMAT.toUpperCase()}
                              selectsEnd
                              startDate={filterProps.filtersState.fromDate}
                              endDate={filterProps.filtersState.toDate}
                              minDate={filterProps.filtersState.fromDate}
                             />
                   </GridColumn>
                </GridRow>
                <GridRow className='buttonsRow'>
                  <Grid.Column width={10}>
                      <Label basic className="resetBtn" onClick={clearDevicesFilter}>
                      Clear all
                    </Label>
                  </Grid.Column>
                    <Grid.Column  width={5}>
                          <Button  content="Apply"   type="button"   onClick={() => {applyFilter() }}
                                        className='applyBtn'/>
                    </Grid.Column>
                </GridRow>
            </Grid>
            </>
   );
});

