import { observer } from 'mobx-react-lite';
import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Grid,
  GridColumn,
  GridRow,
  PaginationProps,
  Table,
  Image,
} from 'semantic-ui-react';
import FormPaging from '../../UIItems/FormPaging';
import LoadingComponent from '../../UICommon/LoadingComponent';
import { PagingParams } from '../../../api/models/pagination';
import { useStore } from '../../../api/stores/store';
import DevicesFilter from './DevicesFilter';
import DeviceRegister from './DeviceRegister';
import { EncryptionStatus, FrequencyOptions, PermissionLevel, StatusOptions, getStatusOptionDisplayText } from '../../../api/models/enums';
import DeviceForm from './DeviceForm';
import { FilterState, ServerResult } from '../../../api/models/common';
import ServerMsgBar from '../../UIItems/ServerMsgBar';
import VerificationBitLocker from './VerificationBitLocker';
import { RecoveryKeyRequest } from '../../../api/models/device';
import BitLockerKeyList from './BitLockerKeyList';

export default observer(function DevicesList() {
  const { deviceStore, globalStore, accountStore,filterStore } = useStore();
  const {
    loadDevices,
    devices,
    setPagingParams,
    devicePagination,
    loadDevice,
    filterSet,
    applySort,
    deviceSortingParams,setLoadinInitialDevice,
    loadBitLockerKeyList
  } = deviceStore;
  const { activeUser } = globalStore;
  const { setMenuActiveItem } = accountStore;
  const [loadingNext, setLoadingNext] = useState(false);
  const [showDevicesFilter, setShowDevicesFilter] = useState(false);
  const [showAddDevice, setShowAddDevice] = useState(false);
  const [showUpdateDevice, setShowUpdateDevice] = useState(false);
  const [selectedDeviceId, setSelectedDeviceId] = useState('0');
  const [filterState, setFilterState] = useState<FilterState>({
    frequencyList: [],
    statusList: [],
    searchTxt: '',
    fromDate: null,
    toDate: null,
    companyId:undefined,
    deviceId:undefined
  });
  const refFilterBox = useRef<HTMLDivElement>(null);
  const refUpdateAsset = useRef<HTMLDivElement>(null);
  const [responseState, setResponseState] = useState<ServerResult | undefined>(
    undefined
  );
  const [showVerificationBox, setShowVerificationBox] = useState(false);
  const [showBitLockerKeyList, setShowBitLockerKeyList] = useState(false);


  useEffect(() => {
    setMenuActiveItem('Assets');
    setLoadinInitialDevice(true);
    loadDevices();
    if (filterSet && filterSet.size > 0) filterStore.displayFilter(filterSet,setFilterState);
  }, [filterSet]);


  function runPaging(
    event: React.MouseEvent<HTMLAnchorElement>,
    data: PaginationProps
  ) {
    var activePage: number = +data.activePage!;
    setLoadingNext(true);
    setPagingParams(new PagingParams(activePage));
    loadDevices().then(() => setLoadingNext(false));
  }
  function showHideFilter() {
    setShowDevicesFilter(!showDevicesFilter);
  }

  function showHideUpdateAsset(id: string) {
    if (id !== '0')
    {
        loadDevice(id);
    }
    setShowUpdateDevice(!showUpdateDevice);    
  }
  const showAddBox = () => {
    setShowAddDevice(!showAddDevice);
  };
  function setResponseStateReceived(responseState: ServerResult | null) {
    if (responseState) setResponseState(responseState);
  }
  function hideResponseState() {
    if (responseState) setResponseState(undefined);
  }
  function useOnClickOutside(
    ref: React.RefObject<HTMLElement>,
    handler: (event: MouseEvent) => void
  ) {
    useEffect(() => {
      const handleClick = (event: MouseEvent) => {
        if (!ref.current || ref.current.contains(event.target as Node)) {
          return;
        }
        handler(event);
      };
      document.addEventListener('mousedown', handleClick);
      return;
    }, [ref, handler]);
  }
  useOnClickOutside(refFilterBox, showHideFilter);
  useOnClickOutside(refUpdateAsset, () => showHideUpdateAsset('0'));

  if (deviceStore.loadinInitialDevice && !loadingNext)
    return <LoadingComponent content="Loading App " />;

  function handleShowVerificationBox(id: string) {
    setSelectedDeviceId(id);
    setShowVerificationBox(true);    
  }
  function getBitLockerKeyList (verificationCode: string): Promise<void> {
    const request: RecoveryKeyRequest = {
        id: selectedDeviceId.toString(),
        verificationCode: verificationCode
    };
    return loadBitLockerKeyList(request)
        .then(() => {
            setShowVerificationBox(false);    
            setShowBitLockerKeyList(true);
        })
}
  const gridClass = `mainPageGrid ${showVerificationBox || showBitLockerKeyList ? 'modal-open' : ''}`;

  return (
    <>
     {(showVerificationBox ||  showBitLockerKeyList) && (
            <div className="overlay"></div>
      )}
      <Grid  className={gridClass}  columns={2} onClick={hideResponseState}>

        <GridRow>
          <GridColumn width={14} className="pageTitleColumn">
            <span className="pageTitle">Assets</span>
            {activeUser && activeUser.level !== PermissionLevel.User && (
              <Button
                type="button"
                content=" + Add Asset"
                className={showAddDevice ? 'activeAddButton' : 'addButton'}
                onClick={showAddBox}
              />
            )}
          </GridColumn>
          <GridColumn width={2} className="pageTitleColumn">
            <div className="filterBox" onClick={showHideFilter}>
            <Button
                type="button"
                className={`filterButton ${ filterSet.size !== 0 ? 'filterActivated' : ''  }`}
              >
                <span>
                  <Image src="/img/tune.svg"  /> Filters
                </span>
              </Button>
            </div>
          </GridColumn>

          {showDevicesFilter && (
            <div ref={refFilterBox}>
              <DevicesFilter
                hideFilter={showHideFilter}
                filtersState={filterState}
                setFiltersState={setFilterState}
              />
            </div>
          )}

        </GridRow>
        <GridRow>
          <GridColumn className="mainTable">
            <Table stackable sortable style={{ display: 'block' }}>
              <Table.Header className="TableHeader">
                <Table.Row className="tableUpHeader">
                  <Table.Cell colSpan="8">General info</Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell colSpan="2">Last Report</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell
                    className="firstColumn anchor"
                    width={1}
                    sorted={
                      deviceSortingParams.sortField === 'deviceSN'
                        ? deviceSortingParams.sortAscending === 'true'
                          ? 'ascending'
                          : 'descending'
                        : undefined
                    }
                    onClick={() => applySort('deviceSN')}
                  >
                    Device SN
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={2}
                    sorted={
                      deviceSortingParams.sortField === 'computerName'
                        ? deviceSortingParams.sortAscending === 'true'
                          ? 'ascending'
                          : 'descending'
                        : undefined
                    }
                    onClick={() => applySort('computerName')}
                  >
                    Computer Name
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={2}
                    sorted={
                      deviceSortingParams.sortField === 'companyName'
                        ? deviceSortingParams.sortAscending === 'true'
                          ? 'ascending'
                          : 'descending'
                        : undefined
                    }
                    onClick={() => applySort('companyName')}
                  >
                    Company
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={1}
                    sorted={
                      deviceSortingParams.sortField === 'siteLocation'
                        ? deviceSortingParams.sortAscending === 'true'
                          ? 'ascending'
                          : 'descending'
                        : undefined
                    }
                    onClick={() => applySort('siteLocation')}
                  >
                    Site Location
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={1}
                    sorted={
                      deviceSortingParams.sortField === 'frequency'
                        ? deviceSortingParams.sortAscending === 'true'
                          ? 'ascending'
                          : 'descending'
                        : undefined
                    }
                    onClick={() => applySort('frequency')}
                  >
                    Frequency
                  </Table.HeaderCell>

                  <Table.HeaderCell
                    width={2}
                    sorted={
                      deviceSortingParams.sortField === 'statusName'
                        ? deviceSortingParams.sortAscending === 'true'
                          ? 'ascending'
                          : 'descending'
                        : undefined
                    }
                    onClick={() => applySort('statusName')}
                  >
                    Status
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={1}
                    sorted={
                      deviceSortingParams.sortField === 'lastBackup'
                        ? deviceSortingParams.sortAscending === 'true'
                          ? 'ascending'
                          : 'descending'
                        : undefined
                    }
                    onClick={() => applySort('lastBackup')}
                  >
                    Last Backup
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={1}
                    className=" nonSortable lastColumn"
                  ></Table.HeaderCell>
                  <Table.HeaderCell
                    width={1}
                    className="blank nonSortable"
                  ></Table.HeaderCell>

                  <Table.HeaderCell
                    textAlign="center"
                    width={2}
                    className="firstColumn anchor"
                    sorted={
                      deviceSortingParams.sortField === 'reportedStatus'
                        ? deviceSortingParams.sortAscending === 'true'
                          ? 'ascending'
                          : 'descending'
                        : undefined
                    }
                    onClick={() => applySort('reportedStatus')}
                  >
                    Status
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    textAlign="center"
                    width={1} 
                    sorted={
                      deviceSortingParams.sortField === 'lastReportDate'
                        ? deviceSortingParams.sortAscending === 'true'
                          ? 'ascending'
                          : 'descending'
                        : undefined
                    }
                    onClick={() => applySort('lastReportDate')}
                  >
                    Date/Time
                  </Table.HeaderCell>
                  <Table.HeaderCell className="lastColumn nonSortable"></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {devices.map((device: any) => (
                  <Table.Row key={device.idAuto} className="tableRow">
                    <Table.Cell width={1}>
                      <a href={`/SNReports/${device.idAuto}`}>
                        {device.deviceSN}
                      </a>
                    </Table.Cell>
                    <Table.Cell width={2}>
                      <span className=" cutText">{device.computerName}</span>
                    </Table.Cell>
                    <Table.Cell width={2}>
                      <span className="cutText">{device.companyName}</span>
                    </Table.Cell>
                    <Table.Cell width={1}>{device.siteLocation}</Table.Cell>
                    <Table.Cell width={1} textAlign="center">
                       {  device.frequency === FrequencyOptions.Daily? 'Daily'
                        : device.frequency === FrequencyOptions.TowDay? '2 Days'
                        : device.frequency === FrequencyOptions.Weekly? 'Weekly'
                        : ''}
                    </Table.Cell>
                    <Table.Cell
                      width={2}
                      className={
                        device.status === StatusOptions.CriticalError || device.status === StatusOptions.NoBackupOccurred 
                          ? 'statusErr'
                          : device.status === StatusOptions.BackupSuccess
                          ? 'statusBackupSuccess'
                          : device.status === StatusOptions.Warning
                          ? 'statusWarning'
                          : 'statusActive'
                      }
                      textAlign="left"
                    >
                      {getStatusOptionDisplayText(device.status)}
                    </Table.Cell>
                    <Table.Cell width={2}>
                      <span className="cutText">{device.lastBackup}</span>
                    </Table.Cell>
                    <Table.Cell>
                      { (device.bitLockerStatus === EncryptionStatus.DeviceEncrypted || device.bitLockerStatus === EncryptionStatus.EncryptionFailed) && (
                     <Image
                            src="/img/key.svg" 
                            className={activeUser!.level <  PermissionLevel.User ? 'statusDeviceEncriptedActive': 'statusDeviceEncripted' }
                            onClick={() => activeUser!.level < PermissionLevel.User && handleShowVerificationBox(device.idAuto)}
                          />) }
                    </Table.Cell>
                    <Table.Cell className="blank"></Table.Cell>
                    <Table.Cell width={2}>
                      <span className="cutText">{device.reportedStatus}</span>
                    </Table.Cell>
                    <Table.Cell width={2}>
                      <span className="cutText">{device.reportedStatusDate}</span>
                    </Table.Cell>
                    <Table.Cell style={{ width: '19px' }}>
                      {activeUser &&
                        activeUser.level < PermissionLevel.User && (
                          <Image
                            src="/img/edit.svg"
                            className="hidenIcon"
                            onClick={() => showHideUpdateAsset(device.idAuto)}
                          />
                        )}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </GridColumn>
        </GridRow>
        <GridRow>
          <FormPaging
            currentPage={devicePagination.currentPage}
            totalPages={devicePagination.totalPages}
            onPageChange={runPaging}
          />
        </GridRow>
        {responseState && <ServerMsgBar runActionResult={responseState} />}
      </Grid>

      {showAddDevice && <DeviceRegister hideBox={showAddBox} />}
      {showUpdateDevice && (
            <DeviceForm
              hideBox={() => showHideUpdateAsset('0')}
              setResponseState={setResponseStateReceived}
            />
          )}
        {showVerificationBox && (
               <VerificationBitLocker 
                         runOnApprove={getBitLockerKeyList}
                         hideVerificationBox={()=>setShowVerificationBox(false)}></VerificationBitLocker>
          )}
        {showBitLockerKeyList &&( <BitLockerKeyList hideWindow={()=>setShowBitLockerKeyList(false)} ></BitLockerKeyList >
        )}
    </>
  );
});
