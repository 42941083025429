
import { observer } from 'mobx-react-lite';
import React from 'react'
import {   Grid, GridColumn, GridRow } from 'semantic-ui-react';
import MDBTab1 from './MDBTab1';
import MDBTab2 from './MDBTab2';
import MDBTab3 from './MDBTab3';
import { isBrowser } from 'react-device-detect';
import { useStore } from '../../api/stores/store';
import MStatisticChart from './MStatisticChart';
import LogsStatisticChart from '../UIParts/Logs&Reports/LogsStatisticChart';
export default observer (function MDeviceDashBoard()
{
  const {deviceStore} = useStore();
  const {dbNoBackups,dbAttackPossible,dbBackupSaccessAsset} = deviceStore;
    return(
       
      <Grid  className={'mainMobilePageCard'} >
      <Grid.Row columns={1}>
          <Grid.Column>
              <MStatisticChart />
          </Grid.Column>
          <Grid.Column>
               <LogsStatisticChart />
          </Grid.Column>
    </Grid.Row>
    <GridRow columns={1}>
      <GridColumn className={'gridMobileColumn'}>
      {dbAttackPossible.length > 0 &&
      <MDBTab1/>
      } 
      </GridColumn>
              <GridColumn  className={'gridMobileColumn'}>
      {dbNoBackups.length >0 &&
      <MDBTab2/>
      }
      </GridColumn>
      <GridColumn  className={'gridMobileColumn'}>
      {dbBackupSaccessAsset.length >0 &&
      <MDBTab3/>
      }
      </GridColumn>
    </GridRow>
      </Grid>
    )
})