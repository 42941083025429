import { observer } from 'mobx-react-lite';
import { useStore } from '../../../api/stores/store';
import {Image} from 'semantic-ui-react';
import React, { useState } from 'react';
import {  Form, Formik } from 'formik';
import { Button,  Grid, GridRow,  Icon, Item,  Rail, Segment } from 'semantic-ui-react';
import * as Yup from 'yup';
import LoginTextInput from '../../UIItems/LoginTextInput';
import { ServerResult } from '../../../api/models/common';
import ServerMsgBar from '../../UIItems/ServerMsgBar';

export default observer(function TabRegisterNS() {
  const { deviceStore, globalStore} = useStore();
  const {activeUser} = globalStore;
  const [showSNHelp, setShowSNHelp] = useState(false);
  const [formResponseState, setFormResponseState] = useState<
  ServerResult | undefined
>(undefined);
  return (
    <Formik
      initialValues={{ deviceSN: "", 
                              companyCode: activeUser?activeUser.companyId.toString():"",  
                              siteLocation:"",
                              fromVM :false, 
                              error: null }}
      onSubmit={(values, { setStatus}) =>  {
            deviceStore.regestryDevice(values) 
                    .then(res => {
                      var successResult = {
                        isSuccess: true,
                        msg: 'The Device is Successfully Registered',
                      };
                      setFormResponseState(successResult);
                    })
                    .catch(error => {
                      var failResult = {
                        isSuccess: false,
                        msg: error,
                      };
                      setFormResponseState(failResult);
                    })
      } }

      
      validationSchema={Yup.object({
        companyCode: Yup.string().required("Site Number is a required field"),
        deviceSN: Yup.string().required("Device SN is a required field"),
      })}
    >
         {({handleSubmit,  errors,status}) => (
         
<Form  className="vertical-form" onSubmit={handleSubmit} autoComplete='off'>

  <Grid columns={1}  className='loginGrid justifyCenter'>
        
        <GridRow className='loginRow'>
                <Grid.Column style={{ height:'100%' }}>
                        <LoginTextInput name="companyCode" placeholder="Site Number "   title='Site Number'/>
                </Grid.Column> 
        </GridRow>
        <GridRow GridRow className='loginRow'>
              <Grid.Column style={{ height:'100%' }}>      
                            <LoginTextInput name='deviceSN' placeholder='Enter SN'   title='Serial Number' />
                            <Image  className='questImg'  src="/img/question.svg" onClick={() => setShowSNHelp(!showSNHelp)} />
              </Grid.Column>
         </GridRow >
         <Rail internal position='left' hidden={!showSNHelp} onClick={() => setShowSNHelp(false)}>
                    <Segment.Group style={{width:'500px'}} >
                        <Segment>
                            <Icon name='close' floated='right' className='closeImage' />
                             <Image src='/img/SN_Help.jpg' /></Segment>
                        </Segment.Group>
          </Rail>
          <GridRow>  
              {formResponseState && (
                  <ServerMsgBar runActionResult={formResponseState} loginForm />
              )}
         </GridRow>
         <GridRow >
              <Grid.Column  style={{ width:'auto' }}>     
                    <Button  content=" Register SN " type="submit" fluid className='loginBtn'/>
              </Grid.Column> 
          </GridRow>

          </Grid>
        </Form>
      )}
    </Formik>
  );
});

