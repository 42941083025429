import { useField } from 'formik';
import React from 'react';
import { Form, Input, Label } from 'semantic-ui-react';

interface Props {
    placeholder: string;
    name: string;
    title?: string;
    type?:string;
    icon?:string;
    iconPosition?:'left';
    label?: string;
    fluid?:boolean;
    disabled?:boolean;
    autoComplete?:string;
}

export default function MyTextInput(props: Props) {
    const [field, meta] = useField(props.name); 
    return (
        <Form.Field error={meta.touched && !!meta.error}>
            
           
            <label >{props.title}</label>
            <Input   {...field} {...props} />
            {meta.touched && meta.error ? (
                <Label basic color='red'>{meta.error}</Label>
            ) : null}
            
        </Form.Field>
    )
} 