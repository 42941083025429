import { makeAutoObservable, runInAction, reaction } from 'mobx';
import agent from '../agent';
import {
  BitLockerKey,
  CRUTexts,
  CRUType,
  DashboardIndicators,
  Device,
  DeviceForUpdate,
  RecoveryKeyRequest,
  ThinDevice,
} from '../models/device';
import {
  forPrintDTO,
  Pagination,
  PagingParams,
  SortingParams,
} from '../models/pagination';
import { Statistic } from '../models/statistic';
import { store } from './store';
import { statusOptions } from '../options/selectOptions';

export default class deviceStore {
  devices: Device[] = [];
  devicesStatistic: Statistic[] = [];
  dbNoBackups: Device[] = [];
  dbAttackPossible: Device[] = [];
  dbBackupSaccessAsset: Device[] = [];
  dashboardIndicators: DashboardIndicators | undefined = undefined;
   selectedDevice: Device | undefined = undefined;
  editMode = false;
  loadingDevice = false;
  loadinInitialDevice = true;
  devicePagination: Pagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
    totalPages: 0,
  };
  devicePagingParams = new PagingParams();
  deviceSortingParams = new SortingParams();
  filterSet: Map<any, any> = new Map();
  filterAplay: number = 0;
  listSorted = false;
  listFiltered = false;
  updateState = false;
  CRUTypes:CRUType[]=[];
  deviceBitLockerKeys:BitLockerKey[]=[];
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    reaction(
      () => ({
        devicePagingParams: this.devicePagingParams,
      }),
      (params) => {
        this.loadDevices();
      }
    );
  }

  setOptionFilterItem = (predicate: string, value: string) => {

    if (this.filterSet.has(predicate)) {
      var prd = this.filterSet.get(predicate).toString();
      this.filterSet.set(predicate, prd + ',' + value);
    } else {
      this.filterSet.set(predicate, value);
      this.filterAplay++;
    }
 
  };

  setFilterItem = (predicate: string, value: any) => {
    this.setPagingParams(new PagingParams(1));
    this.filterSet.set(predicate, value);
    this.filterAplay++;
  };
  setCompanyFilter = (value: any) => {

    this.filterSet.set('companyList', value);
  };
  reSetFilterItem = (predicate: string) => {

    this.filterSet.delete(predicate);
    this.filterAplay--;
  };
  resetPageState = () => {

    this.deviceSortingParams.sortField = 'updateDate';
    this.deviceSortingParams.sortAscending = 'false';
    this.listSorted = false;
    this.listFiltered = false;
  };

  resetSentFilter = () => {

    this.filterSet.forEach((key, value) => {
      this.filterSet.delete(key);
      this.filterSet.delete(value);
    });
    this.filterAplay = 0;
    this.listFiltered = false;
  };

  setUpdateState = (state: boolean) => {
    this.updateState = state;
  };

  clearSort = () => {
    this.deviceSortingParams.sortField = 'updateDate';
    this.deviceSortingParams.sortAscending = 'false';
    this.filterAplay++;
    this.listSorted = false;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.devicePagingParams = pagingParams;
  };

  setSortingParams = (sortField: string, sortDirection: string) => {
    this.deviceSortingParams.sortField = sortField;
    this.deviceSortingParams.sortAscending = sortDirection;
  };

  applySort = (sortField: string) => {
    if (this.deviceSortingParams.sortField === sortField) {
      if (this.deviceSortingParams.sortAscending === 'true')
        this.deviceSortingParams.sortAscending = 'false';
      else this.deviceSortingParams.sortAscending = 'true';
    } else {
      this.deviceSortingParams.sortField = sortField;
      this.deviceSortingParams.sortAscending = 'true';
    }
    this.listSorted = true;
    this.loadDevices();
  };
  adminApplySort(sortField: string) {
    this.applySort(sortField);
    this.loadDevices();
  }
  get axiosParams() {
    const params = new URLSearchParams();
    params.append('pageNumber', this.devicePagingParams.pageNumber.toString());
    params.append('pageSize', this.devicePagingParams.pageSize.toString());
    params.append('orderField', this.deviceSortingParams.sortField);
    params.append('orderAscending', this.deviceSortingParams.sortAscending);
    this.filterSet.forEach((value, key) => {
      if (key === 'companyList' && value && Array.isArray(value)) {
        value.map((companyId: any) => params.append(key, companyId));
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  get devicesByCompany() {
    return Array.from(this.devices).sort(
      (a, b) => a.companyIDAuto - b.companyIDAuto
    );
  }

  get groupedDevices() {
    return Object.entries(
      this.devicesByCompany.reduce((devices, device) => {
        const company = device.companyName
          ? device.companyName
          : 'Not associated with any company';
        devices[company] = devices[company]
          ? [...devices[company], device]
          : [device];
        return devices;
      }, {} as { [key: string]: Device[] })
    );
  }


  loadDevices = async () => {
    this.devices = [];
    try {
      const result = await agent.Devices.list(this.axiosParams);
      runInAction(() => {
        this.setPagination(result.pagination);
        this.devices = result.data;
        this.setLoadinInitialDevice(false);
      });
     } catch (error) {
      this.setLoadinInitialDevice(false);
    }
  };

  getDevicesStatistic = async () => {
    this.devicesStatistic = [];
    try {
      const result = await agent.Devices.statusChart(this.axiosParams);
      this.devicesStatistic = result.data;
    } catch (error) {

    }
  };

  getDevicesStatisticM = async () => {
    this.loadinInitialDevice = true;
    this.devicesStatistic = [];
    try {
      const result = await agent.Devices.statistic(this.axiosParams);
      this.devicesStatistic = [];
      this.devicesStatistic = result.data;

      const result1 = await agent.Devices.dbNoBackupsList(this.axiosParams);
      this.dbNoBackups = [];
      this.dbNoBackups = result1.data;

      const result2 = await agent.Devices.dbAttackPossibleList(
        this.axiosParams
      );
      this.dbAttackPossible = [];
      this.dbAttackPossible = result2.data;

      const result3 = await agent.Devices.dbBackupSuccess(this.axiosParams);
      this.dbBackupSaccessAsset = [];
      this.dbBackupSaccessAsset = result3.data;

      this.setLoadinInitialDevice(false);
    } catch (error) {
      this.setLoadinInitialDevice(false);
    }
  };
  getDashboardIndicators = async () => {
    this.dashboardIndicators = undefined;
    try {
      const result3 = await agent.Devices.dbDashboardIndicators(
        this.axiosParams
      );
      this.dashboardIndicators = result3.data;
    } catch (error) {

    }
  };

  getAttackPossible = async () => {
    this.dbAttackPossible = [];
    try {
      const result2 = await agent.Devices.dbAttackPossibleList(
        this.axiosParams
      );
      this.dbAttackPossible = result2.data;
    } catch (error) {
      console.log(error);
    }
  };
  getNoBackupsList = async () => {
    this.dbNoBackups = [];
    try {
      const result1 = await agent.Devices.dbNoBackupsList(this.axiosParams);
      this.dbNoBackups = result1.data;
    } catch (error) {
      console.log(error);
    }
  };

  setPagination = (pagination: Pagination) => {
    this.devicePagination = pagination;
  };

  loadDevice = async (idStr: string) => {
    if (idStr === '0') {
      this.loadinInitialDevice = false;
      this.selectedDevice = undefined;
      return;
    }

    this.loadinInitialDevice = true;
    var id: number = +idStr;
    let device = this.getDevice(id);
    if (device) {
      this.selectedDevice = device;
      this.loadinInitialDevice = false;
    } else {
      try {
        device = await agent.Devices.details(id);
        runInAction(() => {
          this.selectedDevice = device;
          this.loadinInitialDevice = false;
        });
      } catch (error) {
        this.loadinInitialDevice = false;
      }
    }
  };

  unselectDevice = () => {
    this.selectedDevice = undefined;
  };
  getDevice = (id: number) => {
    return this.devices.find((d) => d.idAuto === id);
  };

  setLoadinInitialDevice = (state: boolean) => {
    this.loadinInitialDevice = state;
  };

  createDevice = async (device: Device) => {
    this.loadingDevice = true;
    device.idAuto = 0;
    try {
      await agent.Devices.create(device);
      runInAction(() => {
        this.devices.push(device);
        this.selectedDevice = device;
        this.loadingDevice = false;
        this.editMode = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingDevice = false;
      });
    }
  };
  regestryDevice = async (device: ThinDevice) => {
    this.loadingDevice = true;
    try {
      await agent.Devices.regestry(device);
    } catch (error) {
      throw error;
    }
  };

  setPublished = async (devicesList: string[]) => {
    this.loadingDevice = true;
    try {
      await agent.Devices.setPublished(devicesList);
    } catch (error) {
      throw error;
    }
  };

  setForPrint = async (idAuto: string, typeNum: number) => {
    this.loadingDevice = true;
    try {
      let idAutoNum = parseInt(idAuto);
      let printProps: forPrintDTO = { idAuto: idAutoNum, productType: typeNum };
      await agent.Devices.setForPrint(printProps);
    } catch (error) {
      throw error;
    }
  };

  confirmNewSN = async (numNewSN: number) => {
    try {
      if (numNewSN) {
        await agent.Devices.confirmNewSN(numNewSN);
      }
    } catch (error) {
      throw error;
    }
  };

  generateNewSN = async (numNewSN: string | undefined) => {
    try {
      if (numNewSN !== undefined) {
        var num: number = +numNewSN;
        await agent.Devices.generateSN(num);
      }
    } catch (error) {
      throw error;
    }
  };

  updateDevice = async (device: DeviceForUpdate) => {
    this.loadingDevice = true;
    try {
      await agent.Devices.update(device);
      runInAction(() => {
        let updatedDevice = this.devices.find(
          (i) => i.idAuto === device.idAuto
        );
        if (updatedDevice) {
          if (device.status !== updatedDevice.status) {
            updatedDevice.status = device.status;
            var selectedStatus =statusOptions.find(item => item.value === device.status);
            updatedDevice.statusName = selectedStatus!.text;
          }

          if (device.companyIDAuto !== updatedDevice.companyIDAuto) {
            updatedDevice.companyIDAuto = device.companyIDAuto;
            var selectedCompany = store.globalStore.companyList.find(item => item.companyId === device.companyIDAuto);
            updatedDevice.companyName = selectedCompany!.companyName;
          }

          updatedDevice.computerName = device.computerName;
          updatedDevice.computerDescript = device.computerDescript;
          updatedDevice.siteLocation = device.siteLocation;
          updatedDevice.frequency = device.frequency;
          updatedDevice.version = device.version;
          this.devices.map(
            (obj) =>
              this.devices.find((o) => o.idAuto === device.idAuto) ||
              updatedDevice
          );
        }
        this.loadingDevice = false;
      });
    } catch (error) {
      this.loadingDevice = false;
      this.editMode = false;
      throw error;
    }
  };

  sortAdapter = (state: any, action: any) => {
    this.listSorted = true;
    let dir: string = 'true';
    this.deviceSortingParams.sortField = action.column;

    if (state.column === action.column) {
      dir = state.direction === 'ascending' ? 'false' : 'true';
    }
    this.deviceSortingParams.sortAscending = dir;
    this.setPagingParams(new PagingParams(1));
    if (state.column === action.column) {
      return {
        ...state,
        direction: state.direction === 'ascending' ? 'descending' : 'ascending',
      };
    }
    return {
      column: action.column,
      direction: 'ascending',
    };
  };

  loadCRUTypes = async () => {
    this.CRUTypes = [];
    {
      const result = await agent.Devices.cruTypes();
      this.CRUTypes = result;
    }
  };
  loadBitLockerKeyList = async (recoveryKeyRequest: RecoveryKeyRequest) =>
    {
            const result = await agent.Devices.getBitLockerKey(recoveryKeyRequest);
            runInAction(() => {
              this.deviceBitLockerKeys= result.data;
            });
    }
}
