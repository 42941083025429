import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Grid, GridRow } from 'semantic-ui-react';
import PreDeliveryList from './PreDeliveryList';
import GenerateSN from './GenerateSN';
import { useStore } from '../../../api/stores/store';
import LoadingComponent from '../../UICommon/LoadingComponent';

export default observer(function NewSNDashBoard() {
  const { deviceStore } = useStore();
  const {
    loadDevices,
    devices,
    resetPageState,
  } = deviceStore;

  useEffect(() => {
    if (devices.length === 0) {
      loadDevices();
    }
    return () => {
      resetPageState();
    };
  }, []);

  if (deviceStore.loadinInitialDevice)
    return <LoadingComponent content="Loading App" />;

  return (
    <Grid >
      <GridRow>
                <Grid.Column >
                      <GenerateSN />
                </Grid.Column>
       </GridRow>
       <GridRow>
              <Grid.Column >
                      <PreDeliveryList />
              </Grid.Column>
      </GridRow>
    </Grid>
  );
});
