import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Image, Menu, Label } from 'semantic-ui-react';
import { useStore } from '../../api/stores/store';
import SelectCompany from '../UIItems/SelectCompany';
import { NavLink } from 'react-router-dom';
import UserBox from '../UIItems/UserBox';
import { PermissionLevel } from '../../api/models/enums';

export default observer(function SideBar() {
  const {
    globalStore,
    accountStore,
    deviceStore,
    backupsStore,
    userProfileStore,
  } = useStore();
  const {getSelectedCompany,
    activeUser,
    getIsAdmin,getIsSuperAdmin,
    selectedCompanyList,
    setSelectedCompanylist,
  } = globalStore;
  const { setMenuActiveItem, menuActiveItem } = accountStore;
  const isAdmin: boolean = getIsAdmin();
  const isSuperAdmin: boolean =getIsSuperAdmin();
  const [showCompanyBox, setShowCompanyBox] = useState(false);
  const [showUserBox, setShowUserBox] = useState(false);
  const refCompany = useRef<HTMLDivElement>(null);
  const refTechMenu = useRef<HTMLDivElement>(null);

  function applyCompanyFilter() {
    deviceStore.setCompanyFilter(selectedCompanyList);
    deviceStore.loadDevices();
    deviceStore.getDashboardIndicators();
    deviceStore.getDevicesStatistic();
    backupsStore.setCompanyFilter(selectedCompanyList);
    backupsStore.loadBackups();
    backupsStore.getLastBackupRow();
    userProfileStore.setCompanyFilter(selectedCompanyList);
    userProfileStore.loadUserProfiles();
    setShowCompanyBox(false);
  }
  function resetCompanyFilter() {
    deviceStore.reSetFilterItem('companyList');
    backupsStore.reSetFilterItem('companyList');
    userProfileStore.reSetFilterItem('companyList');
  }
  function changeSelectedCompanyList(comnpanylist: number[]) {
    setSelectedCompanylist(comnpanylist);
  }

  function handleClickCompanyBox() {
    setShowCompanyBox(!showCompanyBox);
  }

  function handleClickTechMenu() {
    setShowUserBox(!showUserBox);
  }

  function useOnClickOutside(
    ref: React.RefObject<HTMLElement>,
    handler: (event: MouseEvent) => void
  ) {
    useEffect(() => {
      const handleClick = (event: MouseEvent) => {
        if (!ref.current || ref.current.contains(event.target as Node)) {
          return;
        }
        handler(event);
      };
      document.addEventListener('mousedown', handleClick);
      return;
    }, [ref, handler]);
  }
  useOnClickOutside(refCompany, () => setShowCompanyBox(false));
  useOnClickOutside(refTechMenu, handleClickTechMenu);

  return (
    <>
      <Menu secondary inverted fixed="left" vertical>
        <Image src="/img/Layer_1.svg" className="menuLogoImg" />
        {activeUser!.listCompanies!.length === 1 && (
          <Menu.Item className="companySelect">
            <span> {activeUser!.userCompany.companyName}</span>
          </Menu.Item>
        )}
        {activeUser!.listCompanies!.length > 1 && (
          <Menu.Item className="companySelect" onClick={handleClickCompanyBox}>
            <span> {getSelectedCompany()}</span>
            {showCompanyBox && (
              <Image
                src={'/img/up.svg'}
                className="companySelectImg"
                onClick={() => setShowCompanyBox(true)}
              />
            )}
            {!showCompanyBox && (
              <Image
                src={'/img/down.svg'}
                className="companySelectImg"
                onClick={() => setShowCompanyBox(false)}
              />
            )}
          </Menu.Item>
        )}
        <Menu.Item
          className="menuItem"
          name="dashboard"
          active={menuActiveItem === 'Dashboard'}
          onClick={() => setMenuActiveItem('Dashboard')}
          as={NavLink}
          to="/devicesDashBoard"
        >
          <div className="image-container">
            <Image src="/img/dashboard.svg" className="menuImage" />
            <Image
              src="/img/dashboard_a.svg"
              className="menuImage alterImage"
              style={{ opacity: '0' }}
            />
          </div>
          <span className="menuItemText">Dashboard</span>
        </Menu.Item>
        <Menu.Item
          className="menuItem"
          name="Assets"
          as={NavLink}
          to="/devices"
          active={menuActiveItem === 'Assets'}
          onClick={() => setMenuActiveItem('Assets')}
        >
          <div className="image-container">
            <Image src="/img/assets.svg" className="menuImage" />
            <Image
              src="/img/assets_a.svg"
              className="menuImage alterImage"
              style={{ opacity: '0' }}
            />
          </div>
          <span className="menuItemText">Assets</span>
        </Menu.Item>

        <Menu.Item
          className="menuItem"
          as={NavLink}
          to="/SNReports/0"
          name="Logs & Reports"
          onClick={() => setMenuActiveItem('Logs & Reports')}
          active={menuActiveItem === 'Logs & Reports'}
        >
          <div className="image-container">
            <Image src="/img/reports.svg" className="menuImage" />
            <Image
              src="/img/reports_a.svg"
              className="menuImage alterImage"
              style={{ opacity: '0' }}
            />
          </div>
          <span className="menuItemText">Logs & Reports</span>
        </Menu.Item>
        {activeUser!.cloudVersion && (
          <Menu.Item
            className="menuItem"
            name="Resources"
            as={NavLink}
            to="/downloads"
            active={menuActiveItem === 'Resources'}
            onClick={() => setMenuActiveItem('Resources')}
          >
            <div className="image-container">
              <Image src="/img/resources.svg" className="menuImage" />
              <Image
                src="/img/resources_a.svg"
                className="menuImage alterImage"
                style={{ opacity: '0' }}
              />
            </div>
            <span className="menuItemText">Resources</span>
          </Menu.Item>
        )}

        {(isAdmin ||activeUser?.level == PermissionLevel.Partner ) && (
          <>
            <Menu.Item
              className="menuItem"
              name="Users"
              as={NavLink}
              to="/userProfiles"
              active={menuActiveItem === 'Users'}
              onClick={() => setMenuActiveItem('Users')}
            >
              <div className="image-container">
                <Image src="/img/users.svg" className="menuImage" />
                <Image
                  src="/img/users_a.svg"
                  className="menuImage alterImage"
                  style={{ opacity: '0' }}
                />
              </div>
              <span className="menuItemText">Users</span>
            </Menu.Item>
          </>
        )}
        {isSuperAdmin && (
          <>
            <Menu.Item
              className="menuItem"
              name="Admin Console"
              as={NavLink}
              to="/adminTab/assets"
              active={menuActiveItem === 'AdminDashbard'}
              onClick={() => setMenuActiveItem('AdminDashbard')}
            >
              <div className="image-container">
                <Image src="/img/assets.svg" className="menuImage" />
                <Image
                  src="/img/assets_a.svg"
                  className="menuImage alterImage"
                  style={{ opacity: '0' }}
                />
              </div>
              <span className="menuItemText">Admin Console</span>
            </Menu.Item>
          </>
        )}
        <div
          className={
            showUserBox === true ? 'menuBottom selected' : 'menuBottom'
          }
          onClick={() => setShowUserBox(!showUserBox)}
        >
          <Label circular color="black" size="big" className="initialsLabel">
            {activeUser!.initials}
          </Label>
          <span className="menuBottomText">{activeUser!.displayName}</span>
          <span className="menuBottomText2 ">{activeUser!.levelName}</span>
          <span className="menuBottomText1 ">
          {' Site :' + activeUser!.userCompany.companyId+ ' '}
            {activeUser!.userCompany.companyName}{' '}

          </span>
          <p>
            <span className="menuBottomText4">Site Version 2.0.0</span>
          </p>
        </div>
        {activeUser && showUserBox && (
          <div ref={refTechMenu}>
            <UserBox
              activeUser={activeUser}
              onClickHandler={() => setShowUserBox(false)}
            />
          </div>
        )}
      </Menu>
      {showCompanyBox && (
        <div ref={refCompany}>
          {' '}
          <SelectCompany
            runOkBtn={applyCompanyFilter}
            runCuncelBtn={resetCompanyFilter}
            setSelectedCompanyList={changeSelectedCompanyList}
            sourceCompanyList={selectedCompanyList}
            className="selectCompanyBox"
            showButtons={true}
          />
        </div>
      )}
    </>
  );
});
