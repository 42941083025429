import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  FormSelect,
  Grid,
  GridColumn,
  GridRow,
  Icon,
  Image,
} from 'semantic-ui-react';
import FormTextInput from '../../UIItems/FormTextInput';
import { useStore } from '../../../api/stores/store';
import { ServerResult } from '../../../api/models/common';
import ServerMsgBar from '../../UIItems/ServerMsgBar';
import FormSelectInput from '../../UIItems/FormSelectInput';
import SelectCompany from '../../UIItems/SelectCompany';

import {
  frequencyOptions,
  statusOptions,
} from '../../../api/options/selectOptions';
import FormDropDown from '../../UIItems/FormDropDown';

type Props = {
  hideBox: () => void;
  setResponseState: (updateStatus: ServerResult | null) => void;
};
export default observer(function AdminDeviceForm(props: Props) {
  const { deviceStore, globalStore } = useStore();
  const { selectedCompanyList } = globalStore;
  const { selectedDevice } = deviceStore;
  const [formResponseState, setFormResponseState] = useState<
    ServerResult | undefined
  >(undefined);
  const [showCompanyDD, setShowCompanyDD] = useState(false);
  const refCompanyDD = useRef<HTMLDivElement>(null);
  const handleCloseClick = () => {
    props.hideBox();
  };

  function setResponseState(responseState: ServerResult) {
    if (props.setResponseState) props.setResponseState(responseState);
  }
  function handleCloseCompanyDD() {
    setShowCompanyDD(!showCompanyDD);
  }
  const dummyVoid = () => {};

  function useOnClickOutside(
    ref: React.RefObject<HTMLElement>,
    handler: (event: MouseEvent) => void
  ) {
    useEffect(() => {
      const handleClick = (event: MouseEvent) => {
        if (!ref.current || ref.current.contains(event.target as Node)) {
          return;
        }
        handler(event);
      };
      document.addEventListener('mousedown', handleClick);
      return;
    }, [ref, handler]);
  }
  useOnClickOutside(refCompanyDD, () => setShowCompanyDD(false));
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };
  return (
    <Formik
      initialValues={{
        idAuto: selectedDevice!.idAuto,
        deviceSN: selectedDevice!.deviceSN,
        companyIDAuto: selectedDevice!.companyIDAuto,
        companyName: selectedDevice!.companyName,
        status: selectedDevice!.status,
        computerName: selectedDevice!.computerName,
        computerDescript: selectedDevice!.computerDescript,
        siteLocation: selectedDevice!.siteLocation,
        frequency: selectedDevice!.frequency,
        version: selectedDevice!.version,
        error: null,
      }}
      onSubmit={(values, { setStatus }) => {
        deviceStore
          .updateDevice(values)
          .then((res) => {
            var successResult = {
              isSuccess: true,
              msg: 'Device is successfully updated ',
            };
            setResponseState(successResult);
            props.hideBox();
          })
          .catch((error) => {
            var failResult = {
              isSuccess: false,
              msg: 'Update device failed ' + error,
            };
            setFormResponseState(failResult);
          });
      }}
    >
      {({ handleSubmit, status, values }) => {
        function setAssetCompany(companyId: number, companyName: string) {
          values.companyIDAuto = companyId;
          values.companyName = companyName;
          setShowCompanyDD(false);
        }

        return (
          <Form
            className="ui form error internalForm topForm"
            style={{ height: '100%' }}
            onSubmit={handleSubmit}
            onKeyDown={handleKeyDown}
            autoComplete="off"
          >
            <Grid className="formPanel">
              <GridRow>
                <GridColumn className="assetTitle" width={12}>
                  <Icon name="hdd outline" />
                  {selectedDevice!.deviceSN}
                </GridColumn>
                <GridColumn width="2" floated="right">
                  <Image
                    src="/img/close.svg"
                    className="closeWindow"
                    onClick={handleCloseClick}
                  />
                </GridColumn>
              </GridRow>
              <br />
              <div className="scrollForm" style={{ height: '520px' }}>
                <GridRow className="formFieldRow">
                  <GridColumn>
                    <FormSelectInput
                      title="Company"
                      name="companyName"
                      placeholder="Select asset company"
                      setShowSelectBox={handleCloseCompanyDD}
                      required
                    />
                    {showCompanyDD && (
                      <div ref={refCompanyDD}>
                        {' '}
                        <SelectCompany
                          runOkBtn={dummyVoid}
                          runCuncelBtn={dummyVoid}
                          setSelectedCompanyList={dummyVoid}
                          sourceCompanyList={selectedCompanyList}
                          className="companiesList"
                          oneselect
                          setSelectedCompanyId={setAssetCompany}
                        />
                      </div>
                    )}
                  </GridColumn>
                </GridRow>
                <GridRow className="formFieldRow">
                  <GridColumn>
                    <FormDropDown
                      name="status"
                      placeholder="Status"
                      options={statusOptions}
                      title="Asset Status"
                      value={values.status}
                      required
                    />
                  </GridColumn>
                </GridRow>
                <GridRow className="formFieldRow">
                  <GridColumn>
                    <FormDropDown
                      name="frequency"
                      placeholder="Frequency"
                      options={frequencyOptions}
                      title="Frequency"
                      value={values.frequency}
                    />
                  </GridColumn>
                </GridRow>
                <GridRow className="formFieldRow">
                  <GridColumn>
                    <FormTextInput
                      title="Version"
                      name="version"
                      placeholder="Version"
                    />
                  </GridColumn>
                </GridRow>
                <GridRow className="formFieldRow">
                  <GridColumn>
                    <FormTextInput
                      title="Name"
                      name="computerName"
                      placeholder="Computer Name"
                    />
                  </GridColumn>
                </GridRow>
                <GridRow className="formFieldRow">
                  <GridColumn>
                    <FormTextInput
                      title="Description"
                      name="computerDescript"
                      placeholder="Computer Description"
                    />
                  </GridColumn>
                </GridRow>
                <GridRow className="formFieldRow">
                  <GridColumn>
                    <FormTextInput
                      title="Device Location"
                      name="siteLocation"
                      placeholder="Device Location"
                    />
                  </GridColumn>
                </GridRow>
              </div>
              <GridRow className="panelButtonsRow">
                <Grid.Column width={10}></Grid.Column>
                <Grid.Column width={5}>
                  <Button content="Update" type="submit" className="applyBtn" />
                </Grid.Column>
              </GridRow>
              <br />
              {formResponseState && (
                <ServerMsgBar runActionResult={formResponseState} />
              )}
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
});
