import React from 'react';
import { observer } from 'mobx-react-lite'
import {  Header, Label, Segment, Table  } from 'semantic-ui-react';
import { isBrowser,isMobile } from 'react-device-detect';
import { useStore } from '../../api/stores/store';

export default observer (function MDBTab2()
{
   const { deviceStore} = useStore();
   const {dbNoBackups} = deviceStore;

   return(
    <Segment >
             <Header sub className='titleSet1' content= ' Backup Incomplete  '/>
               <Table   compact  color='orange' >
               {isBrowser  && ( <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Device SN</Table.HeaderCell>
                    <Table.HeaderCell>Computer </Table.HeaderCell>
                    <Table.HeaderCell>Last Backup</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>)}

                 <Table.Body>
                   {dbNoBackups.map((device:any) => (
                         <Table.Row key={device.deviceSN}   >
                           {isMobile  && (
                             <Table.Cell className='compDesc' >{device.deviceSN} 
                                  <Label size='mini'>{device.lastBackup? 'Last Backup :' +  device.lastBackup : 'Last Backup --'}</Label>
                             </Table.Cell>
                           )}
                              {isBrowser  && ( <>
                              <Table.Cell className='compDesc' >{device.deviceSN}</Table.Cell>
                               <Table.Cell className='compDesc'  >{device.computerName}<div className='compDesc'>{device.computerDescript}</div></Table.Cell>
                               <Table.Cell className='compDesc' >{device.lastBackup}</Table.Cell>
                               </>)}
                         </Table.Row>
                     ))}
                 </Table.Body>
               </Table>
            </Segment>
   )
})