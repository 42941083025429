import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { PieChart, Pie,  Cell, Legend } from 'recharts';
import { Header } from 'semantic-ui-react';

import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../api/stores/store';
import LoadingComponent from '../UICommon/LoadingComponent';

export default observer(function MStatisticChart() {
  const {deviceStore} = useStore();
  const {devicesStatistic,getDevicesStatisticM, setFilterItem,loadDevices} = deviceStore;
  const navigate = useNavigate();
    useEffect(()=>{ 
        if(devicesStatistic.length=== 0 ) getDevicesStatisticM();
    },[devicesStatistic.length])
    
    function drillDownFilteredList (status :number)
      {
        setFilterItem('status', status);
        loadDevices();
        navigate('/devices');
      }

    if(deviceStore.loadinInitialDevice) return <LoadingComponent content='Loading App Statistic..'/>

  return (
  <>
       {devicesStatistic.length > 0 && (
        <>
          <Header>Assets Last Updated Status</Header>
          <PieChart width={isMobile?300:400} height={400}>
            <Pie
              data={devicesStatistic}
              outerRadius={isMobile?100:140}
              fill="#8884d8"
              paddingAngle={1}
              dataKey="count"
              nameKey="statusName"
              label
            >
              {devicesStatistic.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={
                entry.status === 1? '#00B2E3':
                entry.status === 2?'#ffb703':
                entry.status === 3? '#b83700':
                entry.status === 8? '#101820' :
                entry.status === 9? '#008AAB':
                entry.status === 10? '#008AAB':'#f06c00'}   onClick={() => drillDownFilteredList( entry.status)} />
              ))}
            </Pie>
            <Legend verticalAlign="top" height={60}/>
      </PieChart>
      </>
      )}
</>
  );
});
