
import React, {  useState } from 'react';
import { Button,   Input,  Image, Segment, Label  } from 'semantic-ui-react';

interface VerificationBitLockerProperties {
    runOnApprove: (verificationCode: string) => Promise<void>;
    hideVerificationBox: () => void;
}
export default function VerificationBitLocker(props: VerificationBitLockerProperties) {

  const [verificationCode, setVerificationCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  function handleRunClick() {
    setLoading(true);
    setError(null);
    
    props.runOnApprove(verificationCode)
        .then(() => {
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
            setError(error.message || 'An error occurred');
        });
}
 const cssClass ='formTextInput'+ (error ? ' error ':'')  ;
  return (
    <Segment className='modalCenter'>
         <Image  className= "closeWindow" src="/img/close.svg"   onClick={()=>props.hideVerificationBox()}/>
         <label className='modalTitle'  >BitLocker Recovery Key</label>
         <label  className='modalSubTitle'>To continue, type your password</label>

         <div className='verificationDiv'>
          <Input   name='verificationCode'  icon="lock"  type="password" placeholder="Password"
                         iconPosition="left" autoComplete="new-password"
                         onChange={(event) => setVerificationCode(event.target.value)} className={cssClass} />
           <Button  className='modulButton' 
                          onClick={handleRunClick} 
                          loading={loading}
                          disabled={loading}
                          type='button'>Next</Button></div>
            {error&&  (
                <Label basic className='errorLbl'>Incorrect password</Label>
            ) }
      </Segment>
  )
 }