import { observer } from 'mobx-react-lite';
import React from 'react';
import MyTextInput from './MUIItems/MyTextInput';
import { Button, Form } from 'semantic-ui-react';
import {  Formik } from 'formik';
import queryString from 'query-string';

import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../api/stores/store';

export default observer(function MApprovePasswordPart() {
  const { accountStore } = useStore();
  const parsed = queryString.parse(window.location.search);
  const navigate = useNavigate();
  const externalData = {
            email: window.location.search === '' ? '' : parsed.email,
            token: window.location.search === '' ? '' : parsed.activationToken,
          }

  return (
    <Formik
      initialValues={{
        email: externalData.email,
        password: '',
        token: externalData.token,
        error: null,
      }}
      onSubmit={(values, { setStatus }) => {
        accountStore
          .approvePassword(values)
          .then(() => navigate('/devicesDashBoard'))
          .catch((error) => setStatus({ sent: false, msg: error }));
      }}
      validationSchema={Yup.object({
        password: Yup.string().required('Password is required'),
        passwordConfirmation: Yup.string()
          .required('Confirmation Password is required')
          .oneOf([Yup.ref('password'), null], 'Passwords must match'),
      })}
    >
      {({ handleSubmit, errors, status }) => (
        <Form className="ui form " onSubmit={handleSubmit} autoComplete="off">
          <MyTextInput
            name="password"
            placeholder="Enter a new password "
            type="password"
            icon="lock"
            iconPosition="left"
          />
          <MyTextInput
            name="passwordConfirmation"
            placeholder="Re-enter  new password"
            type="password"
            icon="lock"
            iconPosition="left"
          />

          {status && status.msg && (
            <p
              className={`alert ${ status.sent ? 'alert-success' : 'alert-error'  }`}
            >
              {status.msg}
            </p>
          )}
          <Button positive content="Reset Password" type="submit" fluid />
        </Form>
      )}
    </Formik>
  );
});
