import { useField } from 'formik';
import React, { useState } from 'react';
import { Image, Form } from 'semantic-ui-react';

interface FileInputProperties {
    name: string;
    label?: string;
    onFileChanged: (uploadFile :File) => void;
    disabled?:boolean;
}

export default function FileInput(props: FileInputProperties) {
    const [field, meta] = useField(props.name); 
    const [selectedFile, setSelectedFile] = useState<File|undefined>(undefined);
    const handleFileChange = function( event: React.ChangeEvent<HTMLInputElement> )
    {
       if (event.currentTarget.files && event.currentTarget.files.length > 0)
       {
            setSelectedFile(event.currentTarget.files[0]);
            props.onFileChanged(event.currentTarget.files[0]);
       }
   
   };
    return (

        
            <Form.Field error={meta.touched && !!meta.error} className="file-input-label" disabled={props.disabled} >
                            <div>
                                        <label htmlFor={props.name} className='fileInput'>
                                        <Image src="/img/browser.svg" className="formSubTitleImg" />
                                            {selectedFile ? (
                                            <div>
                                                <span>{selectedFile.name}</span>
                                            </div>
                                            ) : (
                                            <span>Choose file</span>
                                            )}
                                        </label>
                                        <input  id={props.name}   type="file"  onChange={handleFileChange}  accept=".zip"   style={{ display: 'none' }} />
                                        
                            </div>
                </Form.Field>


    )
} 

