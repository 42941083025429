import { observer } from 'mobx-react-lite';
import React from 'react';
import { Button,  Form,  Grid,  GridRow  } from 'semantic-ui-react';
import {  Formik } from 'formik';
import queryString from 'query-string';
import { useStore } from '../../../api/stores/store';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginTextInput from '../../UIItems/LoginTextInput';

export default observer(function ApprovePasswordPart() {
    const location = useLocation();
    const { accountStore } = useStore();
    const parsed = queryString.parse(location.search);
    const navigate = useNavigate();
    const externalData={
        email: location.search  === ''? '': parsed.email,
        token: location.search  === ''? '': parsed.activationToken,
     };

  return (
    <Formik
    initialValues={{ email:externalData.email, password: '', token:externalData.token, error: null }}
    onSubmit={(values,  { setStatus}) =>{
                accountStore.approvePassword(values) 
                .then(() => navigate('/devicesDashBoard'))
                .catch((error) => setStatus({  sent: false,msg: error }))
    }}

    
    validationSchema={Yup.object({
        password: Yup.string().required('Password is required'),
        passwordConfirmation: Yup.string().required('Confirmation Password is required')
           .oneOf([Yup.ref('password'), null], 'Passwords must match')
    })}

  >
    {({ handleSubmit,  errors,status }) => (
      <Form className="vertical-form" onSubmit={handleSubmit} autoComplete="off" >
              <Grid columns={1}  className='loginGrid justifyCenter'><GridRow className='loginRow'>
                 <Grid.Column style={{ height:'100%' }}>
                              <LoginTextInput name="password" placeholder=" " type="password"   title='Enter a new password'/>
                    </Grid.Column> </GridRow><GridRow GridRow className='loginRow'>
                    <Grid.Column style={{ height:'100%' }}>      
                            <LoginTextInput name="passwordConfirmation" placeholder="" type="password"   title='Re-enter  new password'  />
           
                      </Grid.Column></GridRow ><GridRow >
                    <Grid.Column  style={{ width:'auto' }}>     
                              <Button positive content="Reset Password" type="submit" fluid className='loginBtn'/>
                    </Grid.Column> </GridRow>
                    {status && status.msg && (
                              <p className={`alert ${ status.sent ? '-success' : '-error'  }`} >
                                  {status.msg}
                              </p>
                   )}
                </Grid>
      </Form>
      )}
    </Formik>
  );
});
