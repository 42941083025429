import { observer } from "mobx-react-lite";
import React, {  useEffect, useState } from "react";
import { Button, Header, Icon, Item,  ItemDescription,  ItemHeader,  Segment } from "semantic-ui-react";
import { useStore } from "../../api/stores/store";
import { PagingParams } from "../../api/models/pagination";
import LoadingComponent from "../UICommon/LoadingComponent";



export default observer(function DeviceListItems() {
    const {deviceStore} = useStore();
    const {loadDevices,devices,setPagingParams,devicePagination} = deviceStore;
    const [loadingNext,setLoadingNext] = useState(false);


    function  handleGetNext() {                     
    setLoadingNext(true);
    setPagingParams(new PagingParams(devicePagination!.currentPage +1))
    loadDevices().then(() => setLoadingNext(false));

    }


    useEffect(()=>{ 
      if(devices.length ===0 ) loadDevices();
  },[devices.length])

        
    if(deviceStore.loadinInitialDevice && !loadingNext) return <LoadingComponent content='Loading App '/>
    return (
        <Segment.Group  className="mainMobilePageCard" >
            <Header as='h2' content= 'Assets List ' className='SalvadorHeader1'  textAlign='center' />
             {devices.map((device:any) => (
                    <Segment>
                            <Item.Group >
                            <Item >
                            <Item.Content>
                                     <ItemHeader className={'assetStatus'+device.status}> 
                                            <Icon name="hdd" />
                                            deviceSN :  {device.deviceSN}
                                     </ItemHeader>
                            <Item.Description ><Icon 
                                        name={device.status === 3 || device.status === 2 ? 'attention' : device.status === 1 ? 'checkmark' : undefined}
                                        className={device.status === 3  ?"CriticalErrorItem": device.status === 2  ?"WarningErrorItem":  device.status === 1  ?"SuccessItem":""} />
                                  {device.statusName}</Item.Description>
                            <ItemDescription>Last Reported Status {device.reportedStatus} </ItemDescription>
                            <Item.Extra> {device.computerDescript} </Item.Extra>
                            <Item.Extra> {device.siteLocation} </Item.Extra>
                            <Item.Extra>Frequency {device.frequency} </Item.Extra>
                            <Item.Extra>Last Backup {device.lastBackup} </Item.Extra>

                         </Item.Content>    
                            </Item>

                            </Item.Group>
                    </Segment>
                    ))}
                    <br></br>
                        <Button floated='right' content='More..'
                        className='ActiveButton' onClick={handleGetNext} loading={loadingNext} disabled={devicePagination!.totalPages === devicePagination!.currentPage}></Button>        
        </Segment.Group>
        
    )
})