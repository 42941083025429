import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import {  Button,   Grid,  GridColumn,  GridRow,  Image,  Tab} from 'semantic-ui-react';
import LoadingComponent from '../../UICommon/LoadingComponent';
import { useStore } from '../../../api/stores/store';
import DownDocs from './DownDocs';
import DownSoft from './DownSoft';

export default observer(function Downloads() {
  const {  resourcesStore,globalStore } = useStore();
  const { activeUser } = globalStore;
  const {loadActiveResources ,loadinInitial,selectedResource} = resourcesStore;
  const panes = [
      { menuItem: 'Software',   render: () => <Tab.Pane className='resourceTab'><DownSoft/></Tab.Pane> },
      { menuItem: 'Documents', render: () => <Tab.Pane className='resourceTab'><DownDocs/> </Tab.Pane> },
    ]
  useEffect(() => {
     loadActiveResources();
  }, []);

  if (loadinInitial )
    return <LoadingComponent content="Loading Resources.." />;

  return (

    <Grid className='mainPageGrid resourcesGrid'  columns={2} >
       <GridRow>
            <GridColumn >
              <span className='pageTitle'>Resources</span> 
             </GridColumn>
      </GridRow>
      <GridRow >
        <GridColumn width={10}>
                  <span className='pageSubTitle'>Essentials</span> 
        </GridColumn>

        <GridColumn width={6}>
                    <span className='pageSubTitle'>Archive</span> 
        </GridColumn>
      </GridRow>
      <GridRow >
        <GridColumn width={10}>
              <Grid >
                      <GridRow>
                            <GridColumn className='resourceBox rBox1'>
                                 <Grid className='resGrid'   >
                                      <GridRow   >
                                                <Image   src="/img/Layer_1.svg"  className='resourceImg'/>
                                      </GridRow>
                                        <GridRow   >
                                                <span className='resourceTitle'>backup and recovery <br /> software</span>
                                      </GridRow>
                                      <GridRow>
                                                {selectedResource && (
                                                <span className='resourceSubTitle'>Version {selectedResource.fileVersion}</span>
                                                )}{' '}
                                      </GridRow>
                                      <GridRow>
                                                {selectedResource && (
                                                <span className='resourceRemark'> Last Update {selectedResource.insertDate}</span>
                                                )}{' '}
                                      </GridRow>
                                      <GridRow>
                                              <Button content="   Download   "   as="a"   className="downloadBtn"  href="/Resources/SalvadorBackupRecoveryLastVersion.zip"/>
                                      </GridRow>
                                  </Grid>
                            </GridColumn>
                            <GridColumn className='resourceBox rBox2' floated='right' >
                                <Grid className='resGrid'   >
                                      <GridRow   >
                                              <Image   src="/img/Layer_1.svg"  className='resourceImg'/>
                                      </GridRow>
                                    <GridRow   >
                                              <span className='resourceTitle'>User Manual</span>
                                    </GridRow>
                                  <GridRow>
                                        {activeUser && 
                                      <span className='resourceSubTitle'>Customer Code {activeUser.companyId}</span>
                                        }
                                  </GridRow>
                                  <GridRow>
                                      <span className='resourceRemark'> Use code when installing a new asset</span>
                                  </GridRow>
                                  <GridRow>
                                      <Button content="   Download   "  as="a"  href="/Resources/CRU_User_ManualLastVersion.pdf"  className="downloadBtn" />
                                  </GridRow>
                               </Grid>
                           </GridColumn>
                      </GridRow>
                      {/* <GridRow>
                            <GridColumn className='resourceBox'>
                          </GridColumn> 
                            <GridColumn className='resourceBox' floated='right'>
                            </GridColumn>
                      </GridRow> */}
              </Grid>
        </GridColumn>

        <GridColumn width={6}>
         {/* <Tab panes={panes} mrnu /> */}
              <Tab  
                         menu={{  secondary: true, pointing: true ,className:'resourceTabMenu' }}  panes={panes} />
                        


        </GridColumn>
      </GridRow>
    </Grid>

  );
});
