import { makeAutoObservable, reaction } from 'mobx';
import { CompanyInfo } from '../models/company';
import { ServerError } from '../models/serverError';
import { User, UserProfile } from '../models/userProfile';
import agent from '../agent';
import { PermissionLevel } from '../models/enums';
import { USER_STORED_DATA } from '../options/constants';

export default class GlobalStore {
  error: ServerError | null = null;
  token: string | null = window.localStorage.getItem('jwt');
  companyList: CompanyInfo[] = this.getCompanyList();
  selectedCompanyList: number[] = [];
  activeUser: User | null = this.getLoginedUser();
  activeUserProfile: UserProfile | undefined;
  appLoaded = false;
  loadinInitial = false;
  predicate = new Map().set('status', 0);
  listToDownload: { catalogNumber: string; deviceSN: string }[] = [];
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.token,
      (token, activeUser) => {
        if (token) {
          window.localStorage.setItem('jwt', token);
          window.localStorage.setItem(USER_STORED_DATA, JSON.stringify(this.activeUser));
          if (this.activeUser!.listCompanies.length > 0) {
            window.localStorage.setItem(
              'compL',
              JSON.stringify(this.companyList)
            );
          }
        } else {
          window.localStorage.removeItem('jwt');
          window.localStorage.removeItem(USER_STORED_DATA);
          window.localStorage.removeItem('compL');
        }
      }
    );
  }

  setPredicate = (predicate: string, value: any) => {
    this.predicate.delete(predicate);
    this.predicate.set(predicate, value);
  };
  getPredicate() {
    return this.predicate;
  }

  setServerError = (error: ServerError) => {
    this.error = error;
  };

  setToken = (token: string | null) => {
    this.token = token;
  };

  setlistToDownload = (list: { catalogNumber: string; deviceSN: string }[]) => {
    this.listToDownload = list;
  };

  setSelectedCompanylist = (companyList: number[] | []) => {
    this.selectedCompanyList = companyList;
  };
  getSelectedCompany =() =>{
    if (this.selectedCompanyList.length === 0 )
        return 'Filter by company'
    else
    {
      var retVal = '';
      const company = this.companyList.find((company) => company.companyId === this.selectedCompanyList[0]);
       if (company)
          retVal = company.companyName;
       if (this.selectedCompanyList.length > 1) 
          retVal+='...'
       return retVal;
    }  
  }

  setCompanyList = (companyList: CompanyInfo[] | []) => {
    this.companyList = companyList;
    window.localStorage.setItem('compL', JSON.stringify(companyList));
  };
  setActiveUser = (loginUser: User | null) => {
    this.activeUser = loginUser;
    window.localStorage.setItem('logU', JSON.stringify(loginUser));
  };
  setAppLoaded = () => {
    this.appLoaded = true;
  };

  getCompanyList() {
    var data = window.localStorage.getItem('compL');
    const companyList = JSON.parse(data!);
    return companyList;
  }

  getLoginedUser() {
    var data = window.localStorage.getItem('logU');
    if (!data) {
      return null;
    }
    if (this.checkIsExpire()) {
      return null;
    }
    const user = JSON.parse(data);
    return user;
  }

  loadLoginedUserProfile = async () => {
    if (!this.activeUser) return undefined;
    try {
      this.loadinInitial = true;
      let user = await agent.UserProfiles.details(this.activeUser!.id);
      this.activeUserProfile = user;
      this.loadinInitial = false;
      return user;
    } catch (error) {
      this.loadinInitial = false;
      throw error;
    }
  };

  checkIsExpire() {
    var isExpired: boolean = false;
    if (this.token === null || this.token === undefined) {
         return true;
    }
    var jwt =this.token;
    var tokenPayload = jwt.split('.')[1];
    var tokenPayloadBase64 = tokenPayload.replace(/-/g, '+').replace(/_/g, '/');
    var decodedTokenPayload = JSON.parse(window.atob(tokenPayloadBase64));
    var tokenDate = decodedTokenPayload.exp * 1000;
    if (tokenDate < new Date().getTime())
              isExpired = true;
    return isExpired;
  }

  getIsAdmin() {
    var data = window.localStorage.getItem(USER_STORED_DATA);
    if (!data) {
      return false;
    }
    const user = JSON.parse(data);
    if (!user) {
      return false;
    }
    return user.level === PermissionLevel.SuperAdmin || 
               user.level === PermissionLevel.SystemAdmin || 
                user.level ===PermissionLevel.SiteAdministrator;
  }
  getIsSuperAdmin() {
    var data = window.localStorage.getItem(USER_STORED_DATA);
    if (!data) {
      return false;
    }
    const user = JSON.parse(data);
    if (!user) {
      return false;
    }
    return user.level === PermissionLevel.SuperAdmin || 
               user.level === PermissionLevel.SystemAdmin ;
  }
}
