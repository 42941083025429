import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Grid, GridRow, Label, Icon, Input, GridColumn, Button, Image,} from 'semantic-ui-react';
import { useStore } from '../../../api/stores/store';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SearchTextBox from '../../UIItems/SearchTextBox';
import { CruTextOptions, FrequencyOptions, StatusOptions } from '../../../api/models/enums';
import { FORMAL_DAY_FORMAT } from '../../../api/options/constants';
import { FilterState } from '../../../api/models/common';

  type DevicesFilterProperties = {
    hideFilter: () => void;
    filtersState: FilterState;
    setFiltersState: React.Dispatch<React.SetStateAction<FilterState>>;
  };
  
export default observer(function DevicesFilter(filterProps: DevicesFilterProperties) {
  const { deviceStore,filterStore } = useStore();
  const {handleFrequencyChanged,handleStatusChanged,handlFromDate,
            handlToDate,
            clearFilter,getFormatedDate} = filterStore;
  const [showSearchCRU, setShowSearchCRU] = useState(false);
  const { setOptionFilterItem, resetSentFilter, setFilterItem, loadDevices} = deviceStore;
  const [inputValue, setInputValue] = useState('');

  function onChangeSelectedText(id: number, type: number, name:string) {
    setInputValue(name);
    filterStore.handlSelectedText(id,type,name,filterProps.setFiltersState);
    if (type !== CruTextOptions.CompanyName) {
        filterProps.filtersState.deviceId = id;
    }
    setShowSearchCRU(false);
  }
 
    function clearDevicesFilter()
    {
      clearFilter(filterProps.setFiltersState);
      setInputValue('');
      resetSentFilter();
      loadDevices();
    }

  function applyDevicesFilter() {
    resetSentFilter();
    if (filterProps.filtersState.frequencyList)
    {
      filterProps.filtersState.frequencyList.forEach((element) => {
            setOptionFilterItem('frequency',element.toString());
       });
    }
    if (filterProps.filtersState.statusList)
    {
      filterProps.filtersState.statusList.forEach((element) => {
            setOptionFilterItem('statusList',element.toString());
       });
    }
    if (filterProps.filtersState.fromDate) setFilterItem('fromDate', getFormatedDate(filterProps.filtersState.fromDate));
    if (filterProps.filtersState.toDate) setFilterItem('toDate',  getFormatedDate(filterProps.filtersState.toDate));
   
    if (filterProps.filtersState.deviceId) 
      setFilterItem('id', filterProps.filtersState.deviceId);
    if (filterProps.filtersState.searchTxt) 
        setFilterItem('searchTxt', filterProps.filtersState.searchTxt);
    if (filterProps.filtersState.companyId) 
        setFilterItem('companyId', filterProps.filtersState.companyId);
    loadDevices();
    filterProps.hideFilter();
  }
  useEffect(() => {
   if (filterProps.filtersState.searchTxt)
        setInputValue(filterProps.filtersState.searchTxt)
  }, [])
    return (
    <>
      <Grid className="filterPanel" columns={2}>
        <GridRow>
          <Grid.Column width={13}>
            <div className="filterLeftMarker">
              <Image src="/img/tune.svg" />
              Filters
            </div>          </Grid.Column>
          <Grid.Column width={3}>
            <Image
              src="/img/close.svg"
              className="closeWindow"
              onClick={()=>filterProps.hideFilter()}
            />
          </Grid.Column>
        </GridRow>
        <GridRow>
          <Grid.Column width={16}>
            <Input
              iconPosition="left"
              name="searchImput"
              className="searchText"
              placeholder="Search for SN or computer name"
              onChange={(event, data) => {
                setInputValue(data.value);
                filterStore. searchByText(data.value, setShowSearchCRU, filterProps.setFiltersState);
              }}
              value={inputValue}
            >
              <Icon name="search" />
              <input />
            </Input>
          </Grid.Column>
          {showSearchCRU && (
            <SearchTextBox handlSelectUnit={onChangeSelectedText} />
          )}
        </GridRow>
        <GridRow className="filterTitleRow">
          {' '}
          <Grid.Column width={16}>
            <span className="filtersTitle">By Frequency</span>
          </Grid.Column>
        </GridRow>
        <GridRow>
          <Grid.Column width={15}>
            <Button
              type="button"
              content=" Daily "
              className={
                `filtersOption ${filterProps.filtersState.frequencyList!.indexOf(FrequencyOptions.Daily) !== -1  ? ' selected' : ''  }`}
               onClick={()=>handleFrequencyChanged(FrequencyOptions.Daily,filterProps.filtersState,filterProps.setFiltersState)}
            />
            <Button
              type="button"
              content=" 2 Day "
              className={
                `filtersOption ${filterProps.filtersState.frequencyList!.indexOf(FrequencyOptions.TowDay) !== -1  ? ' selected' : ''  }`}
               onClick={()=>handleFrequencyChanged(FrequencyOptions.TowDay,filterProps.filtersState,filterProps.setFiltersState)}
            />
            <Button
              type="button"
              content=" Weekly "
              className={
                `filtersOption ${filterProps.filtersState.frequencyList!.indexOf(FrequencyOptions.Weekly) !== -1  ? ' selected' : ''  }`}
              onClick={()=>handleFrequencyChanged(FrequencyOptions.Weekly,filterProps.filtersState,filterProps.setFiltersState)}
            />
          </Grid.Column>
        </GridRow>
        <GridRow className="filterTitleRow">
          {' '}
          <Grid.Column width={16}>
            <span className="filtersTitle">By Status</span>
          </Grid.Column>
        </GridRow>
        <GridRow>
          <Grid.Column width={15}>
            <Button
                  icon type="button"
                  className={
                  `filtersOption ${filterProps.filtersState.statusList!.indexOf(StatusOptions.BackupSuccess) !== -1  ? ' selected' : ''  }`}
                  onClick={()=>handleStatusChanged(StatusOptions.BackupSuccess, filterProps.filtersState,filterProps.setFiltersState)} >
                  <Icon name="circle" className='statusBackupSuccess' />
                  Backup Succeeded
            </Button>

            <Button
              icon
              type="button"
              className={
                `filtersOption ${filterProps.filtersState.statusList!.indexOf(StatusOptions.Warning) !== -1  ? ' selected' : ''  }`}
               onClick={()=>handleStatusChanged(StatusOptions.Warning, filterProps.filtersState,filterProps.setFiltersState)}>
              <Icon name="circle" className='statusWarning' />
              Warning
            </Button>

            <Button
              icon
              type="button"
              className={
                `filtersOption ${filterProps.filtersState.statusList!.indexOf(StatusOptions.CriticalError) !== -1  ? ' selected' : ''  }`}
              onClick={()=>handleStatusChanged(StatusOptions.CriticalError ,filterProps.filtersState,filterProps.setFiltersState)} >
              <Icon name="circle" className='statusCriticalError'/>
              Critical Error
            </Button>
          </Grid.Column>
        </GridRow>
        <GridRow>
          <Grid.Column width={15}>
            <Button
              icon
              type="button"
              className={
                `filtersOption ${filterProps.filtersState.statusList!.indexOf(StatusOptions.Registered) !== -1  ? ' selected' : ''  }`}
                 onClick={()=>handleStatusChanged(StatusOptions.Registered ,filterProps.filtersState,filterProps.setFiltersState)}  >
              <Icon name="circle" className='statusRegistered' />
              Registered
            </Button>
            <Button
              icon
              type="button"
              className={
                `filtersOption ${filterProps.filtersState.statusList!.indexOf(StatusOptions.NoBackupOccurred) !== -1  ? ' selected' : ''  }`}
              onClick={()=>handleStatusChanged(StatusOptions.NoBackupOccurred ,filterProps.filtersState,filterProps.setFiltersState)}
            >
              <Icon name="circle" className='statusNoBackupOccurred' />
              No Backup Occurred
            </Button>
          </Grid.Column>
        </GridRow>
        <GridRow className="filterTitleRow">
          {' '}
          <Grid.Column width={16}>
            <span className="filtersTitle">Last Backup</span>
          </Grid.Column>
        </GridRow>
        <GridRow>
          <GridColumn width={8}>
            <Label basic className="filtersLabel">
              From
            </Label>
            <DatePicker className='calendarImput'
                             selected={filterProps.filtersState.fromDate}
                              onChange={(date) => handlFromDate(date,filterProps.setFiltersState)}
                              isClearable
                              dateFormat={FORMAL_DAY_FORMAT}
                              placeholderText={FORMAL_DAY_FORMAT.toUpperCase()}
                              selectsStart
                              startDate={filterProps.filtersState.fromDate}
                              endDate={filterProps.filtersState.toDate}
                              maxDate={filterProps.filtersState.toDate}
                         />
          </GridColumn>
          <GridColumn width={8}>
            <Label basic className="filtersLabel">
              To
            </Label>
            <DatePicker className='calendarImput' 
                              selected={filterProps.filtersState.toDate} 
                              isClearable
                              dateFormat={FORMAL_DAY_FORMAT}   
                              onChange={(date) => handlToDate(date,filterProps.setFiltersState)}  
                              placeholderText={FORMAL_DAY_FORMAT.toUpperCase()}
                              selectsEnd
                              startDate={filterProps.filtersState.fromDate}
                              endDate={filterProps.filtersState.toDate}
                              minDate={filterProps.filtersState.fromDate}
                             />
          </GridColumn>
        </GridRow>
        <GridRow className="buttonsRow">
          <Grid.Column width={10}>
            <Label basic className="resetBtn" onClick={clearDevicesFilter}>
              {' '}
              Clear all
            </Label>
          </Grid.Column>
          <Grid.Column width={5}>
            <Button
              content="Apply"
              type="button"
              onClick={() => {
                applyDevicesFilter();
              }}
              className="applyBtn"
            />
          </Grid.Column>
        </GridRow>
      </Grid>
    </>
  );
});
