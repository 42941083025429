import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, Menu, MenuItem,  Label  } from 'semantic-ui-react';
import { useStore } from '../../../api/stores/store';

export default function MSideBar() {
  const { globalStore } = useStore();
  const { activeUser} = globalStore;
  return (
    
    <Menu stackable pointing
    inverted
    fixed="left"
    vertical
      className={'menuMobile'} >
     <Menu.Item name= '' className='underMenu'>
     </Menu.Item>

     <Menu.Item name="Dashboard" as={NavLink}  to="/devicesDashBoard" className={'menuIconMobile'}>
          <Icon name="dashboard" />  
      </Menu.Item>
    <MenuItem name="Assets" as={NavLink}  to="/devices" className={'menuIconMobile'}>
          <Icon name="microchip" />
    </MenuItem>
    <MenuItem name="LogsReports" as={NavLink}  to={"/reports"}className={'menuIconMobile'}>
       
        <Icon name="share square"  className='sideIcon'/>
    </MenuItem>

      { activeUser?.cloudVersion  &&(
      <MenuItem name="Downloads" as={NavLink}  to="/downloads"  className={'menuIconMobile'}>
     
        <Icon name="download" />
      </MenuItem>   
       )}
   
      <MenuItem name="EmailUs" className='contactUsMenu'>
      <span className='versionMobile'>
                 1.55
       </span>
      <Icon name="mail"  className='contactUsIcon'/>
        <a href="mailto:support@salvador-tech.com"> </a>
      </MenuItem> 
  </Menu>
  );
}
