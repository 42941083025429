import { observer } from 'mobx-react-lite';
import { useStore } from '../../../api/stores/store';
import React from 'react';
import LoginPart from './LoginPart';
import ForgotPasswordPart from './ForgotPasswordPart';


export default observer(function TabLogin() {
  const { accountStore } = useStore();
  const{resetPassword} = accountStore;

  return (
    <>
     {resetPassword  ? (
      < ForgotPasswordPart/>) : 
      (< LoginPart/>)}
    </>
)})

