import { useField } from 'formik';
import React from 'react';
import {  Form, Label, Select } from 'semantic-ui-react';

interface FormSelectProperties {
    placeholder: string;
    name: string;
    options: any;
    label?: string;
    defaultValue?:number;
    required?:boolean;

  }

export default function FormSelect(props: FormSelectProperties) {
    const [field, meta, helpers] = useField(props.name); 
    const cssClass ='formSelectInput '+ (meta.error ? ' error ':'') ;
    return (
        <Form.Field error={meta.touched && !!meta.error} className={cssClass}>
            <label className={props.required ? 'required' : ''} >{props.label}</label>
            <Select 
                options={props.options}
                value={field.value || null}
                onChange={(e, d) => helpers.setValue(d.value)}
                onBlur={() => helpers.setTouched(true)}
                placeholder={props.placeholder}
            />
            {meta.touched && meta.error ? (
                <Label basic color='red'>{meta.error}</Label>
            ) : null}
        </Form.Field>
    )
} 
