import { makeAutoObservable,runInAction } from "mobx";
import agent from "../agent";
import {  SMSInfo, SMTPInfo, SiteSettings } from "../models/SMTPInfo";

export default class SMTPStore{
    sMTPInfo :  SMTPInfo |undefined =undefined
    sMSInfo :  SMSInfo |undefined =undefined
    loadSMTPstate=true;
      sSiteSettings: SiteSettings|undefined=undefined
    loadSettingsState=false;
    loadInitialSMS=false;
    constructor() {
        makeAutoObservable(this);
    }

    loadSMTPInfo= async ()=>
    {
        this.loadSMTPstate = true;
        if (this.sMTPInfo){
            this.loadSMTPstate =false;
        }else{
            try{
                const result =   await agent.Communications.getSMTP();
                runInAction(() =>{
                    this.sMTPInfo = result;
                })
                this.loadSMTPstate =false;
            } catch(error){
                this.loadSMTPstate =false;
            }
        }
    }
 

    loadSMSInfo = async () => {
        this.loadInitialSMS = true;
    
        let pSMSInfo = this.sMSInfo;
        if (pSMSInfo) {
          this.loadInitialSMS = false;
          return pSMSInfo;
        } else {
          try {
            pSMSInfo = await agent.Communications.getSMS();
            runInAction(() => {
              this.sMSInfo = pSMSInfo;
            });
            this.loadInitialSMS = false;
            return pSMSInfo;
          } catch (error) {
            this.loadInitialSMS = false;
          }
        }
      };
    loadSiteSettings= async ()=>
    {
        this.loadSettingsState = true;
        if (this.sSiteSettings){
            this.loadSettingsState =false;
        }else{
            try{
                this.sSiteSettings =   await agent.Communications.getSiteSettings();
                this.loadSettingsState =false;
            } catch(error){
                this.loadSettingsState =false;
            }
        }
    }

    updateSiteSetting= async (siteInfo:SiteSettings) =>
    {  
        try{
            await agent.Communications.updateSiteSettings(siteInfo);
            runInAction(()=>{
                this.sSiteSettings = siteInfo;
            })
        }catch(error){
            throw error;
        }
    }

    updateSMS = async (info: SMSInfo) => {
        try {
          await agent.Communications.updateSMS(info);
          runInAction(() => {
            this.sMSInfo = info;
          });
        } catch (error) {
          throw error;
        }
      };
}

