import { observer } from "mobx-react-lite";
import React, {  useEffect, useState } from "react";
import {  Button, Header, Icon, Item,  ItemDescription,  ItemExtra,  ItemHeader,   Label,   Loader,   Segment } from "semantic-ui-react";
import { useStore } from "../../api/stores/store";
import { PagingParams } from "../../api/models/pagination";
import LoadingComponent from "../UICommon/LoadingComponent";
import { ReportStatusOptions } from "../../api/models/enums";


export default observer(function BackupsListItems() {
    const {backupsStore} = useStore();
    const {  backups ,loadBackups , setPagingParams, backupPagination } = backupsStore;
    const [loadingNext, setLoadingNext] = useState(false);


    function  handleGetNext() {                     
    setLoadingNext(true);
    setPagingParams(new PagingParams(backupPagination!.currentPage +1))
    loadBackups().then(() => setLoadingNext(false));

    }

    function getStatus (status :number)
    {
        switch(status) { 
            case ReportStatusOptions.BackupCancelled:
            case ReportStatusOptions.AttackDetected:
            { 
              return 'error'
            } 
            case ReportStatusOptions.DataSignatureFailure:
            case ReportStatusOptions.BitlockerEncryptionFailed:
            case ReportStatusOptions.CanaryFiles:
            case ReportStatusOptions.DataSignatureFailure:
          { 
                return 'warning'
            } 
            case ReportStatusOptions.BackupFinished: { 
                return 'ok'
            } 
            default: { 
                return ''
            } 
         } 
    }
    useEffect(() => {
         if (backups.length == 0 )   loadBackups();
      }, [backups.length]);

         
    if(backupsStore.loadinInitialBackup && !loadingNext) return <LoadingComponent content='Loading App '/>
    return (
        <Segment.Group  className="mainMobilePageCard" >
           <Header as='h2' content= 'Logs Reports List ' className='SalvadorHeader1'  textAlign='center' />
             {backups.map((backup:any) => (
                    <Segment>
                            <Item.Group >
                            <Item >
                            <Item.Content>
                                     <ItemHeader className={'reportStatus'+backup.status}> 
                                            <Icon name="hdd" />
                                            deviceSN :  {backup.deviceSN}
                                     </ItemHeader>
                            <ItemDescription>{backup.computerName} </ItemDescription>
                            <ItemExtra>Backup Started {backup.backupStarted} </ItemExtra>
                            <ItemExtra>Backup Finished {backup.backupFinished} </ItemExtra>
                           
                            <ItemDescription className={'reportStatus'+backup.status}> 
                                 <Icon name={getStatus(backup.status) == 'error'  || getStatus(backup.status) == 'warning' ? 'attention' : getStatus(backup.status) == 'ok' ? 'checkmark' : undefined}/ >
                                  {backup.statusName}</ItemDescription>
                         </Item.Content>    
                            </Item>

                            </Item.Group>
                    </Segment>
                    ))}
                    <Button floated='right' content='More..' className="ActiveButton"
                         onClick={handleGetNext} loading={loadingNext} disabled={backupPagination!.totalPages === backupPagination!.currentPage}></Button>        
   
        </Segment.Group>
        
    )
})