import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid, GridColumn, GridRow, Image, Segment } from 'semantic-ui-react';
import { useStore } from '../../../api/stores/store';
import * as Yup from 'yup';
import {
  adminLevelOptions,
  userLevelOptions,
} from '../../../api/options/selectOptions';

import { PhoneNumberUtil } from 'google-libphonenumber';
import { PermissionLevel } from '../../../api/models/enums';
import FormCheckBox from '../../UIItems/FormCheckBox';
import FormTextInput from '../../UIItems/FormTextInput';
import MyDropDown from '../../UIItems/FormDropDown';
import SelectCompany from '../../UIItems/SelectCompany';
import FormSelectInput from '../../UIItems/FormSelectInput';
import { ServerResult } from '../../../api/models/common';
import ServerMsgBar from '../../UIItems/ServerMsgBar';
import { MAX_INPUT_LENGTH } from '../../../api/options/constants';
import VerificationBox from '../../UIItems/VerificationBox';
import { UserRemovalRequest } from '../../../api/models/userProfile';

type Props = {
  hideBox: () => void;
  setResponseState: (updateStatus: ServerResult | null) => void;
  newUser?: boolean;
};
export default observer(function UserForm(props: Props) {
  const { userProfileStore, globalStore } = useStore();
  const { companyList, activeUser } = globalStore;
  const { selectedUser,deleteUserProfile,loadUserProfiles} = userProfileStore;
  const [showCompanyBox, setShowCompanyBox] = useState(false);
  const [showCompanyDD, setShowCompanyDD] = useState(false);
  const [showVerificationBox, setShowVerificationBox] = useState(false);
  const [formResponseState, setFormResponseState] = useState<
    ServerResult | undefined
  >(undefined);
  const refCompanyDD = useRef<HTMLDivElement>(null);
  const refCompanyBox = useRef<HTMLDivElement>(null);
  const refVerificationBox = useRef<HTMLDivElement>(null);
  
  const isNewUser: boolean =
    selectedUser === undefined
      ? true
      : selectedUser.id.length === 0
      ? true
      : false;

  const isValidPhoneNumber = (value: any) => {
    if (!value) {
      return true;
    }
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
      const parsedNumber = phoneUtil.parse(value, undefined);
      return phoneUtil.isValidNumber(parsedNumber);
    } catch (e) {
      return false;
    }
  };
  const phoneValidation = Yup.string().test(
    'is-valid-phone',
    'International phone number is not valid',
    isValidPhoneNumber
  );
  function setResponseState(responseState: ServerResult) {
    if (props.setResponseState) props.setResponseState(responseState);
  }
  const handleCloseClick = () => {
    props.hideBox();
  };
  const dummyVoid = () => {};
  function handleCloseCompanyBox() {
    setShowCompanyBox(!showCompanyBox);
  }

  function handleCloseCompanyDD() {
    setShowCompanyDD(!showCompanyDD);
  }
  function handleShowVerificationBox() {
    setShowVerificationBox(true);
  }
  function getCompanyNamesForIds(idList: number[]): string {
    const filteredCompanies = companyList.filter((company) =>
      idList.includes(company.companyId)
    );
    const companyNames = filteredCompanies.map(
      (company) => company.companyName
    );
    return companyNames.join(', ');
  }
  function useOnClickOutside(
    ref: React.RefObject<HTMLElement>,
    handler: (event: MouseEvent) => void
  ) {
    useEffect(() => {
      const handleClick = (event: MouseEvent) => {
        if (!ref.current || ref.current.contains(event.target as Node)) {
          return;
        }
        handler(event);
      };
      document.addEventListener('mousedown', handleClick);
      return;
    }, [ref, handler]);
  }
  useOnClickOutside(refCompanyDD, () => setShowCompanyDD(false));
  useOnClickOutside(refCompanyBox, () => setShowCompanyBox(false));
  useOnClickOutside(refVerificationBox, () => setShowVerificationBox(false));
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };
  function runDeleteUser(id:string, verificationCode:string)
  {
    var user: UserRemovalRequest ={
      id:id,
      verificationCode:verificationCode
    }
     deleteUserProfile(user)
      .then(() => {
        var successResult = {
          isSuccess: true,
          msg: 'User deleted successfully',
        };
        setResponseState(successResult);
        loadUserProfiles();
        handleCloseClick();
      })
      .catch((error) => {
        var failResult = {
          isSuccess: false,
          msg: 'Error on delete user :' + (error.response.status == 401)?"Delete Not Permitted":error
        };
        setResponseState(failResult);
      });
  }
  
  return (
    <Formik
      initialValues={{
        id: selectedUser ? selectedUser.id : '',
        displayName: selectedUser ? selectedUser.displayName : '',
        companyId: selectedUser
          ? selectedUser.companyId
          : activeUser && activeUser.listCompanies!.length === 1
          ? activeUser.companyId
          : 0,
        companyName: selectedUser ? selectedUser.companyName : '',
        level: selectedUser ? selectedUser.level : PermissionLevel.User,
        levelName: selectedUser ? selectedUser.levelName : '',
        status: selectedUser ? selectedUser.status : 1,
        statusName: selectedUser ? selectedUser.statusName : '',
        email: selectedUser ? selectedUser.email : '',
        phoneNumber: selectedUser ? selectedUser.phoneNumber : '',
        password: '',
        confirmNewPassword: '',
        lastEnter: selectedUser ? selectedUser.lastEnter : '',
        passwordLastChange: selectedUser ? selectedUser.passwordLastChange : '',
        backupSuccessSendMail: selectedUser
          ? selectedUser.backupSuccessSendMail
          : false,
        backupSuccessSendSMS: selectedUser
          ? selectedUser.backupSuccessSendSMS
          : false,
        backupFailureSendMail: selectedUser
          ? selectedUser.backupFailureSendMail
          : true,
        backupFailureSendSMS: selectedUser
          ? selectedUser.backupFailureSendSMS
          : false,
        attacDetectedSendMail: selectedUser
          ? selectedUser.attacDetectedSendMail
          : true,
        attacDetectedSendSMS: selectedUser
          ? selectedUser.attacDetectedSendSMS
          : false,
        partnerCompanies: selectedUser ? selectedUser.partnerCompanies : [],
        partnerCompaniesList:
          selectedUser && selectedUser.partnerCompanies
            ? getCompanyNamesForIds(selectedUser.partnerCompanies)
            : [],
        partnerSiteNumber: selectedUser ? selectedUser.partnerSiteNumber : '',
        error: null,
      }}
      onSubmit={(values) => {
        var msgSuccesUpdate = 'The User Profile is Successfully Updated ';
        var msgSuccesCreate = 'The New User  is Successfully Registered .';
        var msgErrorUpdate = 'Error on Update The User Profile : ';
        var msgErrorCreate = 'Error on Registered New  User Profile : ';

        userProfileStore
          .saveUserProfile(values)
          .then((res) => {
            var successResult = {
              isSuccess: true,
              msg: isNewUser ? msgSuccesCreate : msgSuccesUpdate,
            };
            if (isNewUser) {
              values.id = res!.id;
              values.levelName = res!.levelName;
            }
            userProfileStore.setUserProfile(values);
            setResponseState(successResult);
            props.hideBox();
          })
          .catch((error) => {
            var failResult = {
              isSuccess: false,
              msg: isNewUser ? msgErrorCreate + error : msgErrorUpdate + error,
            };
            setFormResponseState(failResult);
          });
      }}
      validationSchema={Yup.object({
        backupSuccessSendSMS: Yup.boolean(),
        backupFailureSendSMS: Yup.boolean(),
        attacDetectedSendSMS: Yup.boolean(),
        phoneNumber: phoneValidation.when(
          [
            'backupSuccessSendSMS',
            'backupFailureSendSMS',
            'attacDetectedSendSMS',
          ],
          {
            is: (
              backupSuccessSendSMS: boolean,
              backupFailureSendSMS: boolean,
              attacDetectedSendSMS: boolean
            ) =>
              backupSuccessSendSMS === true ||
              backupFailureSendSMS === true ||
              attacDetectedSendSMS === true,
            then: Yup.string().required(
              'Phone Number required when  one of Send SMS checkboxes are checked'
            ),
          }
        ),

        id: Yup.string(),
        password: Yup.string()
          .transform((_, value: string) => {
            return value === '' ? null : value;
          })
          .when('id', {
            is: (id: string) => id === '',
            then: Yup.string()
              .required('Password is  required ')
              .min(6, 'Password should be longer than 5 symbols')
              .matches(
                /(?=.*[A-Z])/,
                " Must have at least one uppercase ('A'-'Z')"
              )
              .oneOf([Yup.ref('confirmNewPassword')], 'Passwords must match'),
            otherwise: Yup.string().nullable(true),
          }),
        displayName: Yup.string()
          .required('Display Name is a required field')
          .max(MAX_INPUT_LENGTH, `Field should not exceed ${ MAX_INPUT_LENGTH } characters`),
        companyId: Yup.number()
          .required('Company is a required field')
          .min(1, 'Company is a required field'),
        email: Yup.string()
          .required('Email is a required field')
          .email('Email is a Invalid '),

        confirmNewPassword: Yup.string()
          .oneOf([Yup.ref('password')], 'Passwords must match')
          .nullable(true)
          .transform((_, value: string) => {
            return value === '' ? null : value;
          }),
      })}
    >
      {({ handleSubmit, values }) => {
        function setPartnerList(companyList: number[]) {
          values.partnerCompanies = companyList;
          values.partnerCompaniesList = getCompanyNamesForIds(companyList);
        }
        function setUserCompany(companyId: number, companyName: string) {
          values.companyId = companyId;
          values.companyName = companyName;
          setShowCompanyDD(false);
        }
        return (
          <Segment raised compact className="addFormBox">
          <Form
            onKeyDown={handleKeyDown}
            onSubmit={handleSubmit}
            autoComplete="off"
            style={{ height: '630px' }}
          >
            <Grid className="addForm">
              <GridRow>
                <GridColumn width={14}>
                  <Image src="/img/User.svg" className="formSubTitleImg" />
                  <span className="formSubTitle">
                    {isNewUser ? 'Add User' : 'User Details'}
                  </span>
                </GridColumn>
                <GridColumn width="2" floated="right">
                  <Image
                    src="/img/close.svg"
                    className="closeWindow"
                    onClick={handleCloseClick}
                  />
                </GridColumn>
              </GridRow>
              <br />

              <div className="scrollForm">
                <GridRow className="formFieldRow">
                  <GridColumn>
                    <FormTextInput
                      name="displayName"
                      placeholder="Display Name"
                      title="Display Name"
                    />
                  </GridColumn>
                </GridRow>
                <GridRow className="formFieldRow">
                  <GridColumn style={{ width: '350px' }}>
                    <FormTextInput
                      name="email"
                      placeholder="Email"
                      title="Email"
                    />
                  </GridColumn>
                </GridRow>
                <GridRow className="formFieldRow">
                  <GridColumn>
                    <FormTextInput
                      name="phoneNumber"
                      placeholder="Phone"
                      title="Phone"
                      autoComplete="new-password"
                    />
                  </GridColumn>
                </GridRow>
                {activeUser!.listCompanies!.length > 1 && (
                  <GridRow className="formFieldRow">
                    <GridColumn>
                      <FormSelectInput
                        title="Company"
                        name="companyName"
                        placeholder="Select user company"
                        setShowSelectBox={handleCloseCompanyDD}
                        required
                      />
                      {showCompanyDD && (
                       <div ref={refCompanyDD}>
                          {' '}
                          <SelectCompany
                            runOkBtn={dummyVoid}
                            runCuncelBtn={dummyVoid}
                            setSelectedCompanyList={dummyVoid}
                            sourceCompanyList={values.partnerCompanies}
                            className="companiesList"
                            oneselect
                            setSelectedCompanyId={setUserCompany}
                          />
                        </div>
                      )}
                    </GridColumn>
                  </GridRow>
                )}
                <GridRow className="formFieldRow">
                  <GridColumn>
                    {activeUser!.level &&
                      activeUser!.level !== PermissionLevel.User && (
                        <MyDropDown
                          name="level"
                          placeholder="Permission level"
                          options={
                            activeUser!.level <= PermissionLevel.SystemAdmin
                              ? adminLevelOptions
                              : userLevelOptions
                          }
                          title="Permission  level"
                          defaultValue={values.level}
                          value={values.level}
                        />
                      )}
                    {activeUser!.level! === PermissionLevel.User && (
                      <span className="formSelectInput">
                        {' '}
                        {values.levelName}
                      </span>
                    )}
                  </GridColumn>
                </GridRow>
                {values!.level === PermissionLevel.Partner && (
                  <GridRow className="formFieldRow">
                    <GridColumn>
                      <FormSelectInput
                        title="Partner companies"
                        name="partnerCompaniesList"
                        placeholder="Select partner companies"
                        setShowSelectBox={handleCloseCompanyBox}
                      />
                      {showCompanyBox && (
                        <div ref={refCompanyBox}>
                          <SelectCompany
                            runOkBtn={() => setShowCompanyBox(false)}
                            runCuncelBtn={dummyVoid}
                            setSelectedCompanyList={setPartnerList}
                            sourceCompanyList={values.partnerCompanies}
                            className="companiesList"
                            showButtons={true}
                          />
                        </div>
                      )}
                    </GridColumn>
                  </GridRow>
                )}
                {!selectedUser && (
                  <>
                    <GridRow className="formFieldRow">
                      <Grid.Column width={8}>
                        <FormTextInput
                          name="password"
                          placeholder="Password"
                          icon="lock"
                          type="password"
                          title="Create Password "
                          iconPosition="left"
                          autoComplete="new-password"
                          //  labelPointing="Password must be at least 6 characters and must have at least one uppercase ('A'-'Z') "
                        />
                      </Grid.Column>
                    </GridRow>
                    <GridRow className="formFieldRow">
                      <Grid.Column width={8}>
                        <FormTextInput
                          name="confirmNewPassword"
                          placeholder="Confirm password"
                          title="Retype password "
                          type="password"
                          icon="lock"
                          iconPosition="left"
                          autoComplete="new-password"
                        />
                      </Grid.Column>
                    </GridRow>
                  </>
                )}
                <br />
                <GridRow className="formFieldRow">
                  <GridColumn>
                    <Image
                      src="/img/notifications.svg"
                      className="formSubTitleImg"
                    />
                    <span className="formSubTitle">Manage notifications</span>
                    <span className="subLabel">
                      Please select the notifications you wish to receive
                    </span>
                  </GridColumn>
                </GridRow>

                <GridRow>
                  <GridColumn>
                    <Grid columns="equal" divided="vertically">
                      <GridRow>
                        <GridColumn></GridColumn>
                        <GridColumn width={3}>Email</GridColumn>
                        <GridColumn width={3}>SMS</GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn className="formCheckBoxLabel">
                          On every successful backup
                        </GridColumn>
                        <GridColumn width={3}>
                          <FormCheckBox
                            name="backupSuccessSendMail"
                            className="notificationCheck"
                          ></FormCheckBox>
                        </GridColumn>
                        <GridColumn width={3}>
                          <FormCheckBox
                            name="backupSuccessSendSMS"
                            className="notificationCheck"
                          ></FormCheckBox>
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn className="formCheckBoxLabel">
                          Backup not received on time
                        </GridColumn>
                        <GridColumn width={3}>
                          <FormCheckBox
                            name="backupFailureSendMail"
                            className="notificationCheck"
                          ></FormCheckBox>
                        </GridColumn>
                        <GridColumn width={3}>
                          <FormCheckBox
                            name="backupFailureSendSMS"
                            className="notificationCheck"
                          ></FormCheckBox>
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn className="formCheckBoxLabel">
                          Attack detection during backup
                        </GridColumn>
                        <GridColumn width={3}>
                          <FormCheckBox
                            name="attacDetectedSendMail"
                            className="notificationCheck"
                          ></FormCheckBox>
                        </GridColumn>
                        <GridColumn width={3}>
                          <FormCheckBox
                            name="attacDetectedSendSMS"
                            className="notificationCheck"
                          ></FormCheckBox>
                        </GridColumn>
                      </GridRow>
                    </Grid>
                  </GridColumn>
                </GridRow>
              </div>
              <GridRow className="panelButtonsRow">
                <Grid.Column width={10}>
                {!isNewUser  && (<Button
                    content={'Delete '}
                    type='button'
                    className="applyBtn sysAction"
                    onClick ={handleShowVerificationBox} 
                    />)}
                </Grid.Column>
                <Grid.Column width={5}>
                  <Button
                    content={values.id.length === 0 ? 'Register ' : 'Update '}
                    type="submit"
                    className="applyBtn"
                  />
                </Grid.Column>
              </GridRow>
              <br />
              {formResponseState && (
                <ServerMsgBar runActionResult={formResponseState} />
              )}
              {showVerificationBox && (
                   <div ref={refVerificationBox}>
                         <VerificationBox 
                         name='id' 
                         title='Delete User Verification Code' 
                         id={values.id}
                         runOnApprove={runDeleteUser}
                         hideVerificationBox={()=>setShowVerificationBox(false)}></VerificationBox>
                  </div>)}
            </Grid>
          </Form>
          </Segment>
        );
      }}
    </Formik>
  );
});
