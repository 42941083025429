import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import {  Grid, GridColumn, GridRow,Table, Image, Segment, TableHeader, TableHeaderCell, Popup, TableRow } from 'semantic-ui-react';
import { useStore } from '../../../api/stores/store';
import { BitLockerKey } from '../../../api/models/device';

interface BitLockerKeyListProperties {
    hideWindow: () => void;
}

export default observer(function BitLockerKeyList(props: BitLockerKeyListProperties)
{
    const {deviceStore} = useStore();
    const {deviceBitLockerKeys} = deviceStore;
    const [sortedKeys, setSortedKeys] = useState<BitLockerKey[]>(deviceBitLockerKeys);
    const [sortField, setSortField] = useState<'creationDate' | 'lastBackup' | null>(null);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    
    const canUseNavigatorClipboard = () => {
        return !!navigator.clipboard && typeof navigator.clipboard.writeText === 'function';
      };
   const canUseExecCommandCopy = () => {
        const textArea = document.createElement('textarea');
        document.body.appendChild(textArea);
        textArea.value = 'test';
        textArea.select();
        let successful;
        try {
          successful = document.execCommand('copy');
        } catch (err) {
          successful = false;
        }
        document.body.removeChild(textArea);
        return successful;
      };

      const copyToClipboard = async (textToCopy:string) => {
        if (canUseNavigatorClipboard()) {
              await navigator.clipboard.writeText(textToCopy);
          } else if (canUseExecCommandCopy()) {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;
            document.body.appendChild(textArea);
            textArea.select();
            const successful = document.execCommand('copy');
            document.body.removeChild(textArea);
          }
   }

     function sortKeys  (field: 'creationDate' | 'lastBackup')  {
        const isAsc = sortField === field && sortDirection === 'asc';
        const newDirection = isAsc ? 'desc' : 'asc';
        
        const sorted = [...sortedKeys].sort((a, b) => {
          const dateA = new Date(a[field]).getTime();
          const dateB = new Date(b[field]).getTime();
          return newDirection === 'asc' ? dateA - dateB : dateB - dateA;
        });
    
        setSortedKeys(sorted);
        setSortField(field);
        setSortDirection(newDirection);
      };

    return(
        <Segment className='modalCenter'>
        <Grid >
                <GridRow >
                        <GridColumn >
                                <>
                                <div>
                                   <Image  className='modelCloseWindow' src="/img/close.svg"  onClick={()=>props.hideWindow()}/>
                                    <label className='modalTitleL'  >BitLocker Recovery Key</label>

                                </div>
                                <div  className='modalHeader'>
                                         <Image   src="/img/disk.svg"/> {deviceBitLockerKeys && deviceBitLockerKeys[0] && deviceBitLockerKeys[0].deviceSN}
                                </div>
                                <Table    stackable sortable  className='recoveryKeyList' >
                                    <TableHeader>
                                        <TableRow>
                                        <Table.HeaderCell    className="nonSortable" > 
                                                        Key
                                                </Table.HeaderCell>
                                                <Table.HeaderCell  
                                                        sorted={sortField === 'creationDate' ? sortDirection === 'asc'? 'ascending':'descending' : undefined}
                                                        onClick={() => sortKeys('creationDate') } >
                                                        Created
                                                    </Table.HeaderCell>
                                                <Table.HeaderCell   
                                                        sorted={sortField === 'lastBackup' ? sortDirection === 'asc'? 'ascending':'descending' : undefined}
                                                        onClick={() => sortKeys('lastBackup') } >
                                                        Last Backup
                                                    </Table.HeaderCell>                                            
                                        </TableRow>
                                    </TableHeader>
                                <Table.Body>
                                        {sortedKeys.map((key :BitLockerKey) => (
                                            <Table.Row key={key.id} >
                                                <Table.Cell className='inline-content '>
                                                    { (canUseNavigatorClipboard() || canUseExecCommandCopy()) && (
                                                            <Popup content='Copy the Recovery Key  ' trigger={<Image  src="/img/copy-thin.svg"  onClick={() => copyToClipboard(key.bitLockerRecoveryKey)} />} />
                                                    )}
                                                    {key.bitLockerRecoveryKey}
                                                </Table.Cell>
                                                <Table.Cell width={2}>{key.creationDate}</Table.Cell>
                                                <Table.Cell width={2}>{key.lastBackup}</Table.Cell>
                                        </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                                </>
                 
                        </GridColumn>
                </GridRow>          
                <GridRow>
        </GridRow>
        </Grid>
        </Segment>
    )
})