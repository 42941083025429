import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import {
  CRUTexts,
  Device,
  DashboardIndicators ,
  ThinDevice,
  DeviceForUpdate,
  CRUType,
  RecoveryKeyRequest,
  BitLockerKey,
} from './models/device';
import { Backup } from './models/backup';
import { RegestrySet } from './models/regestrySet';
import {
  ApprovePasswordValues,
  User,
  UserFormValues,
  UserProfile,
  UserRemovalRequest,
} from './models/userProfile';
import { store } from './stores/store';
import {
  forPrintDTO,
  PaginatedResult,
  uploadResDTO,
} from './models/pagination';
import { DashboardRow, Statistic } from './models/statistic';
import { Company, PartnerDTO, TimeZoneDTO } from './models/company';
import { SMSInfo, SMTPInfo, SiteSettings } from './models/SMTPInfo';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const sysUpdateAxios = axios.create({
  baseURL: process.env.REACT_APP_UPD_URL,
});
axios.interceptors.request.use((config) => {
  const token = store.globalStore.token;
  if (token) config.headers!.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    const pagination = response.headers['pagination'];
    if (pagination) {
      response.data = new PaginatedResult(
        response.data,
        JSON.parse(pagination)
      );
      return response as AxiosResponse<PaginatedResult<any>>;
    }
    return response;
  },
  (error: AxiosError) => {
    const { data, status, config } = error.response!;
    switch (status) {
      case 400:
        if (config.method === 'get' && data.errors.hasOwnProperty('id')) {
        }
        if (data.errors) {
          const modalStateErrors = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
              modalStateErrors.push(data.errors[key]);
            }
          }
          throw modalStateErrors.flat();
        } else {
          toast.error('Bad Request' + data.errors[0]);
        }
        break;
      case 404:
        toast.error('Not Found');
        break;

      case 500:
        toast.error('Server Error');
        break;
    }
    return Promise.reject(error);
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;
const uploadConfig = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};
const httpsConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};
const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
  upload: <T>(url: string, formData: {}) =>
    axios.post<T>(url, formData, uploadConfig).then(responseBody),
  run: <T>(url: string) => axios.post<T>(url).then(responseBody),
  sysUpd: <T>(url: string) => sysUpdateAxios.post<T>(url).then(responseBody),

  httpsPost: <T>(url: string, body: {}) =>
    axios.post<T>(url, body, httpsConfig).then(responseBody),
};
const UserProfiles = {
  list: (params: URLSearchParams) => axios.get<PaginatedResult<UserProfile[]>>('/User', { params }) .then(responseBody),
  listPartners: () =>
    axios.get<UserProfile[]>('/Admin/ListPartners').then(responseBody),
  details: (id: string) => requests.get<UserProfile>(`/User/${id}`),
  update: (user: UserProfile) => requests.put<void>('/User/', user),
  delete: (userRemovalRequest:UserRemovalRequest) => requests.del<void>('/User/DeleteUser', userRemovalRequest),
  assignToPartner: (set: PartnerDTO) =>
    requests.post<void>('/Admin/AssignToPartner', set),
};
const Devices = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<Device[]>>('/Device', { params })
      .then(responseBody),
  details: (id: number) => requests.get<Device>(`/Device/${id}`),
  create: (device: Device) => requests.post<void>('/Device', device),
  update: (device: DeviceForUpdate) =>
    requests.post<void>('Device/EditDevice', device),
  delete: (id: number) => requests.del<void>('/Device', id),
  regestry: (device: ThinDevice) =>
    requests.post<void>('Device/RegestryDevice', device),
  setPublished: (devicesList: string[]) =>
    requests.post<void>('Device/SetPublished', devicesList),
  setForPrint: (device: forPrintDTO) =>
    requests.post<void>(`/Device/PreparePrint`, device),
  statistic: (params: URLSearchParams) =>
    axios.get<Statistic[]>('Device/DevicesStatistics', { params }),
  statusChart: (params: URLSearchParams) =>
    axios.get<Statistic[]>('Device/DevicesByStatus', { params }),
  cruTexts: (param: string) =>
    requests.get<PaginatedResult<CRUTexts[]>>(`/Device/CRUTexts${param}`),
  cruTypes: () =>requests.get<CRUType[]>(`/Device/CRUTypes`),
  dbNoBackupsList: (params: URLSearchParams) =>
    axios.get<Device[]>('Device/NoBackups', { params }),
  dbAttackPossibleList: (params: URLSearchParams) =>
    axios.get<Device[]>('Device/AttackPossible', { params }),
  dbCriticalAssetList: (params: URLSearchParams) =>
    axios.get<Device[]>('Device/CriticalErrorsAsset', { params }),
  dbDashboardIndicators: (params: URLSearchParams) =>
    axios.get<DashboardIndicators>('Device/DashboardIndicators', { params }),
  dbBackupSuccess: (params: URLSearchParams) =>
    axios.get<Device[]>('Device/BackupSuccessAsset', { params }),
  confirmNewSN: (numNewSN: number) =>
    axios.post<void>(`Admin/SendConfirmEmail?numNewSN=${numNewSN}`),
  generateSN: (numNewSN: number) =>
    axios.post<void>(`Admin/RegestryNewSN?numNewSN=${numNewSN}`),
  getBitLockerKey: (recoveryKeyRequest: RecoveryKeyRequest) => axios.post<BitLockerKey[]>('Device/RecoveryKey', recoveryKeyRequest ),
};
const Account = {
  current: () => requests.get<User>('/Account'),
  login: (user: UserFormValues) => requests.post<User>('/Account/login', user),
  register: (user: UserProfile) =>
    requests.post<User>('/Account/Register', user),
  registerSet: (set: RegestrySet) =>
    requests.post<User>('/Account/RegisterSet', set),
  company: () => requests.get<Company>(`Account/UserCompany`),
  resetPassword: (email: string) =>
    axios.post<void>(`Account/Reset-link?email=${email}`),
  approvePassword: (set: ApprovePasswordValues) =>
    requests.post<User>('/Account/ApprovePassword', set),
  approveEmail: (set: ApprovePasswordValues) =>
    requests.post<void>('/Account/ApproveEmail', set),
};
const UserCompany = {
  details: (id: string) => requests.get<Company>(`/Company/${id}`),
  update: (company: Company) => requests.put<void>(`/Company/`, company),
  listTimeZones: () => axios.get<TimeZoneDTO[]>('/Company/GetGetTimeZonesList'),
};

const Backups = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<Backup[]>>('/DeviceReport', { params })
      .then(responseBody),
  details: (id: number) => requests.get<Backup>(`/Backup/${id}`),
  statistic: (params: URLSearchParams) =>
    axios.get<Statistic[]>('DeviceReport/ReportsStatistics', { params }),
  getLastBackup: (params: URLSearchParams) =>
    axios.get<DashboardRow>('/DeviceReport/DashboardRow', { params }),
};

const Resources = {
  list: () => requests.get<uploadResDTO[]>('Admin/GetActiveResources'),  upload: (fileResourse: FormData) =>    requests.upload<void>('Admin/UploadResorce', fileResourse),
  runSysUpdate: () => requests.sysUpd<void>('Admin/RunSysUpdate'),
  uploadPack: (fileResourse: FormData) =>    requests.upload<void>('Admin/UploadSysUpdate', fileResourse),
  runDBExport: () => axios.post<void>('SysUpdate/RunExportDB'),
  runDBImport: () => axios.post('SysUpdate/RunImportDB'),
  uploadDBImport: (fileResourse: FormData) =>    requests.upload<void>('SysUpdate/UploadDBFile', fileResourse),
};

const Communications = {
  getSMTP: () => requests.get<SMTPInfo>('Admin/SMTPPrefs'),
  getSMS: () => requests.get<SMSInfo>('Admin/SMSPrefs'),
  getSiteSettings: () => requests.get<SiteSettings>('Admin/SiteSettings'),
  updateSMTP: (info: SMTPInfo) => requests.post<void>('/Admin/SMTPPrefs', info),
  updateSMS: (info: SMSInfo) => requests.post<void>('/Admin/SMSPrefs', info),
  updateSiteSettings: (info: SiteSettings) =>
    requests.httpsPost<void>('/Admin/SiteSettings', info),
};

const agent = {
  UserProfiles,
  Devices,
  Account,
  Backups,
  UserCompany,
  Resources,
  Communications,
};
export default agent;
