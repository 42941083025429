import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import {
  Grid,
  GridColumn,
  Tab,
  Image,
  Segment,
  Button,
} from 'semantic-ui-react';
import queryString from 'query-string';
import { isBrowser, isMobile } from 'react-device-detect';
import { useStore } from '../../api/stores/store';
import MApprovePasswordPart from './MApprovePasswordPart';
import MTabRegisterCompany from './MTabRegisterCompany';
import MTabRegisterNS from './MTabRegisterNS';
import MTabLogin from './MTabLogin';

export default observer(function MStartPage() {
  const { accountStore } = useStore();
  const { activeTabIndex, setActiveTabIndex } = accountStore;
  const [showRederectMsg, setShowRederectMsg] = useState(true);
  const panes = [
    {
      menuItem: {
        key: 'login',
        icon: 'user circle',
        content: isBrowser ? 'Login' : '',
      },
      render: () => (
        <Tab.Pane>
          <MTabLogin />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: 'registerNS',
        icon: 'hdd',
        content: isBrowser ? 'Register SN' : '',
      },
      render: () => (
        <Tab.Pane>
          <MTabRegisterNS />{' '}
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: 'registerCompany',
        icon: 'building',
        content: isBrowser ? 'Register  Company Site' : '',
      },
      render: () => (
        <Tab.Pane>
          <MTabRegisterCompany />{' '}
        </Tab.Pane>
      ),
    },
  ];

  const panes1 = [
    {
      menuItem: {
        key: 'login',
        icon: 'user circle',
        content: 'Change Password',
      },
      render: () => (
        <Tab.Pane>
          <MApprovePasswordPart />
        </Tab.Pane>
      ),
    },
  ];

  const parsed = queryString.parse(window.location.search);
  const externalData = {
    email: window.location.search === '' ? '' : parsed.email,
    password: '',
    token: window.location.search === '' ? '' : parsed.activationToken,
  };
  const arrivalType = window.location.search === '' ? 'login' : parsed.type;

  useEffect(() => {
    if (arrivalType === 'login' && externalData.token !== '')
      accountStore.approveEmail(externalData);
  }, [arrivalType, externalData]);
  return (
    <>
      <Grid
        inverted
        textAlign="center"
        className="masthead"
        verticalAlign="top"
      >
        <Grid.Row verticalAlign="top">
          <GridColumn />
          <GridColumn width="7">
            <Image
              src="/img/V1.55/SalvadorLogo.png"
              className="mainLogo"
              size={isMobile ? 'small' : 'large'}
            />
          </GridColumn>
          <GridColumn />
        </Grid.Row>
        <Grid.Row columns={1} verticalAlign="top" className="startPageBady">
          <GridColumn className={'mobailTab'}>
            {arrivalType === 'pwReset' ? (
              <Tab
                verticalAlign="top"
                menu={{
                  attached: true,
                  tabular: true,
                  className: 'collorSet2',
                }}
                panes={panes1}
              />
            ) : (
              <Tab
                verticalAlign="top"
                activeIndex={activeTabIndex}
                onTabChange={(e, data) => {
                  setActiveTabIndex(data, 0);
                }}
                menu={{
                  attached: true,
                  tabular: true,
                  className: 'collorSet2',
                }}
                panes={panes}
              />
            )}
          </GridColumn>
        </Grid.Row>
        {activeTabIndex !== 2 && (
          <Grid.Row verticalAlign="bottom" className="startPageBottom">
            <GridColumn
              verticalAlign="bottom"
              className={isMobile ? 'LinksImagesCol' : ''}
            >
              <Image
                src="/img/V1.55/SP_1.png"
                size="small"
                href="/rsc/PD.pdf"
                target={'_blank'}
                className="bottomLinksImages"
              ></Image>
              <br />
            </GridColumn>

            <GridColumn
              verticalAlign="bottom"
              className={isMobile ? 'LinksImagesCol' : ''}
            >
              <Image
                src="/img/V1.55/SP_2.png"
                size="small"
                className="bottomLinksImages"
                href="https://www.youtube.com/watch?v=hu9PlIpT2f4"
                target={'_blank'}
              ></Image>
            </GridColumn>

            <GridColumn
              verticalAlign="bottom"
              className={isMobile ? 'LinksImagesCol' : ''}
            >
              <Image
                src="/img/V1.55/SP_3.png"
                size="small"
                className="bottomLinksImages"
                href="https://support.salvador-tech.com/Resources/CRU_User_ManualLastVersion.pdf"
                target={'_blank'}
              ></Image>
            </GridColumn>

            <GridColumn
              verticalAlign="bottom"
              className={isMobile ? 'LinksImagesCol' : ''}
            >
              <Image
                src="/img/V1.55/SP_4.png"
                size="small"
                className="bottomLinksImages"
                href="https://www.salvador-tech.com/"
                target={'_blank'}
              ></Image>
            </GridColumn>

            <GridColumn
              verticalAlign="bottom"
              className={isMobile ? 'LinksImagesCol' : ''}
            >
              <Image
                src="/img/V1.55/SP_5.png"
                size="small"
                className="bottomLinksImages"
                href="https://www.youtube.com/channel/UC3ytVKx8UiDFFs-_LFo-Xdw"
                target={'_blank'}
              ></Image>
            </GridColumn>

            <GridColumn
              verticalAlign="bottom"
              className={isMobile ? 'LinksImagesCol' : ''}
            >
              <Image
                src="/img/V1.55/SP_6.png"
                size="small"
                className="bottomLinksImages"
                href="https://www.salvador-tech.com/contact"
                target={'_blank'}
              ></Image>
            </GridColumn>
          </Grid.Row>
        )}
      </Grid>
      {showRederectMsg &&(
      <Segment className='forRedirect'>
        For full functionality <br />
         please use a desktop browser.<br />
        Click   
        <Button   basic color='blue' content='OK'  onClick={() => setShowRederectMsg(false)}/><br />
        to continue to the mobile site
      </Segment>)}
    </>
  );
});
