import { makeAutoObservable, runInAction } from 'mobx';
import { Company} from "../models/company";
import agent from '../agent';
import { selectOptions } from '../models/common';


export default class companyStore {
  userCompany: Company | undefined = undefined;
  editMode = false;
  loadInitial = true;
  updating = false;
  timeZoneOptions: selectOptions[]|undefined = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  loadUserCompany = async () => {
    this.setLoadinInitial(true);
    let company = this.userCompany;
    if (company) {
      this.setLoadinInitial(false);
      return company;
    } else {
      try {
        company = await agent.Account.company();
        runInAction(() => {
          this.userCompany = company;
        });

        this.setLoadinInitial(false);
        return company;
      } catch (error) {
        this.setLoadinInitial(false);
      }
    }
  };

  updateCompany = async (company: Company) => {
    this.updating = true;
    if (company.inactive || company.inactive === 1)
         company.inactive =1;
    else
          company.inactive =0;
        
    try {
      await agent.UserCompany.update(company);
      runInAction(() => {
        this.updating = false;
        this.editMode = false;
        this.userCompany = company;
      });
    } catch (error) {
      this.updating = false;
      this.editMode = false;
      throw error;
    }
  };
  loadCompany = async (id: string) => {
      this.setLoadinInitial(true);
      try {
        var company = await agent.UserCompany.details(id);
        this.userCompany = company;
     } 
      catch (error)
           {throw error; }
      finally
           {this.setLoadinInitial(false);}

  };
  setLoadinInitial = (state: boolean) => {
    this.loadInitial = state;
  };
  loadTimeZoneOptions = async () => {
      const result = await agent.UserCompany.listTimeZones();
      this.timeZoneOptions = result.data.map((timezone) => ({
        text: timezone.displayName,
        value: timezone.id,
      })); 
  };
}
