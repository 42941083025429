import { observer } from 'mobx-react-lite';
import React, { useState,  } from 'react';
import {Grid, GridColumn,  Tab,Image,  Accordion, List,} from 'semantic-ui-react';
import { useStore } from '../../../api/stores/store';
import TabLogin from './TabLogin';
import TabRegisterNS from './TabRegisterNS';

import queryString from 'query-string';
import ApprovePasswordPart from './ApprovePasswordPart';
import { isBrowser} from 'react-device-detect';
import TabRegisterCompany from './TabRegisterCompany';
import { useLocation } from 'react-router-dom';
import '../../styles_start.css';

export default observer(function StartPage() {
  const location = useLocation();
  const { accountStore } = useStore();
  const { activeTabIndex,setActiveTabIndex } = accountStore;
  const [showRegCompany, setShowRegCompany] = useState(false);

  const panes = [
    { menuItem: { key: 'login',  content: isBrowser? 'Login' :''},
      render: () => ( <Tab.Pane><TabLogin/></Tab.Pane>), },
    { menuItem: { key: 'registerNS',  content: isBrowser?'Register SN':'' },
      render: () => (  <Tab.Pane><TabRegisterNS/>   </Tab.Pane>  ),  } ];
    const panes1 = [
      { menuItem: { key: 'login', content: 'Change Password' },
       render: () => ( <Tab.Pane><ApprovePasswordPart/></Tab.Pane>), } ];      const arrivalType = location.search  === ''? 'login': queryString.parse(location.search).type;

      const AdvancedContent = (
        <List className='accordionList'>
    <List.Item> 
        <Image    src="/img/PDescription.svg" />  
        <a  href='/rsc/PD.pdf' target={'_blank'}>Product Description</a>
    </List.Item>
    <List.Item >
        <Image   src="/img/import_contacts.svg" />  
        <a  href='https://support.salvador-tech.com/Resources/CRU_User_ManualLastVersion.pdf' target={'_blank'} rel="noreferrer">User Manual</a>
    </List.Item>
    <List.Item >
        <Image   src="/img/InstalDemo.svg" />  
        <a  href='https://www.youtube.com/watch?v=hu9PlIpT2f4' target={'_blank'} rel="noreferrer">Installation Demo</a>
    </List.Item>
    <List.Item >
        <Image    src="/img/YouTube.svg" />  
        <a  href='https://www.youtube.com/channel/UC3ytVKx8UiDFFs-_LFo-Xdw' target={'_blank'} rel="noreferrer">YouTube</a>
    </List.Item>
    <List.Item >
      <Image    src="/img/Contact.svg" />  
        <a  href='https://www.salvador-tech.com/contact' target={'_blank'} rel="noreferrer">Contact</a>
    </List.Item>
  </List>
      )
      const AdvancedTitle = (<div className='accordionEnter'><span className='dots'>...</span>
         <Image  className='accordionImg'  src="/img/world.svg" />  </div>
      )
      const rootPanels = [{ key: 'panel-1', title:  { content: AdvancedTitle }, content: { content: AdvancedContent } }]
  return   (

    <Grid     inverted textAlign="center"  className="startPage"  >
        <Grid.Row   >
          <Grid  className='startPageGrid'  >
              <Grid.Row   >
                    <GridColumn  width="3" >
                          <Image   src="/img/Layer_1.svg" />
                    </GridColumn>

                    <GridColumn  width="10"  className='justifyCenter'>
                    {!showRegCompany ? (
                        <Grid className='startPageTabGrid' >
                               <Grid.Row    > 
                                        <GridColumn className='fullHeight'  >
                                         {arrivalType ===  'pwReset' ? (
                                                <Tab   menu={{   secondary: true, pointing: true ,className:'startPageTabRow'}}  panes={panes1} />
                                          ) : 
                                                 <Tab activeIndex={activeTabIndex}   
                                                        menu={{  secondary: true, pointing: true ,className:'startPageTabRow' }} 
                                                        onTabChange={(e, data) => setActiveTabIndex(data,undefined)}
                                                        panes={panes} />
                                          } 
                                      </GridColumn>
                                </Grid.Row>
                                <Grid.Row  className='loginBoxRow'  >
                                      <GridColumn className='loginBoxBottom' textAlign='center' >
                                      <span className='loginRemark'>Don’t have an account yet?
                                      <a onClick={() => setShowRegCompany(true)} className='loginRemarkBold'>  Register </a> </span>
                                    </GridColumn> 
                                </Grid.Row>
                          </Grid>
                          ) : (
                            <TabRegisterCompany showRegCompany={showRegCompany} setShowRegCompany={setShowRegCompany}/>
                          )}
                </GridColumn> 

                <GridColumn  width="3"  className='groupSmalImg'>
                      <Accordion defaultActiveIndex={-1}  panels={rootPanels} fluid />
                </GridColumn>
             
          </Grid.Row>
          </Grid>
            
        </Grid.Row>

     </Grid>
  );});


