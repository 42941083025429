import { createContext, useContext } from "react";
import AccountStore from "./accountStore";
import DeviceStore from "./deviceStore";
import GlobalStore from "./globalStore";
import BackupStore from "./backupsStore";
import UserProfileStore from "./userProfileStore";
import companyStore from "./companyStore";
import modalStore from "./modalStore";
import resourcesStore from "./resourcesStore";
import SMTPStore from "./smtpStore";
import FilterStore from "./filterStore";


interface Store{
    userProfileStore :UserProfileStore;
    deviceStore : DeviceStore;
    globalStore: GlobalStore;
    accountStore: AccountStore;
    backupsStore: BackupStore;
    companyStore :  companyStore;
    modalStore :  modalStore;
    resourcesStore:resourcesStore;
    smtpStore:SMTPStore;
    filterStore : FilterStore;
}

export const store:Store={
    userProfileStore : new UserProfileStore()
    ,deviceStore : new DeviceStore()
    ,globalStore : new GlobalStore()
    ,accountStore: new AccountStore()
    ,backupsStore : new BackupStore()
    ,companyStore : new companyStore()
    ,modalStore : new modalStore()
    ,resourcesStore :new resourcesStore()
    ,smtpStore :new SMTPStore()
    ,filterStore :new FilterStore()
}

export const StoreContext=createContext(store);

export function useStore()
{
    return useContext(StoreContext)
}

