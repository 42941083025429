import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { ErrorMessage, Formik } from 'formik';
import { Button, Form, Grid, GridRow, Label } from 'semantic-ui-react';
import { useStore } from '../../../api/stores/store';
import LoginTextInput from '../../UIItems/LoginTextInput';
import { useNavigate } from 'react-router-dom';
import { ServerResult } from '../../../api/models/common';
import ServerMsgBar from '../../UIItems/ServerMsgBar';

export default observer(function LoginPart() {
  const { accountStore } = useStore();
  const { setResetPassword } = accountStore;
  const navigate = useNavigate();
  const [formResponseState, setFormResponseState] = useState<
    ServerResult | undefined
  >(undefined);
  return (
    <Formik
      initialValues={{ email: '', password: '', username: '', error: null }}
      onSubmit={(values, {}) => {
        accountStore
          .login(values)
          .then(() => navigate('/devicesDashBoard'))
          .catch((error) => {
            var successResult = {
              isSuccess: false,
              msg: 'Invalid Email or Password',
            };
            setFormResponseState(successResult);
          });

      }}
    >
      {({ handleSubmit, errors, status }) => (
        <Form
          onSubmit={handleSubmit}
          autoComplete="off"
          className="vertical-form"
        >
          <Grid columns={1} className="loginGrid justifyCenter">
            <GridRow className="loginRow">
              <Grid.Column style={{ height: '100%' }}>
                <LoginTextInput
                  name="email"
                  placeholder="Email"
                  title="Email"
                />
              </Grid.Column>
            </GridRow>
            <GridRow GridRow className="loginRow">
              <Grid.Column style={{ height: '100%' }}>
                <LoginTextInput
                  name="password"
                  placeholder=""
                  type="password"
                  title="Password"
                />
              </Grid.Column>
            </GridRow>
            <GridRow>
              {formResponseState && (
                <ServerMsgBar runActionResult={formResponseState} loginForm />
              )}
             
              <Grid.Column style={{ width: 'auto' }}>
                <Label
                  as="a"
                  size="large"
                  content="Forgot Password?"
                  className="blueRemark"
                  onClick={() => setResetPassword(true)}
                ></Label>
              </Grid.Column>
            </GridRow>
            <GridRow>
              <Grid.Column style={{ width: 'auto' }}>
                <Button
                  content="   Login   "
                  type="submit"
                  fluid
                  className="loginBtn"
                />
              </Grid.Column>{' '}
            </GridRow>
          </Grid>
        </Form>
      )}
    </Formik>
  );
});
