import { makeAutoObservable } from 'mobx';
import agent from '../agent';
import { uploadResDTO } from '../models/pagination';
export default class  resourcesStore{
    loadinInitial=false;
    selectedResource:uploadResDTO|undefined;
    activeResources:uploadResDTO[]=[]
    softResources:uploadResDTO[]=[]
    docsResources:uploadResDTO[]=[]
    constructor() {
        makeAutoObservable(this)
    }

    uploadRes = async (fileResourse: FormData) =>
    {    
          await agent.Resources.upload(fileResourse);
     };

     uploadSysUpdate = async (fileResourse: FormData) =>
     {    
          await agent.Resources.uploadPack(fileResourse);
      };
      uploadDBImport = async (fileResourse: FormData) =>
     {    
           await agent.Resources.uploadDBImport(fileResourse);
     };
    runSysUpdate = async () =>
     {    
            await agent.Resources.runSysUpdate();
     };
     runDBExport = async () =>
     {    
            await agent.Resources.runDBExport();
     };
     runDBImport = async () =>
     {    
            await agent.Resources.runDBImport();
     };
     setLoadinInitial =(state:boolean) =>{
        this.loadinInitial = state;
    }

    setSelectedResource =(res :uploadResDTO) =>{
       this.selectedResource = res;
    }
     loadActiveResources = async ()=>
    {
        this.loadinInitial = true;
        try{
            this.activeResources = await agent.Resources.list(); 
            this.selectedResource = this.activeResources.find(i => i.fileType === 1);
            this.softResources= this.activeResources.filter(i => i.fileType === 3);
            this.docsResources = this.activeResources.filter(i => i.fileType === 4);
            this.setLoadinInitial(false);
        }
        catch(error)
        {
            this.setLoadinInitial(false);
        }
    }
    }
  
  
  
  