import React from 'react';
import { observer } from 'mobx-react-lite'
import {  Header,  Label, Segment, Table  } from 'semantic-ui-react';
import { isBrowser,isMobile } from 'react-device-detect';
import { useStore } from '../../api/stores/store';
export default observer (function MDBTab3()
{
   const { deviceStore} = useStore();
   const {dbBackupSaccessAsset} = deviceStore;

   return(
    <Segment >
             <Header sub className='titleSet1' content= 'Backup Successful '/>
               <Table   compact  color='blue' >
               {isBrowser  && (
               <Table.Header>
                  <Table.Row>
                  <Table.HeaderCell>Device SN</Table.HeaderCell>
                    <Table.HeaderCell>Computer</Table.HeaderCell>

                  </Table.Row>
                </Table.Header>)}
              
                 <Table.Body>
                   {dbBackupSaccessAsset.map((device:any) => (
                         <Table.Row key={device.deviceSN}   >
                             {isMobile  && (
                             <Table.Cell className='compDesc'>{device.deviceSN} <Label size='mini'>{device.computerName}</Label></Table.Cell>
                           )}
                              {isBrowser  && ( <>
                           <Table.Cell className='compDesc'>{device.deviceSN}</Table.Cell>
                           <Table.Cell ><div className='compDesc'>{'   Computer : '+ device.computerName}</div></Table.Cell>
                           </>)}
                         </Table.Row>
                     ))}
                 </Table.Body>
               </Table>
            </Segment>
   )
})