import React, { useState } from 'react';
import {  Grid,GridRow, Tab,TabProps } from 'semantic-ui-react';
import NewSNDashBoard from './NewSNDashBoard';
import UploadRes from './UploadRes';

interface Props {
    type:string ;
  }
export function AdminTab (props: Props) {

    const [activeTabIndex,setActiveTabIndex] = useState('0');

    const panes = [
        { menuItem: { key: 'assets', value:'0', content: 'Manage Assets'},
         render: () => ( <Tab.Pane> <NewSNDashBoard/></Tab.Pane>), },
        { menuItem: { key: 'resources' , value:'1',content: 'Upload Resources' },
         render: () => (  <Tab.Pane><UploadRes/></Tab.Pane>  ),  }
        ];
    // useEffect(() => {
    //     let selectedTab =  panes.find(pane => pane.menuItem.key === props.type);
    //     if (selectedTab)     
    //         setActiveTabIndex(selectedTab.menuItem.value);
    // }, [props.type]);

    const setActiveTab = (tabProps: TabProps|undefined, index:number|undefined) => {
        if (tabProps && tabProps.activeIndex)
            setActiveTabIndex(tabProps.activeIndex.toString());
        else
            setActiveTabIndex('0');
    }

    return (
        <Grid  className='mainPageGrid' >
            <GridRow   >
            <span className='pageTitle'>Admin Console</span> 
            </GridRow>
            <GridRow   >
                     <Tab    activeIndex={activeTabIndex} 
                        onTabChange={(e, data) => {setActiveTab(data,undefined); }} 
                           menu={{  secondary: true, pointing: true ,className:'tabContainer' }}  panes={panes} />
            </GridRow>
     </Grid>

    )
}
