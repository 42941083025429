import {  useField } from 'formik';
import React from 'react';
import { Checkbox, Form } from 'semantic-ui-react';

interface FormCheckBoxProperties {
    toggle?:boolean;
    name: string;
    label?: string;
    className?:string;
}

export default function FormCheckBox(props: FormCheckBoxProperties) {
    const [field, meta, helpers] = useField(props.name); 
    return (
        <Form.Field error={meta.touched && !!meta.error}>
            <Checkbox   {...field} {...props}  value={field.value  }   className='formCheckBox' 
            onChange={(e, d) => helpers.setValue(d.checked)} checked = {field.value }
                            />
        </Form.Field>
    )
} 

