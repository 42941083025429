import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';

import { Header, Label } from 'semantic-ui-react';
import { isMobile } from 'react-device-detect';
import LoadingComponent from '../../UICommon/LoadingComponent';
import { useStore } from '../../../api/stores/store';
import { useNavigate } from 'react-router-dom';



export default observer(function LogsStatisticChart() {
  const {backupsStore,deviceStore} = useStore();
  const {backupsStatistic,getBackupsStatistic,setFilterItem,loadBackups} = backupsStore;
  const {unselectDevice} = deviceStore;

  const navigate = useNavigate();

  
    useEffect(()=>{ 
      if(backupsStatistic.length === 0 ) getBackupsStatistic();
    },[backupsStatistic.length])


    if(backupsStore.loadinInitialBackup) return <LoadingComponent content='Loading App Statistic..'/>
    
     function drillDownFilteredLogs (status :number)
      {
           setFilterItem('status', status);
           loadBackups();
           navigate('/reports');
      }

  return (
        <>
           {backupsStatistic.length > 0 && (
            <>
              <Header>Backups Statistics</Header>
              <BarChart width={isMobile?250:400} 
                    height={isMobile?350:400}   
                    data={backupsStatistic}
                    barCategoryGap ='10%'>
            <Bar dataKey="count"   label={{ position:'insideRight' }}>
                    {backupsStatistic.map((entry, index) => (
                      <Cell key={`cell-${index}`} 
                      fill={entry.status == 1? '#96DAEA':
                              entry.status == 2? '#ffb703':
                              entry.status == 5? '#0082CA':'#b83700'} 
                            onClick={() => drillDownFilteredLogs( entry.status)} />
                    ))}
              </Bar>

              <XAxis dataKey="statusName"  interval={0}
                      angle={-30}
                      textAnchor="end" height={70} />
            <YAxis />
            <Tooltip />
          </BarChart></>
     )}

  

  </>
  );
});
