import React from 'react';
import { Outlet } from "react-router-dom"
import {  Grid } from 'semantic-ui-react';
import SideBar from './SideBar';

export function Layout () {
    return (

        <Grid columns={2}>
        <Grid.Column   width='2'  >
                 <SideBar />
         </Grid.Column >
         <Grid.Column  width='14'>
                <Outlet />
         </Grid.Column>
         </Grid>


    )
}


