import { useField } from 'formik';
import React from 'react';
import { Form, Input, Label } from 'semantic-ui-react';

interface FormSelectInputProperties {
     placeholder: string;
     name: string;
     required?:boolean;
     title?: string;
     label?: string;
     readonly?:boolean;
     setShowSelectBox: () => void;
}

export default function FormSelectInput(props: FormSelectInputProperties) {
    const [field, meta] = useField(props.name); 
    const cssClass ='formSelectInput '+ (meta.error ? ' error ':'')  ;
    return (
        <Form.Field error={meta.touched && !!meta.error} onClick={()=> props.setShowSelectBox()}  className='formSelectInput'>
            <label className={props.required ? 'required' : ''} >{props.title}</label>
            <Input    {...field} {...props}  className={cssClass} icon={'chevron down'} onClick={()=> props.setShowSelectBox()}/>
            {meta.touched && meta.error ? (
                <Label basic className='errorLbl'>{meta.error}</Label>
            ) : null}
            
        </Form.Field>
    )
} 