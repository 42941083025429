import React from 'react';
import { observer } from 'mobx-react-lite'
import {  Header, Label, Segment, Table  } from 'semantic-ui-react';
import { isBrowser,isMobile } from 'react-device-detect';
import { useStore } from '../../api/stores/store';

export default observer (function MDBTab1()
{
   const { deviceStore} = useStore();
   const {dbAttackPossible} = deviceStore;

   return(
    <Segment >
             <Header sub className='titleSet1' content= ' Attack Alert '/>
               <Table   compact  color='red' >
               {isBrowser  && (<Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Computer</Table.HeaderCell>
                    <Table.HeaderCell>Device SN</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>)}
                 <Table.Body>
                   {dbAttackPossible.map((device:any) => (
                         <Table.Row key={device.deviceSN}   >
                           {isMobile  && (
                             <Table.Cell className='compDesc' >{device.computerName} <Label size='mini'>{device.deviceSN}</Label></Table.Cell>
                           )}
                              {isBrowser  && ( <>
                               <Table.Cell className='compDesc' >Computer Name: {device.computerName}<div className='compDesc'>{device.computerDescript}</div></Table.Cell>
                               <Table.Cell  className='compDesc'>{device.deviceSN}</Table.Cell></>
                            )}

                         </Table.Row>
                     ))}
                 </Table.Body>
               </Table>
            </Segment>
   )
})