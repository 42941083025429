import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Grid, GridColumn, GridRow, Image } from 'semantic-ui-react';
import { useStore } from '../../../api/stores/store';
import StatisticChart from './StatisticChart';
import LoadingComponent from '../../UICommon/LoadingComponent';
import { Link } from 'react-router-dom';
export default observer(function DeviceDashBoard() {
  const { deviceStore, backupsStore,accountStore } = useStore();
  const {
    dashboardIndicators,
    getDashboardIndicators,
    setLoadinInitialDevice,
    loadinInitialDevice,
  } = deviceStore;
  const { setMenuActiveItem  } = accountStore;
  const { lastBackup, getLastBackupRow } = backupsStore;
  const listStatusFailed = [6,7,13,17,19];
  const lastBackupStatusFailed :boolean= lastBackup ?listStatusFailed.includes(lastBackup.status):false; 
  useEffect(() => {
    setMenuActiveItem('Dashboard');
    setLoadinInitialDevice(true);
    getDashboardIndicators();
    getLastBackupRow();
    setLoadinInitialDevice(false);
  }, []);

  if (loadinInitialDevice)
    return <LoadingComponent content="Loading App Statistic.." />;
  return (
    <Grid className="mainPageGrid dashboardPage"  >
      <GridRow>
        <GridColumn width="11" className="pageTitleColumn">
          <span className="pageTitle">Dashboard</span>
        </GridColumn>
      </GridRow>
      {lastBackup && (
        <Grid.Row
          className={`rowTop ${lastBackupStatusFailed ? 'rowNegative' : ''}`}
        >
          <Grid.Column
            width="11"
            as={Link}
            to={`/SNReports/${lastBackup!.deviceIDAuto}`}
          >
            <Image
              src={
                lastBackupStatusFailed
                  ? '/img/err_small.svg'
                  : '/img/Success.svg'
              }
              className="rowTitleImg"
            />
            <span>{lastBackup!.statusName}
              {lastBackup!.computerName
                ? '  Computer:  ' + lastBackup!.computerName
                : '  CRU:' + lastBackup!.deviceSN}
              <span className="dateSpan">{' ' + lastBackup!.insertDate}</span>
            </span>
          </Grid.Column>
        </Grid.Row>
      )}
      <GridRow>
        <GridColumn width={9}>
          <Grid className="dashBoardSegment R1C1" padded>
            <GridRow className="dashBoardSegmentTitleFail">Failed Jobs</GridRow>
            <GridRow>
              <GridColumn className="dashBoardSegmentItem" floated="left">
                <Image src="/img/avg_pace.svg" className="dashBoardItemImg" />
                <span className="dashBoardSegmentLabel">Last 24 Hours</span>

                <span className="dashBoardSegmentContent">
                  {dashboardIndicators
                    ? dashboardIndicators.failedBackupsLast24Hours
                    : 0}{' '}
                </span>
              </GridColumn>
              <GridColumn className="dashBoardSegmentItem">
                <Image
                  src="/img/calendar_today.svg"
                  className="dashBoardItemImg"
                />
                <span className="dashBoardSegmentLabel">Last 30 Days</span>

                <span className="dashBoardSegmentContent">
                  {dashboardIndicators
                    ? dashboardIndicators.failedBackupsLast30Days
                    : 0}
                </span>
              </GridColumn>
              <GridColumn
                className="dashBoardSegmentItem current"
                floated="right"
              >
                <span className="dashBoardSegmentLabel">Current</span>

                <span className="dashBoardSegmentContent">
                  {dashboardIndicators
                    ? dashboardIndicators.failedBackupsCurrent
                    : 0}
                </span>
              </GridColumn>
            </GridRow>
          </Grid>
        </GridColumn>
        <GridColumn width={7} floated='right'>
          <Grid className="dashBoardSegment R1C2" padded>
            <GridRow className="dashBoardSegmentTitleSuccess">
              Successful Jobs
            </GridRow>
            <GridRow>
              <GridColumn className="dashBoardSegmentItem" floated="left">
                <Image src="/img/avg_pace.svg" className="dashBoardItemImg" />
                <span className="dashBoardSegmentLabel">Last 24 Hours</span>

                <span className="dashBoardSegmentContent">
                  {`${
                    dashboardIndicators
                      ? Math.round(
                          dashboardIndicators.successfulBackupsLast24Hours * 10
                        ) / 10
                      : 0
                  }%`}
                </span>
              </GridColumn>
              <GridColumn className="dashBoardSegmentItem" floated="right">
                <Image
                  src="/img/calendar_today.svg"
                  className="dashBoardItemImg"
                />
                <span className="dashBoardSegmentLabel">Last 30 Days</span>

                <span className="dashBoardSegmentContent">
                  {' '}
                  {`${
                    dashboardIndicators
                      ? Math.round(
                          dashboardIndicators.successfulBackupsLast30Days * 10
                        ) / 10
                      : 0
                  }%`}
                </span>
              </GridColumn>
            </GridRow>
          </Grid>
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn width={4}>
          <Grid className="dashBoardSegment R2C1-2 " padded>
            <span className="legend">Attacks detected in the last 30 days</span>
   <br/>
              <div className="statisticsCircle">
                 {dashboardIndicators? dashboardIndicators.attacksDetectedAssets:0}
              </div>

          </Grid>
        </GridColumn>
        <GridColumn width={4}>
          <Grid className="dashBoardSegment R2C1-2 " padded>
            <span className="legend">
              Incomplete backups detected in the last 30 days
            </span>
           
              <div className="statisticsCircle blue">
                {dashboardIndicators
                  ? dashboardIndicators.incompleteBackupsAssets
                  : 0}
              </div>
            
          </Grid>
        </GridColumn>
        <GridColumn width={8} floated='right'>
          <Grid className="dashBoardSegment R2C3" >
            <StatisticChart />
          </Grid>
        </GridColumn>
      </GridRow>
    </Grid>
  );
});
