import { observer } from 'mobx-react-lite';
import React, { useEffect }  from 'react';
import { Grid, GridColumn, GridRow  } from 'semantic-ui-react';
import { useStore } from '../../../api/stores/store';

export default observer(function DownSoft() {
    const { resourcesStore,} = useStore();
    const { softResources ,loadActiveResources } = resourcesStore;
    useEffect(() => {
           if (softResources === undefined) loadActiveResources();
      }, []);
    
    return (
            
<Grid  divided='vertically' >
            {softResources.map((resouce:any) => (
                <GridRow  >
                    <GridColumn  >
                        <a href={resouce.fileUrl}  download rel="noopener noreferrer" >
                            <span className='resourceLabel'>{resouce.fileName}</span><br></br>
                            <span className='resourceSmallLabel'> {resouce.insertDate}</span>
                        </a>
                    </GridColumn>
                    </GridRow>
            ))}
</Grid>
 )});
   

