import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  GridColumn,
  GridRow,
  Icon,
  Image,
} from 'semantic-ui-react';
import { useStore } from '../../../api/stores/store';
import * as Yup from 'yup';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { PermissionLevel } from '../../../api/models/enums';
import FormCheckBox from '../../UIItems/FormCheckBox';
import FormTextInput from '../../UIItems/FormTextInput';
import LoadingComponent from '../../UICommon/LoadingComponent';
import ServerMsgBar from '../../UIItems/ServerMsgBar';
import { ServerResult  } from '../../../api/models/common';

type Props = {
  hideBox: () => void;
};
export default observer(function ProfileForm(props: Props) {
  const { userProfileStore, globalStore } = useStore();
  const {
    companyList,
    activeUser,
    loadLoginedUserProfile,
    activeUserProfile,
    loadinInitial,
  } = globalStore;
  const isValidPhoneNumber = (value: any) => {
    if (!value) {
      return true;
    }
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
      const parsedNumber = phoneUtil.parse(value, undefined);
      return phoneUtil.isValidNumber(parsedNumber);
    } catch (e) {
      return false;
    }
  };
  const phoneValidation = Yup.string().test(
    'is-valid-phone',
    'International phone number is not valid',
    isValidPhoneNumber
  );
  const [responseState, setResponseState] = useState<ServerResult  | undefined>(
    undefined
  );

  function hideResponseState() {
    if (responseState) setResponseState(undefined);
  }

  useEffect(() => {
    loadLoginedUserProfile();
  }, [loadLoginedUserProfile]);

  function getCompanyNamesForIds(idList: number[]): string {
    const filteredCompanies = companyList.filter((company) =>
      idList.includes(company.companyId)
    );
    const companyNames = filteredCompanies.map(
      (company) => company.companyName
    );
    return companyNames.join(', ');
  }
  if (loadinInitial)
    return <LoadingComponent content="Loading User Profile.." />;
  const handleKeyDown = (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    };
  return (
    <Formik
      initialValues={{
        id: activeUserProfile ? activeUserProfile.id : '',
        displayName: activeUserProfile ? activeUserProfile.displayName : '',
        companyId: activeUserProfile
          ? activeUserProfile.companyId
          : activeUser
          ? activeUser.level === PermissionLevel.SiteAdministrator
            ? activeUser.companyId
            : 0
          : 0,
        companyName: activeUserProfile ? activeUserProfile.companyName : '',
        level: activeUserProfile
          ? activeUserProfile.level
          : PermissionLevel.User,
        levelName: activeUserProfile ? activeUserProfile.levelName : '',
        status: activeUserProfile ? activeUserProfile.status : 1,
        statusName: activeUserProfile ? activeUserProfile.statusName : '',
        email: activeUserProfile ? activeUserProfile.email : '',
        phoneNumber: activeUserProfile ? activeUserProfile.phoneNumber : '',
        password: '',
        confirmNewPassword: '',
        lastEnter: activeUserProfile ? activeUserProfile.lastEnter : '',
        passwordLastChange: activeUserProfile
          ? activeUserProfile.passwordLastChange
          : '',
        backupSuccessSendMail: activeUserProfile
          ? activeUserProfile.backupSuccessSendMail
          : true,
        backupSuccessSendSMS: activeUserProfile
          ? activeUserProfile.backupSuccessSendSMS
          : false,
        backupFailureSendMail: activeUserProfile
          ? activeUserProfile.backupFailureSendMail
          : true,
        backupFailureSendSMS: activeUserProfile
          ? activeUserProfile.backupFailureSendSMS
          : false,
        attacDetectedSendMail: activeUserProfile
          ? activeUserProfile.attacDetectedSendMail
          : true,
        attacDetectedSendSMS: activeUserProfile
          ? activeUserProfile.attacDetectedSendSMS
          : false,
        partnerCompanies: activeUserProfile
          ? activeUserProfile.partnerCompanies
          : [],
        partnerCompaniesList:
          activeUserProfile && activeUserProfile.partnerCompanies
            ? getCompanyNamesForIds(activeUserProfile.partnerCompanies)
            : [],
        partnerSiteNumber: activeUserProfile
          ? activeUserProfile.partnerSiteNumber
          : '',
        error: null,
      }}
      validateOnMount={false}
      onSubmit={(values, { setStatus }) => {
        var msgSuccesUpdate = 'The User Profile is Successfully Updated ';
        var msgErrorUpdate = 'Error on Update The User Profile : ';

        userProfileStore
          .saveUserProfile(values)
          .then((res) => {
            var successResult = {
              isSuccess: true,
              msg: msgSuccesUpdate,
            };
            setResponseState(successResult);
          })
          .catch((error) => {
            var successResult = {
              isSuccess: false,
              msg: msgErrorUpdate + error,
            };
            setResponseState(successResult);
          });
      }}
      validationSchema={Yup.object({
        backupSuccessSendSMS: Yup.boolean(),
        backupFailureSendSMS: Yup.boolean(),
        attacDetectedSendSMS: Yup.boolean(),
        phoneNumber: phoneValidation.when(
          [
            'backupSuccessSendSMS',
            'backupFailureSendSMS',
            'attacDetectedSendSMS',
          ],
          {
            is: (
              backupSuccessSendSMS: boolean,
              backupFailureSendSMS: boolean,
              attacDetectedSendSMS: boolean
            ) =>
              backupSuccessSendSMS === true ||
              backupFailureSendSMS === true ||
              attacDetectedSendSMS === true,
            then: Yup.string().required(
              'Phone Number required when  one of Send SMS checkboxes are checked'
            ),
          }
        ),

        id: Yup.string(),
        displayName: Yup.string().required('Display Name is a required field'),
        email: Yup.string()
          .required('Email is a required field')
          .email('Email is a Invalid '),
        password: Yup.string()
          .min(6, 'Password should be longer than 5 symbols')
          .matches(/(?=.*[A-Z])/, " Must have at least one uppercase ('A'-'Z')")
          .oneOf([Yup.ref('confirmNewPassword')], 'Passwords must match')
          .nullable(true)
          .transform((_, value: string) => {
            return value === '' ? null : value;
          }),
        confirmNewPassword: Yup.string()
          .oneOf([Yup.ref('password')], 'Passwords must match')
          .nullable(true)
          .transform((_, value: string) => {
            return value === '' ? null : value;
          }),
      })}
    >
      {({ errors, handleSubmit, setFieldValue, isValid, status, values }) => {

        return (
          <Form 
            onKeyDown={handleKeyDown}
            className="ui form error internalForm"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Grid onClick={hideResponseState}  columns={2}>
              <GridRow className="dataHeader">
                <GridColumn>
                  <span className="formSubTitle">
                    <strong>{values.displayName} </strong>
                  </span>
                  <br />
                  <span className="dataSubTitle">
                    <strong>{values.levelName}</strong>
                  </span>
                </GridColumn>
              </GridRow>
              <br></br>
              <GridRow className="formFieldRow">
                <GridColumn width={7}>
                  <Image
                    src="/img/account_circle.svg"
                    className="formSubTitleImg"
                  />
                  <span className="formSubTitle">General info</span>
                </GridColumn>
                <GridColumn width={8}>
                  <Icon
                    name="address book outline"
                    size="large"
                    color="grey"
                  ></Icon>
                  <span className="formSubTitle">System permissions</span>
                  <br />
                  <span className="dataSubTitle">{values.levelName}</span>
                  <br />
                  {values!.level === PermissionLevel.Partner && (
                    <span className="formSelectInput">
                      {values.partnerCompaniesList}
                    </span>
                  )}
                </GridColumn>
              </GridRow>
              <GridRow >
                      <GridColumn width={7}>
                      <Grid>
                            <GridRow className="formFieldRow">
                                  <GridColumn >
                                        <FormTextInput  name="displayName" placeholder="Name" title="Name" />
                                  </GridColumn>
                            </GridRow>
                            <GridRow className="formFieldRow">
                                  <GridColumn >
                                        <FormTextInput name="email" placeholder="Email" title="Email" />                                    
                                  </GridColumn>
                            </GridRow>
                            <GridRow className="formFieldRow">
                                  <GridColumn >
                                        <FormTextInput name="phoneNumber" placeholder="Phone"
                                                                  title="Phone"  autoComplete="new-password"/>
                                  </GridColumn>
                            </GridRow>
                            <GridRow className="formFieldRow">                                  
                                  <GridColumn >
                                        <FormTextInput  name="password"  placeholder="Password"
                                                                    icon="lock"  type="password"  title="Create Password "
                                                                    iconPosition="left" autoComplete="new-password" />
                                  </GridColumn>
                            </GridRow>
                            <GridRow className="formFieldRow">                                   
                                  <GridColumn >
                                        <FormTextInput name="confirmNewPassword" placeholder="Confirm password"
                                                                  title="Retype password "  type="password"  icon="lock"
                                                                   iconPosition="left"  autoComplete="new-password" />
                                  </GridColumn>
                            </GridRow>
                                                   
                      </Grid>      
                      </GridColumn>
                      <GridColumn width={8}>
                      <Grid columns={3}>
                          <GridRow className="formFieldRow">
                                  <GridColumn width={16} >
                                        <Image
                                          src="/img/notifications.svg"
                                          className="formSubTitleImg"
                                        />
                                        <span className="formSubTitle">Manage notifications</span>
                                        <span className="subLabel">
                                          Please select the notifications you wish to receive
                                        </span>
                                </GridColumn>
                          </GridRow>
                          <GridRow className='notificationRow'>
                                  <GridColumn  width={10}></GridColumn>
                                  <GridColumn width={3}>Email</GridColumn>
                                  <GridColumn width={3}>SMS</GridColumn>
                          </GridRow>
                         <GridRow className='notificationRow'>
                                  <GridColumn className="formCheckBoxLabel" width={10}>
                                          On every successful backup
                                  </GridColumn>
                                  <GridColumn width={3}>
                                          <FormCheckBox  name="backupSuccessSendMail" className="notificationCheck"></FormCheckBox>
                                  </GridColumn>
                                  <GridColumn width={3}>
                                          <FormCheckBox  name="backupSuccessSendSMS" className="notificationCheck"></FormCheckBox>
                                  </GridColumn>
                          </GridRow>
                          <GridRow className='notificationRow'>
                                  <GridColumn className="formCheckBoxLabel" width={10}>
                                        Backup not received on time
                                  </GridColumn>
                                  <GridColumn width={3}>
                                          <FormCheckBox  name="backupFailureSendMail" className="notificationCheck"></FormCheckBox>
                                  </GridColumn>
                                  <GridColumn width={3}>
                                        <FormCheckBox  name="backupFailureSendSMS" className="notificationCheck"></FormCheckBox>
                                  </GridColumn>
                          </GridRow>
                          <GridRow>
                                  <GridColumn className="formCheckBoxLabel" width={10}>
                                    Attack detection during backup
                                  </GridColumn>
                                  <GridColumn width={3}>
                                             <FormCheckBox  name="attacDetectedSendMail" className="notificationCheck"></FormCheckBox>
                                  </GridColumn>
                                  <GridColumn width={3}>
                                          <FormCheckBox  name="attacDetectedSendSMS" className="notificationCheck"></FormCheckBox>
                                  </GridColumn>
                          </GridRow>  
                          <GridRow> 
                            <GridColumn  width={16}>
                               {responseState && (
                                    <ServerMsgBar runActionResult={responseState} />
                              )}
                            </GridColumn>
                          </GridRow>
                      </Grid>
                      </GridColumn>
              </GridRow>
             </Grid>
             <Button 
                    content="   Update   "
                    type="submit"
                    className="applyBtn tabFormBtn"
                  />
          </Form>
        );
      }}
    </Formik>
  );
});
