import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardGroup,
  Grid,
  GridRow,
  Header,
  Icon,
  Image,
   Label,
  Segment,
  Table,
} from 'semantic-ui-react';


import { isBrowser } from 'react-device-detect';
import { useStore } from '../../api/stores/store';
import LoadingComponent from '../UICommon/LoadingComponent';
export default observer(function MDownloads() {
  const { companyStore, resourcesStore,globalStore } = useStore();
  const { loadUserCompany, loadInitial, userCompany } = companyStore;
  const { activeUser } = globalStore;
  const {
    selectedResource,
    softResources,
    docsResources,
    loadActiveResources
  } = resourcesStore;

  useEffect(() => {
    if (userCompany === undefined) loadUserCompany();
    if (selectedResource === undefined) loadActiveResources();
  }, [userCompany, loadUserCompany, selectedResource, loadActiveResources]);

  if (loadInitial )
    return <LoadingComponent content="Loading Resources.." />;

  return (
    <Grid className ={isBrowser? 'mainPageGrid':'mainMobilePageCard'} >
      <GridRow>
      <Card.Group >
        <Card>
          <Image src="/img/V1.55/RecoverySoftBox.png" wrapped ui={false} />
          <Card.Content>
            <Card.Header> Backup and Recovery Software </Card.Header>
            <Card.Meta>
              {selectedResource && (
                <>
                  <Label as="a" className='collorSet3' ribbon size="big">
                    <b>Version {selectedResource.fileVersion}</b>
                  </Label>
                </>
              )}{' '}
            </Card.Meta>
            <Card.Description>
              {selectedResource && (
                <> Last Update {selectedResource.insertDate} </>
              )}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <a
              href="/Resources/SalvadorBackupRecoveryLastVersion.zip"
              download
              rel="noopener noreferrer"
            >
              <Image
                src={`/img/V1.55/SoftDownload.png`}
                s
                ize="mini"
                spaced="right"
              />{' '}
            </a>
          </Card.Content>
        </Card>
        <Card>
          <Image src="/img/V1.55/SalvadorLogo2.jpg" wrapped ui={false} />
          <Card.Content>
            <Card.Header textAlign="center">User Manual </Card.Header>
            <Card.Description textAlign="center">
              The New Standard in <br></br>Operational Continuity and<br></br>{' '}
              Cyber-Attack Recovery{' '}
            </Card.Description>
            <Card.Content className="titleSet1">
              {' '}
              {activeUser && 
              <b >
                Your customer code is: <strong> {activeUser.companyId}</strong>{' '}
              </b>}
            </Card.Content>
            <Card.Meta>Use this customer code when installing new asset </Card.Meta>
          </Card.Content>
          <Card.Content extra>
            <a
              href="/Resources/CRU_User_ManualLastVersion.pdf"
              download
              rel="noopener noreferrer"
            >
              <Image src={`/img/PDFDownload.png`} s ize="mini" spaced="right" />{' '}
            </a>
          </Card.Content>
        </Card>
      </Card.Group>
    </GridRow>
    <GridRow>
      <CardGroup>
        <Card>
      <Segment >
             <Header sub className='titleSet1'textAlign="center"  content= ' Other Software  '/>
               <Table   compact  color='blue' >
              
                 <Table.Body>
                   {softResources.map((resouce:any) => (
                         <Table.Row   >
                             <Table.Cell className='compDesc' >
                              <a href={resouce.fileUrl}  download rel="noopener noreferrer" >
                              <Icon name="cog" /> {resouce.fileName}</a><br></br>
                              <Label size='mini'>{resouce.insertDate}</Label>
                            </Table.Cell>
                         
                           </Table.Row>
                     ))}
                 </Table.Body>
               </Table>
            </Segment>
            </Card>
            <Card>
      <Segment >
             <Header sub className='titleSet1' textAlign="center" content= ' Documents Library  '/>
               <Table   compact  color='blue' >
              
                 <Table.Body>
                   {docsResources.map((resouce:any) => (
                         <Table.Row   >
                             <Table.Cell className='compDesc' >
                              <a href={resouce.fileUrl}  download rel="noopener noreferrer" >
                              <Icon name="cog" /> {resouce.fileName}</a>
                              <br></br><Label size='mini'>{resouce.insertDate}</Label>
                            </Table.Cell>
                         
                           </Table.Row>
                     ))}
                 </Table.Body>
               </Table>
            </Segment>
            </Card>

       
      </CardGroup>
      </GridRow>
    </Grid>
  );
});
