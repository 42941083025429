import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import {  Form, Formik } from 'formik';
import MyTextInput from './MUIItems/MyTextInput';
import { Button, Divider, Header, Icon, Item, Rail, Segment } from 'semantic-ui-react';
import * as Yup from 'yup';
import _ from 'lodash';
import { useStore } from '../../api/stores/store';

export default observer(function MTabRegisterNS() {
  const { deviceStore, globalStore} = useStore();
  const {companyList,activeUser} = globalStore;
  const [showSNHelp, setShowSNHelp] = useState(false);
  let companyId = activeUser === null? "":companyList[0].companyId.toString();

  return (
    <Formik
      initialValues={{ deviceSN: "", 
                              companyCode: activeUser?activeUser.companyId.toString():"",  
                              siteLocation:"",
                              fromVM :false, 
                              error: null }}
      onSubmit={(values, { setStatus}) =>  {
            deviceStore.regestryDevice(values) 
                    .then(res => {setStatus({ sent: true, msg: "The Device is Successfully Registered"})})
                    .catch(error => {setStatus({ sent: false, msg: error })})
      } }

      
      validationSchema={Yup.object({
        companyCode: Yup.string().required("Site Number is a required field"),
        deviceSN: Yup.string().required("Device SN is a required field"),
      })}
    >
         {({handleSubmit,  errors,status}) => (
         
         <Form className={companyId === "" ?'ui form error':'ui form error  internalForm'} onSubmit={handleSubmit} autoComplete='off'>
         <MyTextInput name="companyCode" placeholder="Site Number "  icon='world'  iconPosition='left'/>
         <Divider horizontal>
            <Header as="h4">
              <Icon name="hdd" />
              CRU Device
            </Header>
          </Divider>
          <MyTextInput name='deviceSN' placeholder='Enter SN- On the back of the CRU device' icon='hdd'  iconPosition='left' />
                   
          <Rail internal position='left' hidden={!showSNHelp} onClick={() => setShowSNHelp(false)}>
                    <Segment.Group compact>
                        <Segment>
                            <Icon name='close' floated='right' className='closeImage' />
                             <Item.Image src='/img/SN_Help.jpg' /></Segment>
                        </Segment.Group>
                    </Rail>
                    <a className='activeLink'  onClick={() => setShowSNHelp(!showSNHelp)}>  <Icon name='hdd' /> What is an SN number?</a>
                    
                    {status && status.msg && (
                              <p className={`alert ${ status.sent ? 'alert-success' : 'alert-error'  }`} >
                                  {status.msg}
                              </p>
            )}
          <Button  content="Register SN" type="submit" fluid className='collorSet3' />
        </Form>
      )}
    </Formik>
  );
});

