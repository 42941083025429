

   export enum PermissionLevel {
     SuperAdmin = 1,
     SystemAdmin = 2,
     Partner = 10,
     SiteAdministrator = 20,
     User = 21,
   }
   export enum CruTextOptions {
    SerialNumber = 1,
    ComputerName = 2,
    CompanyName = 3
  }

  export enum StatusOptions {
    BackupSuccess = 1,
    Warning = 2,
    CriticalError = 3,
    Registered = 4,
    Published = 5,
    Uninstalled  = 6,
    GeneratedSN = 7,
    RegistrationFailed= 9,
    NoBackupOccurred = 11,
  }

export const StatusOptionNames: Record<StatusOptions, string> = {
  [StatusOptions.BackupSuccess]: 'Backup Succeeded',
  [StatusOptions.Warning]: 'Warning',
  [StatusOptions.CriticalError]: 'Critical Error',
  [StatusOptions.Registered]: 'Registered',
  [StatusOptions.Published]: 'Published',
  [StatusOptions.Uninstalled]: 'Uninstalled',
  [StatusOptions.GeneratedSN]: 'Generated SN',
  [StatusOptions.RegistrationFailed]: 'Registration Failed',
  [StatusOptions.NoBackupOccurred]: 'No Backup Occurred'
};

export enum ReportStatusOptions {
    BackupStarted = 1,
    BackupCancelled = 2,
    BackupFinished=5,
    AttackDetected=6,
    DataSignatureFailure=7,
    BitlockerEncryptionFailed=9,
    CanaryFiles=13,
    BitlockerDecryptionFailed = 15,
    RecoveryStarted = 20,
    RecoveryCancelled = 21,
    RecoveryFinished = 22,
  }

  export enum  FrequencyOptions  {
    Daily='1',
    TowDay='2',
    Weekly='7'
  }
  export enum  EncryptionStatus  {
    EncryptionFailed=0,
    DeviceEncrypted=1,  
  }


  export function getStatusOptionDisplayText(deviceStatus: StatusOptions)
  {      
      return StatusOptionNames[deviceStatus];      
  }