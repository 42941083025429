import React, { useEffect,  useState } from 'react';
import {  Grid,GridColumn,GridRow, Tab, TabProps } from 'semantic-ui-react';
import CompanyForm from './CompanyForm';
import SiteSettings from './SiteSettings';
import SiteAdminDashBoard from './SiteAdminDashBoard';
import ProfileForm from '../UserProfiles/ProfileForm';
import { useStore } from '../../../api/stores/store';
import { PermissionLevel } from '../../../api/models/enums';


interface Props {
    type:string ;
  }
export function SystemTab (props: Props) {
    const {accountStore, globalStore} = useStore();
    const [activeTabIndex,setActiveTabIndex] = useState('0');
    const { setMenuActiveItem,} = accountStore;
    const { activeUser,getIsAdmin} = globalStore;
    const isAdmin: boolean = getIsAdmin();
    const dummyVoid = () => { };
    var panes = [
        { menuItem: { key: 'profile', value:'0', content: 'Profile'},
         render: () => ( <Tab.Pane>  <ProfileForm hideBox={dummyVoid} /></Tab.Pane>), }
        ];

        if (isAdmin ) {
                panes.push({
                    menuItem: { key: 'companyInfo', value:'1',  content:  'Company Info' },
                    render: () => (  <Tab.Pane><CompanyForm hideBox={dummyVoid} /></Tab.Pane>  )
                });
                panes.push({
                    menuItem: { key: 'siteSettings' , value:'2',  content: 'Site Settings'},
                    render: () => ( <Tab.Pane>  <SiteSettings/></Tab.Pane>)
                });
        }
        if (!activeUser!.cloudVersion && isAdmin) {
                panes.push({
                    menuItem: { key: 'systemUpdate', value:'3',  content:  'System Update' },
                    render: () => ( <Tab.Pane>  <SiteAdminDashBoard/></Tab.Pane>)
                });
         }
    useEffect(() => {
        setMenuActiveItem('')
        let selectedTab =  panes.find(pane => pane.menuItem.key === props.type);
        if (selectedTab)     
            setActiveTabIndex(selectedTab.menuItem.value);
    }, [props.type]);

    const setActiveTab = (tabProps: TabProps|undefined, index:number|undefined) => {
        if (tabProps && tabProps.activeIndex)
            setActiveTabIndex(tabProps.activeIndex.toString());
        else
            setActiveTabIndex('0');
    }

    return (
        <Grid  className='mainPageGrid'>
            <GridRow   >
            <span className='pageTitle'>Settings</span> 
            </GridRow>

            <GridRow   >
                <GridColumn width={15}>
                     <Tab    activeIndex={activeTabIndex} 
                        onTabChange={(e, data) => {setActiveTab(data,undefined); }} 
                           menu={{  secondary: true, pointing: true ,className:'tabContainer' }}  panes={panes} />
           </GridColumn>
           </GridRow>
     </Grid>

    )
}
