import { makeAutoObservable,runInAction ,reaction} from "mobx";
import { DashboardRow, Statistic } from "../models/statistic";
import agent from "../agent";
import { Backup } from "../models/backup";
import { Pagination, PagingParams, SortingParams } from "../models/pagination";


export default class backupsStore{
   
    backups :Backup[]=[];
    backupsStatistic: Statistic[]=[];
    loadingBackup=false;
    loadinInitialBackup=true;
    backupPagination : Pagination  = {currentPage:1,itemsPerPage:10,totalItems:0,totalPages:0};
    backupPagingParams  =  new PagingParams();
    backupSortingParams = new   SortingParams();
    filterSet: Map<any, any> = new Map();
    lastBackup:DashboardRow|undefined = undefined
    filterAplay:number=0;
    listSorted=false;
    listFiltered=false;
    constructor() {
        makeAutoObservable(this);
        reaction(
            
            () => ({
                backupPagingParams: this.backupPagingParams,
            }),
            (params) => {
                this.loadBackups();

    } );}

    setOptionFilterItem = (predicate: string, value: string) => {
        if (this.filterSet.has(predicate))
        {
             var prd = this.filterSet.get(predicate).toString();
             this.filterSet.set(predicate, prd +','+ value );
        }
        else
         { 
                this.filterSet.set(predicate,  value );
         }
      } 

      setFilterItem = (predicate: string, value: any) => {
            let oldPredicat  =  this.filterSet.get(predicate);
        if (oldPredicat !== value)
             this.filterSet.delete(predicate);
        this.filterSet.set(predicate, value);
      } 

      setCompanyFilter = ( value: any) => {
        this.filterSet.set('companyList', value);
      } 

      setDeviceIdFilter = ( value: any) => {
        this.filterSet.set('id', value);
      } 

      reSetFilterItem = (predicate: string) => {
        this.filterSet.delete(predicate);
        this.filterAplay--;
      } 

      resetSortingParams = () =>{

        this.backupSortingParams.sortField = "insertDate";
        this.backupSortingParams.sortAscending= "true";
      }

      deleteSort =()=>
      {
        this.backupSortingParams.sortField = "insertDate";
        this.backupSortingParams.sortAscending= "true";
         this.listSorted= false;
      }
      applySort = (sortField :string ) =>{
        if (this.backupSortingParams.sortField === sortField)
        {
            if(this.backupSortingParams.sortAscending === 'true')
                this.backupSortingParams.sortAscending = 'false';
            else
                this.backupSortingParams.sortAscending = 'true';
        }
        else
        {
            this.backupSortingParams.sortField = sortField;
            this.backupSortingParams.sortAscending = 'true' ;
        }
        this.loadBackups();
        this.listSorted= true;
    }

    setPagingParams = (pagingParams : PagingParams) =>   {
        this.backupPagingParams = pagingParams;
    }

    get axiosParams () {
         const params = new URLSearchParams();
         params.append('pageNumber' , this.backupPagingParams.pageNumber.toString());
         params.append('pageSize' , this.backupPagingParams.pageSize.toString());
         params.append('orderField' , this.backupSortingParams.sortField);
         params.append('orderAscending' , this.backupSortingParams.sortAscending);
         this.filterSet.forEach((value, key) => {
            if (key === 'companyList' && value &&  Array.isArray(value)) {
                value.map((companyId: any) =>(
                    params.append(key, companyId)
                ));
            } else {
                params.append(key, value);
            }
        })
         return params;
    }

    get backupsByCompany (){
       return Array.from(this.backups).sort((a,b) =>
          a.companyIDAuto - b.companyIDAuto);   
    }
    get groupedBackups() {
        return Object.entries(
            this.backupsByCompany.reduce((backups,bacup) => {
                const company = bacup.companyName;
                backups[company] = backups[company]? [...backups[company],bacup] : [bacup];
                return backups;
            },{} as {[key:string] : Backup[]})
        )
    }

    resetSentFilter =()=>
    {
        this.filterSet.forEach((key,value) => {
            this.filterSet.delete(key);
            this.filterSet.delete(value);
          })
          this.filterAplay=0;
          this.listFiltered = false;
    }

    loadBackups = async ()=>
    {
       
        this.loadinInitialBackup = true;
        this.backups = [];
        try{
                const result =  await agent.Backups.list(this.axiosParams);
                runInAction(() =>{
                    this.setPagination(result.pagination);
                    this.backups =  result.data;
              
                    this.loadinInitialBackup = false;
                })
        }
        catch(error)
        {
            this.loadinInitialBackup = false;
        }
    }
    
    getBackupsStatistic = async ()=>
    {
        this.loadinInitialBackup = true;
        this.backupsStatistic = []
        try{
                const result =  await agent.Backups.statistic(this.axiosParams);
                this.backupsStatistic = result.data;
                this.setLoadinInitialBackup(false);
        }
        catch(error)
        {
            this.setLoadinInitialBackup(false);
        }
    }

    getLastBackupRow = async ()=>
    {
        this.loadinInitialBackup = true;
        try{

                const result =  await agent.Backups.getLastBackup(this.axiosParams);
                this.lastBackup = result.data;
                this.setLoadinInitialBackup(false);
       }
        catch(error)
        {
            this.setLoadinInitialBackup(false);
        }
    }

    setLoadinInitialBackup =(state:boolean) =>{
        this.loadinInitialBackup = state;
    }


    setPagination = (pagination: Pagination) => {
        this.backupPagination = pagination;
    }
    
    sortAdapter = (state :any, action :any) => {
        let dir: string = 'true';
        this.backupSortingParams.sortField = action.column;
        if (state.column === action.column) {
            dir = state.direction === 'ascending' ? 'false' : 'true';}
        this.backupSortingParams.sortAscending = dir;
        this.setPagingParams(new PagingParams(1));
        this.loadBackups();
        if (state.column === action.column) {    
            return {
            ...state,
            direction:
              state.direction === 'ascending' ? 'descending' : 'ascending',
            }
        }
        return {
            column: action.column,
            direction: 'ascending',
        } 
    }

  
    setLoadinInitialDevice =(state:boolean) =>{
        this.loadinInitialBackup = state;
    }
 }


