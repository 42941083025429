import { observer } from 'mobx-react-lite';
import React, { useEffect }  from 'react';
import { Grid, GridColumn, GridRow } from 'semantic-ui-react';
import { useStore } from '../../../api/stores/store';

export default observer(function DownDocs() {
    const { resourcesStore,} = useStore();
    const { docsResources ,loadActiveResources } = resourcesStore;
    useEffect(() => {
           if (docsResources === undefined) loadActiveResources();
      }, []);
      return (
            
        <Grid  divided='vertically' >
                    {docsResources.map((resouce:any) => (
                        <GridRow  >
                            <GridColumn  >
                                <a href={resouce.fileUrl}  download rel="noopener noreferrer" >
                                    <span className='resourceLabel'>{resouce.fileName}</span><br></br>
                                    <span className='resourceSmallLabel'> {resouce.insertDate}</span>
                                </a>
                            </GridColumn>
                            </GridRow>
                    ))}
        </Grid>
         )});
        