import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Button, Image, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import { useStore } from '../../../api/stores/store';
import FormTextInput from '../../UIItems/FormTextInput';
import LoadingComponent from '../../UICommon/LoadingComponent';
import { ServerResult  } from '../../../api/models/common';
import ServerMsgBar from '../../UIItems/ServerMsgBar';
import * as Yup from 'yup';

export default observer(function SiteSettings() {
  const { smtpStore } = useStore();
  const { loadSiteSettings, sSiteSettings, loadSettingsState } = smtpStore;
  const [responseState, setResponseState] = useState<ServerResult  | undefined>(
    undefined
  );

  function hideResponseState() {
    if (responseState) setResponseState(undefined);
  }
  useEffect(() => {
    loadSiteSettings();
  }, []);
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };
  if (loadSettingsState)
    return <LoadingComponent content="Loading Site Settings.." />;
  return (
    <Formik
      initialValues={{
        accountSid: sSiteSettings ? sSiteSettings.accountSid : '',
        accountToken: sSiteSettings ? sSiteSettings.accountToken : '',
        fromNumber: sSiteSettings ? sSiteSettings.fromNumber : '',
        availableSmsServer: true,

        smtpPort: sSiteSettings ? sSiteSettings!.smtpPort : 0,
        fromName: sSiteSettings ? sSiteSettings!.fromName : '',
        fromEmail: sSiteSettings ? sSiteSettings!.fromEmail : '',
        smtpServer: sSiteSettings ? sSiteSettings!.smtpServer : '',
        authenticateUser: sSiteSettings ? sSiteSettings!.authenticateUser : '',
        authenticateSecret: sSiteSettings
          ? sSiteSettings!.authenticateSecret
          : '',
        availableSmtpServer: true,
        error: null,
      }}
      onSubmit={(values, { setStatus }) => {
        smtpStore
          .updateSiteSetting(values)
          .then((res) => {
            var successResult = {
              isSuccess: true,
              msg: 'The Site Settings Updated Successfully ',
            };
            setResponseState(successResult);
          })
          .catch((error) => {
            var successResult = {
              isSuccess: false,
              msg: 'Failed to update the Site Settings'
            };
            setResponseState(successResult);
          });
      }}
       validationSchema={Yup.object({
        smtpServer: Yup.string().required('SMTP Server is required'),
        smtpPort: Yup.number().typeError("SMTP Port must be a number").required("SMTP Port is required"),
      })}
    >
      {({ handleSubmit, isValid, status }) => {
        return (
          <Form
            onKeyDown={handleKeyDown}
            className="ui form error internalForm"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Grid onClick={hideResponseState}>
              <GridRow>
                <GridColumn width={6}>
                  <Image
                    src="/img/settings_applications.svg"
                    className="formSubTitleImg"
                  />
                  <span className="formSubTitle">SMTP Settings</span>
                </GridColumn>
                <GridColumn width={6}>
                  <Image src="/img/sms.svg" className="formSubTitleImg" />
                  <span className="formSubTitle"> SMS Service Settings</span>
                </GridColumn>
              </GridRow>

              <GridRow className="formFieldRow">
                <GridColumn width={6}>
                  <FormTextInput
                    title="SMTP Server"
                    name="smtpServer"
                    placeholder="Smtp Server"
                    required
                  />
                </GridColumn>
                <GridColumn width={6}>
                  <FormTextInput
                    title="Account Sid"
                    name="accountSid"
                    placeholder="Account Sid"
                  />
                </GridColumn>
              </GridRow>

              <GridRow className="formFieldRow">
                <GridColumn width={6}>
                  <FormTextInput
                    title="SMTP Port"
                    name="smtpPort"
                    placeholder="SMTP Port"
                    required
                  />
                </GridColumn>
                <GridColumn width={6}>
                  <FormTextInput
                    title="Account Token"
                    name="accountToken"
                    placeholder="Account Token"
                  />
                </GridColumn>
              </GridRow>
              <GridRow className="formFieldRow">
                <GridColumn width={6}>
                  <FormTextInput
                    title="Sender Email"
                    name="fromEmail"
                    placeholder=" Sender Email"
                  />
                </GridColumn>
                <GridColumn width={6}>
                  <FormTextInput
                    title="Sender Phone Number"
                    name="fromNumber"
                    placeholder=" From Number"
                  />
                </GridColumn>
              </GridRow>
              <GridRow className="formFieldRow">
                <GridColumn width={6}>
                  <FormTextInput
                    title="Sender Name"
                    name="fromName"
                    placeholder="Name"
                  />
                </GridColumn>
                <GridColumn width={6}></GridColumn>
              </GridRow>
              <GridRow className="formFieldRow">
                <GridColumn width={6}>
                <FormTextInput
                          title="Sender User"
                          name="authenticateUser"
                          placeholder="User"
                        />
                </GridColumn>
                <GridColumn width={6}></GridColumn>
              </GridRow>
              <GridRow className="formFieldRow">
                <GridColumn width={6}>
                <FormTextInput
                          name="authenticateSecret"
                          placeholder="Secret"
                          icon="lock"
                          type="password"
                          title="Sender Secret"
                          iconPosition="left"
                          autoComplete="new-password"
                        />
                </GridColumn>
                <GridColumn width={6}> </GridColumn>
              </GridRow>

              <GridRow>
                <GridColumn width={8}>
                 </GridColumn>
                <GridColumn width={6}>
                  {responseState && (
                    <ServerMsgBar runActionResult={responseState} />
                  )}
                </GridColumn>
            </GridRow>
            </Grid>
            <Button
                    disabled={!isValid}
                    content="   Update   "
                    type="submit"
                    className="applyBtn tabFormBtn"
                  />
          </Form>
        );
      }}
    </Formik>
  );
});
