import React from 'react';
import {  Grid,Image } from 'semantic-ui-react';


export function UnderConstruction () {
    return (

        <Grid className='mainPageGrid'>
                <Grid.Column   >
                            <Image   src="/img/uc.jpeg" />  
                </Grid.Column >
         </Grid>


    )
}

