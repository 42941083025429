import {  Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid, GridColumn, GridRow, Image } from 'semantic-ui-react';
import { useStore } from '../../../api/stores/store';
import * as Yup from 'yup';
import ServerMsgBar from '../../UIItems/ServerMsgBar';
import FileInput from '../../UIItems/FileInput';
import { ServerResult  } from '../../../api/models/common';

export default observer(function DBAdmin() {
    const {resourcesStore} = useStore();
    const formData = new FormData();
    const [exportStatus,setExportStatus] = useState<ServerResult  | undefined  >(undefined);
    const [importStatus,setImportStatus] = useState<ServerResult  | undefined  >(undefined);
    const [uploadStatus,setUploadStatus] =  useState<ServerResult  | undefined  >(undefined);
    const [fileUploaded,setFileUploaded] =  useState(false);
    const [fileExported,setFileExported] =  useState(false);
    const refResponseStatus = useRef<HTMLDivElement>(null);
    function useOnClickOutside(
        ref: React.RefObject<HTMLElement>,
        handler: (event: MouseEvent) => void
      ) {
        useEffect(() => {
          const handleClick = (event: MouseEvent) => {
            if (!ref.current || ref.current.contains(event.target as Node)) {
              return;
            }
            handler(event);
          };
          document.addEventListener('mousedown', handleClick);
          return;
        }, [ref, handler]);
      }
      useOnClickOutside(refResponseStatus, () => hideResponseState());
    function hideResponseState()
    {
        setUploadStatus(undefined)
        setImportStatus(undefined)
        setExportStatus(undefined)
    }

    return (
        <Formik
        initialValues={{
            
            fileType: '0',
            fileVersion: '0',
            insertDate: '',
            activeVersion: '',
            fileName:'',
            fileDB:null,
            error: null}}

        onSubmit={(values) => {
                if (values.fileDB)
                {
                    formData.append('file', values.fileDB);
                    formData.append('fileType', values.fileType);
                    formData.append('fileVersion',values.fileVersion);
                    formData.append('fileName',values.fileName);
                    resourcesStore.uploadDBImport(formData)
                        .then(_res => {
                            setUploadStatus({
                            isSuccess: true,
                            msg: "File Successfully Uploaded"
                            })
                            setFileUploaded(true);
                        })
                        .catch(error => {
                            setUploadStatus({
                                isSuccess: false,
                                msg: error 
                        })})
                    }
                else
                    setUploadStatus({
                        isSuccess: false,
                        msg:  "Not  selected file for Uploading" 
                })
        } }
        validationSchema={Yup.object().shape({
            fileDB: Yup.mixed().required('File is required'),
        })} >

        {({  handleSubmit,setFieldValue,status ,values}) => {

        const hendlFileChange = function (uploadFile :File){

                setFieldValue("fileDB", uploadFile);
                setFieldValue("fileName", uploadFile.name);
                formData.append('file',uploadFile);
                formData.append('fileName',values.fileName);
        }

        function handlRunImportDB() {
            resourcesStore.runDBImport()
            .then(_res => {
                setImportStatus({
                isSuccess: true,
                msg: "Database Import Successfully Completed."
                })
            })
            .catch(error => {
                setImportStatus({
                    isSuccess: false,
                msg: error
            })})}

        function handlRunExportDB() {
            resourcesStore.runDBExport()
            .then(_res => {
                setExportStatus({
                isSuccess: true,
                msg: "Database Export Successfully Completed"
                })
                setFileExported(true)
            })
            .catch(error => {
                setExportStatus({
                    isSuccess: false,
                msg: error
            })
            setFileExported(false)
        })}
        
        return (
            <Form className='ui form error internalForm '   onSubmit={handleSubmit} autoComplete='off'            >
            <Grid >
            <GridRow className="formFieldRow">
                            <GridColumn>
                                <Image
                                src="/img/database.svg"
                                className="formSubTitleImg"
                                />
                                <span className="formSubTitle">Upload DB Import File</span>
                                <span className="subLabel">(Zip format)</span>
                            </GridColumn>
                    </GridRow>
                    <GridRow className="formFieldRow">      
                             <GridColumn className='file-inputBox'>
                                <Button   disabled type='submit' className='applyBtn'>
                                        Upload
                                        <Image src="/img/upload5.svg" className='btnImg' />
                                </Button>
                                <FileInput name="fileDB" disabled  onFileChanged={hendlFileChange}/>
                        </GridColumn>
                </GridRow>
                <GridRow className="formFieldRow">
                            <GridColumn>
                                <Image
                                src="/img/export1.svg"
                                className="formSubTitleImg"
                                />
                                <span className="formSubTitle">Data Import</span>
                                <span className="subLabel">Description</span>
                            </GridColumn>
                    </GridRow>
                    <GridRow className="formFieldRow">             
                             <GridColumn className='file-inputBox'>
                             <Button  disabled type="button"  className='applyBtn  sysAction'     
                             onClick={handlRunImportDB} >Import </Button>
                               
                        </GridColumn>
                </GridRow>            
                <GridRow className="formFieldRow">
                            <GridColumn>
                                <Image
                                src="/img/upload2.svg"
                                className="formSubTitleImg"
                                />
                                <span className="formSubTitle">Data Export</span>
                                <span className="subLabel">Description</span>
                            </GridColumn>
                    </GridRow>
                <GridRow className="formFieldRow">             
                             <GridColumn className='file-inputBox'>
                             <Button   type="button"  className='applyBtn  sysAction'     
                             onClick={handlRunExportDB} >Export </Button>
                            {fileExported && (
                                     <a className='downloadLink' 
                                     href='/resources/lastBackup.zip' download>
                                        You can receive a copy of the export file</a>
                            )}
                        </GridColumn>
                </GridRow>  
                <div ref={refResponseStatus}>
                {exportStatus && (
                        <ServerMsgBar runActionResult={exportStatus} />
                )}
                 {importStatus && (
                        <ServerMsgBar runActionResult={importStatus} />
                )}
                  {uploadStatus && (
                        <ServerMsgBar runActionResult={uploadStatus} />
                )}
                </div>
            </Grid>
            </Form> ); }}
     </Formik >
 );})