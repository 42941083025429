

import { ErrorMessage, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Button, Divider, FormGroup, Header, Icon,Item,  Rail, Segment } from 'semantic-ui-react';
import MyTextInput from './MUIItems/MyTextInput';
import * as Yup from 'yup';
import { useStore } from '../../api/stores/store';
import ValidationErrors from '../UIParts/Errors/ValidationErrors';
export default observer(function MTabRegisterCompany() {

    const {accountStore} = useStore();
    const [waitResponse, setWaitResponse] = useState(false);
    const [showSNHelp, setShowSNHelp] = useState(false);
    var successMessage =  "The Device and Company  is Successfully Registered .";
    
    return (
        <Formik
        initialValues={{
            companyName: '',
            email:'',
            username:'',
            password: '',
            displayName: '',
            deviceSN: '',
            fromVM:false,
            partnerSiteNumber:'',
            siteLocation: '',
            error: null}}
            
            onSubmit={(values, {setErrors,setStatus}) =>
            {
             setWaitResponse(true);
            accountStore.registerSet(values)
            .then(res => { setStatus({  sent: true,msg: successMessage });
                                 setWaitResponse(false);
            })
            .catch(error =>  {setStatus({sent: false, msg: error})
                                 setWaitResponse(false);
                     })
            }}
            
            validationSchema={Yup.object({
                companyName: Yup.string().required("Company Name is a required field"),
                displayName: Yup.string().required("Display Name is a required field"),
                email: Yup.string().required("Email is a required field").email('Email is a Invalid '),
                password: Yup.string().required('Password is required')
                .min(6, 'Password should be longer than 5 symbols')
                .matches(/(?=.*[A-Z])/, " Must have at least one uppercase ('A'-'Z')"),
                passwordConfirmation: Yup.string().required().oneOf([Yup.ref('password'), null], 'Passwords must match'),
                deviceSN: Yup.string().required("Device SN is a required field"),
            })}
        >
            {({handleSubmit,  errors, isValid, status}) => (
                
                <Form className='ui form error' onSubmit={handleSubmit} autoComplete='off'>
                    <Header as='h2' content='Register Company' textAlign='center' className='titleSet1' />
                    <FormGroup widths='equal'>
                    <MyTextInput name='companyName' placeholder='Company Name' icon='building outline' iconPosition='left' />
                    <MyTextInput name='partnerSiteNumber' placeholder='Partner Site Number (Optional)' icon='world' iconPosition='left' />
                   </FormGroup>
                    <Divider horizontal>
                        <Header as='h4'>
                            <Icon name='user' />
                            Company Site Admin
                        </Header>
                    </Divider>
                    <FormGroup widths='equal'>
                    <MyTextInput name="email" placeholder="Please type admin’s email address" icon='at' iconPosition='left'/>
                    <MyTextInput name='password' placeholder='Please choose a password' type='password' icon='lock'  iconPosition='left' 
                        /> 
                    </FormGroup>
                   <FormGroup widths='equal'>
                   <MyTextInput name='displayName' placeholder='Display Name'  icon='user circle'  iconPosition='left' />

                     
                        <MyTextInput name='passwordConfirmation' placeholder='Please confirm password' type='password' icon='lock'  iconPosition='left' />
                        </FormGroup>
                    <Divider horizontal>
                        <Header as='h4'>
                            <Icon name='hdd' />
                            CRU Device
                          </Header>
                    </Divider>
                    <FormGroup widths='equal'>
                        <MyTextInput name='deviceSN' placeholder='Enter SN' icon='hdd'  iconPosition='left' />
                        <MyTextInput  name='siteLocation' placeholder='Device Location (Optional)'  icon='map marker alternate'  iconPosition='left'  />
                    </FormGroup>
                    <Rail internal position='left' hidden={!showSNHelp} onClick={() => setShowSNHelp(false)}>
                    <Segment.Group compact>
                        <Segment>
                            <Icon name='close' floated='right' className='closeImage' />
                             <Item.Image src='/img/SN_Help.jpg' /></Segment>
                        </Segment.Group>
                    </Rail>
                    <a className='activeLink'  onClick={() => setShowSNHelp(!showSNHelp)}>  <Icon name='hdd' /> What is SN?</a>

                    <ErrorMessage 
                        name='error' render={() => 
                        <ValidationErrors errors={errors.error}/>}
                         />
                            {status && status.msg && (
                              <p className={`alert ${ status.sent ? 'alert-success' : 'alert-error'  }`} >
                                  {status.msg}
                              </p>
                        )}
                    <Button disabled={!isValid || waitResponse }  content='Register' type='submit' fluid className='collorSet3' />
                    
                </Form>
            )}
        </Formik>
    )
}) 