import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import { isMobile } from "react-device-detect";
import { ToastContainer } from 'react-toastify';
import { useStore } from "../api/stores/store";
import { Route, Routes, useLocation, useNavigate} from "react-router-dom";

import ModalContainer from './UICommon/ModalContainer';
import { Layout } from './UICommon/Layout';
import StartPage from './UIParts/Home/StartPage';
import DeviceDashBoard from './UIParts/Devices/DeviceDashBoard';

import BackupsList from './UIParts/Logs&Reports/BackupsList';
import Downloads from './UIParts/Downloads/Downloads';
import ServerError from './UIParts/Errors/ServerError';
import NotFound from './UIParts/Errors/NotFound';
import UserList from './UIParts/UserProfiles/UserList';
import UserForm from './UIParts/UserProfiles/UserForm';
import { UnderConstruction } from './UICommon/UnderConstruction';
import { SystemTab } from './UIParts/Settings/SystemTab';
import ProfileForm from './UIParts/UserProfiles/ProfileForm';
import MStartPage from './Mobile/MStartPage';
import DeviceListItems from './Mobile/DeviceListItems';
import { AdminTab } from './UIParts/Admin/AdminTab';
import { MLayout } from './Mobile/MUICommon/MLayout';
import MDeviceDashBoard from './Mobile/MDeviceDashBoard';
import MDownloads from './Mobile/MDownloads';
import { PermissionLevel } from '../api/models/enums';
import DevicesList from './UIParts/Devices/DevicesList';
import BackupsListItems from './Mobile/BackupsListItems';



function App() {
   const location = useLocation();
   const {globalStore,deviceStore} = useStore();
   const { activeUser ,getIsAdmin} = globalStore;
   const navigate = useNavigate();
   const defaultPath = '/devicesDashBoard';
   const isAdmin: boolean = getIsAdmin();


   useEffect(() => {
     var parsed = queryString.parse(location.search);
    var inParam   = location.search && parsed.inParam? parsed.inParam.toString():'' ;
    var arrivalType = location.search? parsed.type: 'login' ;

    if ((arrivalType === 'confirmSN' ) && globalStore.token  ) 
        deviceStore.generateNewSN(inParam).then(() => navigate('/adminTab/assets'))
  }, [])

  const dummyVoid = () => { };
  return (
    <>
          <ToastContainer position='bottom-right' hideProgressBar />
          <ModalContainer />
      
          <Routes>
             {!isMobile && (
              <>
                    <Route  path='/login' element={<StartPage/>}/>
                    {!activeUser && (
                      <>
                      <Route  path="*"  element={<StartPage/>}/>
                      <Route  path="/"  element={<StartPage/>}/>
                      </>
                    )}
                    {activeUser && (
                        <Route path='/' element={<Layout />}>
                              <Route  path='/' element={<DeviceDashBoard/>}/>
                              <Route    path='/devices'element={<DevicesList/>}/>
                              <Route   path='/reports' element={<BackupsList/>}/>
                              <Route   path='/SNReports/:id' element={<BackupsList/>}/>
                      
                              <Route  path='/devicesDashBoard' element={<DeviceDashBoard/>}/>
                              <Route  path='/userProfile'element={<ProfileForm hideBox={dummyVoid}/>}/>
                              <Route  path='/downloads'element={<Downloads/>}/>

                              <Route path='/sysTab/profile' element={<SystemTab  type='profile'/>} />
                              {(isAdmin || activeUser?.level == PermissionLevel.Partner) && (
                                <>
                                  <Route  path='/userProfiles'element={<UserList/>}/>
                              </>
                              )}
                              {(isAdmin ) && (
                                <>
                                  <Route  path='/sysTab/companyInfo'element={<SystemTab type='companyInfo'/>}/>
                                  <Route  path='/sysTab/siteSettings'element={<SystemTab type='siteSettings'/>}/>
                                  <Route  path='/sysTab/systemUpdate'element={<SystemTab type='systemUpdate'/>}/>
                                  <Route  path='/userInfo'element={<UserForm hideBox={dummyVoid} setResponseState={dummyVoid}/>}/>
                                
                                  <Route  path='/adminTab/assets'element={<AdminTab type='assets'/>}/>
                                  <Route  path='/adminTab/resources'element={<AdminTab type='resources'/>}/>
                              </>
                              )}
      
                              <Route path='/errors' element={<ServerError/>} />
                              <Route path='/server-error' element={<ServerError/>} />
                              <Route path ='*' element={NotFound} />
                              <Route path='/notExist' element={<UnderConstruction/>} />
                            
                        </Route>
                        )}
                            </>
               )}
               {isMobile && (
                <>
                       <Route  path='/login' element={<MStartPage/>}/>
                        {!activeUser && (
                          <>
                          <Route  path="*"  element={<MStartPage/>}/>
                          <Route  path="/"  element={<MStartPage/>}/>
                          </>
                        )}
                    {activeUser && (
                          <Route path='/' element={<MLayout />}>
                          <Route  path='/' element={<MDeviceDashBoard/>}/>
                          <Route  path='/devices'element={<DeviceListItems/>}/>
                          <Route   path='/reports' element={<BackupsListItems/>}/>
                         <Route  path='/devicesDashBoard' element={<MDeviceDashBoard/>}/>
                      
                         <Route  path='/downloads'element={<MDownloads/>}/>

                       <Route path='/errors' element={<ServerError/>} />
                        <Route path='/server-error' element={<ServerError/>} />
                       <Route path ='*' element={NotFound} />
                  </Route>
                   )}
                </>)}
        </Routes>
    </>
      )}
  
  export default observer (App);
