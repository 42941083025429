import { observer } from 'mobx-react-lite';
import { useStore } from '../../../api/stores/store';
import React  from 'react';
import { Form, Formik } from 'formik';

import { Button,  Grid,  GridColumn,  GridRow,  Image,  Label,  Segment,} from 'semantic-ui-react';
import * as Yup from 'yup';
import MySelectInput from '../../UIItems/FormSelect';
import _ from 'lodash';
import FormTextInput from '../../UIItems/FormTextInput';
import { MAX_INPUT_LENGTH } from '../../../api/options/constants';

type Props = {
  hideBox: () => void;
};

export default observer(function DeviceRegister(props: Props) {
  const { deviceStore, globalStore } = useStore();
  const { companyList, activeUser  } = globalStore;

  const siteOptions = _.map(companyList, (company) => ({
    key: company.companyId,
    text: company.companyName,
    value: company.companyId.toString(),
  }));

  const handleCloseClick = () => {
    props.hideBox();
  };
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };
  return (
    <Formik
      initialValues={{
        deviceSN: '',
        companyCode: activeUser ? activeUser.companyId.toString() : '',
        siteLocation: '',
        fromVM: false,
        error: null,
      }}
      onSubmit={(values, { setStatus }) => {
        deviceStore
          .regestryDevice(values)
          .then((res) => {
            setStatus({
              sent: true,
              msg: 'The Device is Successfully Registered',
            });
          })
          .catch((errors) => {
            setStatus({ sent: false, msg: errors[0] });
          })
      }}
      onReset={(values, { setStatus }) => {
        values.deviceSN = '';
        values.siteLocation = '';
        values.companyCode = activeUser ? activeUser.companyId.toString() : '';
      }}
      validationSchema={Yup.object({
        companyCode: Yup.string().required('Site Number is a required field'),
        deviceSN: Yup.string().length(8,"Incorrect SN entered").required("Device SN is a required "),
        siteLocation: Yup.string(). max(MAX_INPUT_LENGTH, `Field should not exceed ${ MAX_INPUT_LENGTH } characters`),
      })}
    >
      {({ handleSubmit, handleReset, errors, status }) => (
        <Segment raised compact className="addFormBox">
          <Form
            onKeyDown={handleKeyDown}
            onSubmit={handleSubmit}
            onReset={handleReset}
            autoComplete="off"
          >
            <Grid className="addForm">
              <GridRow>
                <GridColumn width={14}>
                  <span className="popupTitle">Add Assets</span>
                </GridColumn>
                <GridColumn width={2}>
                  <Image
                    src="/img/close.svg"
                    className="closeWindow"
                    onClick={handleCloseClick}
                  />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn>
                  {activeUser && activeUser.level < 20 ? (
                    <MySelectInput
                      options={siteOptions}
                      placeholder="Company Code"
                      name="companyCode"
                    />
                  ) : (
                    <></>
                  )}
                  {activeUser && activeUser.level >= 20 ? (
                    <Label size="medium">
                      {'Site Number ' + activeUser!.userCompany.companyId}
                      <Label.Detail>
                        {' '}
                        {activeUser!.userCompany.companyName}
                      </Label.Detail>
                    </Label>
                  ) : (
                    <></>
                  )}
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn>
                  <FormTextInput
                    name="deviceSN"
                    placeholder="Device SN"
                    title="Device SN"
                    required
                  />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn>
                  <FormTextInput
                    name="siteLocation"
                    placeholder="Site Location"
                    title="Site Location"
                  />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn className="justifyRight">
                  <Button
                    content="   Apply   "
                    type="submit"
                    className="applyBtn"
                  />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn>
                  {status && status.msg && (
                    <div
                      className={`responseMessage ${
                        status.sent ? 'success' : 'fail'
                      }`}
                    >
                      <p> {status.msg} </p>
                    </div>
                  )}
                </GridColumn>
              </GridRow>
              <GridRow className="activeLinkRow">
                <span onClick={(e) => handleReset()} className="activeLink">
                  + Add Another Asset
                </span>
              </GridRow>
            </Grid>
          </Form>
        </Segment>
      )}
    </Formik>
  );
});
