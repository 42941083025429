import { useField } from 'formik';
import React from 'react';
import { Form, Input, Label } from 'semantic-ui-react';

interface FormTextInputProperties {
    placeholder: string;
    name: string;
    title?: string;
    type?:string;
    icon?:string;
    iconPosition?:'left';
    label?: string;
    required?:boolean;
    className?:string;
    autoComplete?:string
    inverted?:boolean;
}

export default function FormTextInput(props: FormTextInputProperties) {
    const [field, meta] = useField(props.name); 
    const cssClass ='formTextInput '+ (props.inverted ? ' invertedCollor ' : '')+ (meta.error ? ' error ':'')  ;

    return (
        <Form.Field error={meta.touched && !!meta.error} className={props.className}>
           <label className={props.required ? 'required' : ''} >{props.title}</label>
            <Input    {...field} {...props}  className={cssClass} />
            {meta.touched && meta.error ? (
                <Label basic className='errorLbl'>{meta.error}</Label>
            ) : null}
            
        </Form.Field>
    )
} 