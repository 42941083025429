import {  FieldProps, useField } from 'formik';
import React from 'react';
import { Checkbox, Form } from 'semantic-ui-react';

interface FormSwitchProperties  {
    name: string;
    label?: string;
}

export default function ActivateNotification (props: FormSwitchProperties) {
    const [field, meta, helpers] = useField(props.name); 
    return (
        <Form.Field error={meta.touched && !!meta.error}>
            <Checkbox toggle  {...field} {...props}  value={field.value  }
            onChange={(e, d) => helpers.setValue(d.checked? 1:0)} checked = {field.value === 1}
                            />
        </Form.Field>
    )
} 

