import { ErrorMessage, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Button,  Grid,  GridRow,  Icon,  Item,  Rail,  Segment } from 'semantic-ui-react';
import { useStore } from '../../../api/stores/store';
import * as Yup from 'yup';
import ValidationErrors from '../Errors/ValidationErrors';
import {Image} from 'semantic-ui-react';
import FormTextInput from '../../UIItems/FormTextInput';
import LoginTextInput from '../../UIItems/LoginTextInput';

interface Props {
    showRegCompany: boolean;
    setShowRegCompany: React.Dispatch<React.SetStateAction<boolean>>;
  }

export default observer(function TabRegisterCompany(props: Props) {

    const {accountStore} = useStore();
    const [waitResponse, setWaitResponse] = useState(false);
    const [showSNHelp, setShowSNHelp] = useState(false);
    var successMessage =  "The Device and Company  is Successfully Registered .";
   

    return (
        <Formik
        initialValues={{
            companyName: '',
            email:'',
            username:'',
            password: '',
            displayName: '',
            deviceSN: '',
            fromVM:false,
            partnerSiteNumber:'',
            siteLocation: '',
            error: null}}
            
            onSubmit={(values, {setErrors,setStatus}) =>
            {
             setWaitResponse(true);
            accountStore.registerSet(values)
            .then(res => { setStatus({  sent: true,msg: successMessage });
                                 setWaitResponse(false);
            })
            .catch(error =>  {setStatus({sent: false, msg: error})
                                 setWaitResponse(false);
                     })
            }}
            
            validationSchema={Yup.object({
                companyName: Yup.string().required("Company Name is a required "),
                displayName: Yup.string().required("Display Name is a required "),
                email: Yup.string().required("Email is a required ").email('Email is a Invalid '),
                password: Yup.string().required('Password is required')
                .min(6, 'Password should be longer than 5 symbols')
                .matches(/(?=.*[A-Z])/, " Must have at least one uppercase ('A'-'Z')"),
                passwordConfirmation: Yup.string().required('Retype password is a required').oneOf([Yup.ref('password'), null], 'Passwords must match'),
                deviceSN: Yup.string().length(8,"Incorrect SN entered").required("Device SN is a required "),
            })}
        >
            {({handleSubmit,  errors, isValid, status}) => (

         <Form  className="vertical-form1" onSubmit={handleSubmit} autoComplete='off'>

         <Grid columns={2}  className='RegCompanyGrid'>

           <GridRow className='smallTitleRow'>
                  <Image    src="/img/left.svg" className='mediumImg'   onClick={() =>  props.setShowRegCompany(false)}/>
                  <span className='loginGroupLabel'>Register your company</span>
           </GridRow>

           <GridRow className='loginRow1'>
               <Grid.Column  style={{ height:'100%' }}>
                       <LoginTextInput name="companyName" placeholder="Company Name"   
                       title='Company Name'    required/>
               </Grid.Column> 
               <Grid.Column   style={{ height:'100%' }}>
                       <LoginTextInput name="partnerSiteNumber" placeholder="Partner Site Number  "   
                         title='Partner Site Number '/>
               </Grid.Column> 
           </GridRow>

           <GridRow className='smallTitleRow1'>
                  <Image   src="/img/box.svg" className='smallImg' />
                  <span className='loginGroupLabel'>Company Site Admin</span>
                  <div className="divider"></div>
            </GridRow>


             <GridRow className='loginRow1'>
                    <Grid.Column   style={{ height:'100%' }}>
                    <LoginTextInput name="email" placeholder="Admin Email "    title='Admin Email' required />
                   </Grid.Column>
                   <Grid.Column   style={{ height:'100%' }}>
                       <LoginTextInput name='displayName' placeholder='Display Name'  required  title='Display Name' />
                   </Grid.Column>
             </GridRow >

             <GridRow className='loginRow1'>
                    <Grid.Column   style={{ height:'100%' }}>
                        <LoginTextInput name='password' placeholder='Create Password' type='password' 
                                                  required  title='Create Password'  />
                   </Grid.Column>
                   <Grid.Column   style={{ height:'100%' }}>
                      <LoginTextInput name='passwordConfirmation' placeholder='Retype Password' type='password' 
                                                required  title='Retype password' />
                   </Grid.Column>
             </GridRow >

             <GridRow className='smallTitleRow1'>
                  <Image   src="/img/box.svg" className='smallImg' />
                  <span className='loginGroupLabel'>Serial Number</span>
                  <div className="divider"></div>
            </GridRow>

            <GridRow className='loginRow1'>
                    <Grid.Column   style={{ height:'100%' }}>
                        <LoginTextInput name='deviceSN'
                                                   placeholder='Enter SN'  
                                                  required  title='Serial Number'/>
                        <Image  className='questImg'  src="/img/question.svg" onClick={() => setShowSNHelp(!showSNHelp)} />
                  </Grid.Column>
                  <Grid.Column   style={{ height:'100%' }}>
                       <LoginTextInput  name='siteLocation'  placeholder='Device Location'     title='Device Location' />
                  </Grid.Column>
            </GridRow >
           
             <Rail internal position='left' hidden={!showSNHelp} onClick={() => setShowSNHelp(false)}>
                    <Segment.Group compact>
                        <Segment>
                            <Icon name='close' floated='right' className='closeImage' />
                             <Item.Image src='/img/SN_Help.jpg' /></Segment>
                        </Segment.Group>
                    </Rail>
                    <ErrorMessage 
                        name='error' render={() => 
                        <ValidationErrors errors={errors.error}/>}
                         />
                            {status && status.msg && (
                              <p className={`alert ${ status.sent ? 'alert-success' : 'alert-error'  }`} >
                                  {status.msg}
                              </p>
                        )}
             <GridRow  className='smallTitleRow1' width={16}>
                 <Grid.Column   width={16} className='justifyCenter'>     
                              <Button  disabled={!isValid || waitResponse } content=" Register " type="submit" fluid className='loginBtn'/>
                 </Grid.Column>
               </GridRow>

           </Grid>
           </Form>
      )}
    </Formik>
  );
});
