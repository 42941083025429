import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { PieChart, Pie,  Cell, Legend, PieLabelRenderProps } from 'recharts';
import LoadingComponent from '../../UICommon/LoadingComponent';
import { useStore } from '../../../api/stores/store';
import { useNavigate } from 'react-router-dom';
import { Statistic } from '../../../api/models/statistic';
import { getStatusOptionDisplayText } from '../../../api/models/enums';

export default observer(function StatisticChart() {
  const {deviceStore,filterStore} = useStore();
  const {getStatusOptionName} = filterStore;
  const {devicesStatistic,getDevicesStatistic, setFilterItem} = deviceStore;
  const navigate = useNavigate();
 
  const renderLegend = (props: PieLabelRenderProps) => {
  const sumStatistic =  devicesStatistic.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0);

    return (
      <ul>
      {devicesStatistic.map((entry :Statistic) => (
         
         <li key={entry.status}  className={`chartSubTitle  status${getStatusOptionName(entry.status) }`}> 
             {` ${getStatusOptionDisplayText(entry.status)} `}
             <span className='percentTitle'>{`${Math.round(  100 *entry.count /sumStatistic) } %`}</span>
        </li>
       
      ))}
       
      </ul>
    );
  };
  

    useEffect(()=>{ 
        if(devicesStatistic.length === 0 ) getDevicesStatistic();
    },[devicesStatistic.length])
    
    function drillDownFilteredList (status :number)
      {
        setFilterItem('statusList', status);
            navigate('/devices');
      }

    if(deviceStore.loadinInitialDevice) return <LoadingComponent content='Loading App Statistic..'/>


    
  return (
  <>
       {devicesStatistic.length > 0 && (
        <>
      <span className='statisticTitle'>Assets</span> 
      <PieChart width={420} height={210} >
        <Pie
          data={devicesStatistic}
          innerRadius={55} outerRadius={70} 
          fill="#8884d8"
          paddingAngle={1}
          dataKey="count"
          nameKey="statusName"
          label
        >
          {devicesStatistic.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={
            entry.status === 1? '#3DC03A':
            entry.status === 2? '#FFAA07':
            entry.status === 3? '#FF2727':
            entry.status === 11? '#0082CA':'#101820'}   onClick={() => drillDownFilteredList( entry.status)} />
          ))}

        </Pie>
        <Legend  height={120}  width={210} content={renderLegend as React.FC} 
                 layout="vertical"  align='right'  iconType={'circle'} />
      </PieChart>
      </>
      )}
</>
  );
});
