import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Button, Grid, GridColumn, GridRow, Image } from 'semantic-ui-react';
import * as Yup from 'yup';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useParams } from 'react-router-dom';
import { dateFormatOptions } from '../../../api/options/selectOptions';
import LoadingComponent from '../../UICommon/LoadingComponent';
import { useStore } from '../../../api/stores/store';
import FormTextInput from '../../UIItems/FormTextInput';
import FormTimer from '../../UIItems/FormTimer';
import ActivateNotification from '../../UIItems/ActivateNotification';
import FormSelect from '../../UIItems/FormSelect';
import { ServerResult  } from '../../../api/models/common';
import ServerMsgBar from '../../UIItems/ServerMsgBar';
type Props = {
  hideBox: () => void;
};

export default observer(function CompanyForm(props: Props) {
  const { companyStore, globalStore } = useStore();
  const { activeUser } = globalStore;
  const {
    loadCompany,
    loadInitial,
    timeZoneOptions,
    loadTimeZoneOptions,
    userCompany,
  } = companyStore;
  const { id } = useParams<{ id: string }>();
  const [responseState, setResponseState] = useState<ServerResult  | undefined>(
    undefined
  );
  const isValidPhoneNumber = (value: any) => {
    if (!value) {
      return true;
    }
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
      const parsedNumber = phoneUtil.parse(value, undefined);
      return phoneUtil.isValidNumber(parsedNumber);
    } catch (e) {
      return false;
    }
  };
  const phoneValidation = Yup.string().test(
    'is-valid-phone',
    'International phone number is not valid',
    isValidPhoneNumber
  );
  function hideResponseState() {
    if (responseState) setResponseState(undefined);
  }
  useEffect(() => {
    if (id) loadCompany(id);
    else {
      loadCompany(activeUser!.companyId.toString());
    }
    if (timeZoneOptions === undefined) loadTimeZoneOptions();
  }, []);
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };
  if (loadInitial) return <LoadingComponent content="Loading Company.." />;
  return (
    <Formik
      initialValues={{
        idAuto: userCompany ? userCompany.idAuto : null,
        companyName: userCompany ? userCompany.companyName : '',
        address: userCompany ? userCompany.address : '',
        website: userCompany ? userCompany.website : '',
        email: userCompany ? userCompany.email : '',
        phoneNumber: userCompany ? userCompany.phoneNumber : '',
        sysLogIP: userCompany ? userCompany.sysLogIP : '',
        timeZone: userCompany ? userCompany.timeZone : '',
        dateFormat: userCompany ? userCompany.dateFormat : '',
        inactive: userCompany ? userCompany.inactive : 1,
        dailyReportTime: userCompany ? userCompany.dailyReportTime : '',
        error: null,
      }}
      onSubmit={(values, { setStatus }) =>
        companyStore
          .updateCompany(values)
          .then((res) => {
            var successResult = {
              isSuccess: true,
              msg: 'Company data was successfully changed ',
            };
            setResponseState(successResult);
          })
          .catch((error) => {
            var successResult = {
              isSuccess: false,
              msg: 'Error on Action:' + error,
            };
            setResponseState(successResult);
          })
      }
      validationSchema={Yup.object({
        companyName: Yup.string().required('Company Name is a required'),
        email: Yup.string().nullable().email(),
        sysLogIP: Yup.string()
          .nullable()
          .matches(/^([0-9]{1,3}\.){3}[0-9]{1,3}$/, 'IP is incorrect '),
        website: Yup.string()
          .nullable()
          .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'URL is incorrect'
          ),
        phoneNumber: phoneValidation,
        dailyReportTime: Yup.string()
          .nullable()
          .when('inactive', {
            is: 1,
            then: Yup.string() .nullable()
              .required(
                'Timing  Daily Report Is Required When Notification On! '
              ),
          }),
      })}
    >
      {({
        errors,
        isSubmitting,
        handleSubmit,
        setFieldValue,
        isValid,
        status,
        values,
      }) => {
        return (
          <Form
            onKeyDown={handleKeyDown}
            className="ui form error internalForm"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Grid onClick={hideResponseState}>
              <GridRow className="dataHeader">
                <GridColumn>
                  <span className="formSubTitle">
                    <strong>{values.companyName}</strong>
                  </span>
                  <br />
                  <span className="dataSubTitle">
                    <strong>CustomerCode: {values.idAuto}</strong>
                  </span>
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width={5}>
                  <Image
                    src="/img/source_environment.svg"
                    className="formSubTitleImg"
                  />
                  <span className="formSubTitle">General info</span>
                </GridColumn>
                <GridColumn width={5}>
                  <Image
                    src="/img/account_circle.svg"
                    className="formSubTitleImg"
                  />
                  <span className="formSubTitle"> Contact Info</span>
                </GridColumn>
                <GridColumn width={5}>
                  <Image
                    src="/img/perm_contact_calendar.svg"
                    className="formSubTitleImg"
                  />
                  <span className="formSubTitle"> Advanced Settings</span>
                </GridColumn>
              </GridRow>

              <GridRow className="formFieldRow">
                <GridColumn width={5}>
                  <FormTextInput
                    name="companyName"
                    placeholder="Company Name"
                    title="Company Name"
                    required
                  />
                </GridColumn>
                <GridColumn width={5}>
                  <FormTextInput
                    name="email"
                    placeholder="Email"
                    title="Email"
                  />
                </GridColumn>
                <GridColumn width={5}>
                  <FormSelect
                    label="Time Zone"
                    options={timeZoneOptions}
                    placeholder="Time Zone"
                    name="timeZone"
                    required
                  />
                </GridColumn>
              </GridRow>
              <GridRow className="formFieldRow">
                <GridColumn width={5}>
                  <FormTextInput
                    name="address"
                    placeholder="Address"
                    title="Address"
                  />
                </GridColumn>
                <GridColumn width={5}>
                  <FormTextInput
                    name="phoneNumber"
                    placeholder="Phone Number"
                    title="Phone Number"
                  />
                </GridColumn>
                <GridColumn width={5}>
                  <FormSelect
                    label="Date Format"
                    options={dateFormatOptions}
                    placeholder="Date Format"
                    name="dateFormat"
                  />
                </GridColumn>
              </GridRow>
              <GridRow className="formFieldRow">
                <GridColumn width={5}>
                  <FormTextInput
                    name="website"
                    placeholder="Web Site"
                    title="Web Site"
                  />
                </GridColumn>
                <GridColumn width={5}></GridColumn>
                <GridColumn width={5}>
                  <FormTimer
                    name="dailyReportTime"
                    placeholder="Timing  Daily Report"
                    label="Timing Daily Report"
                  ></FormTimer>
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width={5}></GridColumn>
                <GridColumn width={5}></GridColumn>
                <GridColumn width={5}>
                  <FormTextInput
                    name="sysLogIP"
                    placeholder="SIEM/SOC Server IP"
                    title="SIEM/SOC Server IP"
                  />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width={5}></GridColumn>
                <GridColumn width={5}></GridColumn>
                <GridColumn width={5}>
                  <ActivateNotification
                    name="inactive"
                    label="Activate Notification Mechanism"
                  ></ActivateNotification>
                </GridColumn>
              </GridRow>

              <GridRow>
                <GridColumn width={4}></GridColumn>
                <GridColumn width={6}>
                  {responseState && (
                    <ServerMsgBar runActionResult={responseState} />
                  )}
                </GridColumn>
                <GridColumn width={6}></GridColumn>
              </GridRow>
            </Grid>
            <Button
              disabled={!isValid || isSubmitting}
              loading={isSubmitting}
              content="   Update   "
              type="submit"
              className="applyBtn tabFormBtn"
            />
          </Form>

          //
        );
      }}
    </Formik>
  );
});
