import { observer } from 'mobx-react-lite';
import React from 'react';
import {
  Button,
  Grid,
  GridColumn,
  GridRow,
   Label,
} from 'semantic-ui-react';
import { useStore } from '../../../api/stores/store';
import { Form, Formik } from 'formik';
import FormTextInput from '../../UIItems/FormTextInput';

export default observer(function GenerateSN() {
  const { deviceStore } = useStore();
  var successMessage = 'A confirmation email has been sent';

  return (
    <Formik
      initialValues={{ numNewSN: 1 }}
      onSubmit={(values, { setStatus }) => {
        deviceStore
          .confirmNewSN(values.numNewSN)
          .then(() => setStatus({ sent: true, msg: successMessage }))
          .catch((error) => setStatus({ sent: false, msg: error }));
      }}
    >
      {({ handleSubmit, status }) => (
        <Form className="ui form" onSubmit={handleSubmit} autoComplete="off">
          <Label color="grey" pointing="below" size="large">
            Specify the number of serial numbers that will be generated
          </Label>
            <Grid className='generateSNSegment'>
              <GridRow>
                <GridColumn width={4}>
                          <FormTextInput name="numNewSN" placeholder="Amount SNs" />
                </GridColumn>
                <GridColumn width={3}>
                          <Button icon
                              content={"Generate"}
                              type="submit"
                              className="applyBtn">
                           </Button>
                </GridColumn>
                <GridColumn width={5}>
                   {status && status.msg && (
                            <div className={`responseMessage ${
                            status.sent ? 'success' : 'fail'
                             }`} >
                         <p> {status.msg} </p> 
                      </div>
                      )}                 
                  </GridColumn>  </GridRow>
            </Grid>
       </Form>
      )}
    </Formik>
  );
});
