import { useField } from 'formik';
import React from 'react';
import { Form, Input, Label } from 'semantic-ui-react';

interface LoginTextInputProperties {
    placeholder: string;
    name: string;
    title?: string;
    type?:string;
    icon?:string;
    iconPosition?:'left';
    label?: string;
    fluid?:boolean;
    labelPointing?: string;
    required?:boolean;
}

export default function LoginTextInput(props: LoginTextInputProperties) {
    const [field, meta ] = useField(props.name); 
    const cssClass ='loginField '+ (meta.error ? ' error ':'') + (props.required ? ' required' : '') ;

    return (
        <Form.Field error={meta.touched && !!meta.error} >
            <label className={`startPageTabLabel ${props.required ? 'required' : ''  }`} >{props.title}</label>
            <Input    {...field} {...props}  className={cssClass} />
            
            {props.labelPointing  ? (
                <Label pointing size='mini' className='startPageTabLabel' >{props.labelPointing }</Label>
            ) : null}
            {meta.touched && meta.error ? (
                <Label basic className='loginErrorLbl'>{meta.error}</Label>
            ) : null}
            
        </Form.Field>
    )
} 
