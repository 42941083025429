import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import {
  Button,
  Checkbox,
  Dropdown,
  Grid,
  GridRow,
  Icon,
  Label,
  PaginationProps,
  Table,
  Image,
  GridColumn,
} from 'semantic-ui-react';
import { useStore } from '../../../api/stores/store';
import FormPaging from '../../UIItems/FormPaging';
import { PagingParams } from '../../../api/models/pagination';
import 'react-datepicker/dist/react-datepicker.css';
import DevicesFilter from '../Admin/DevicesFilter';
import { FilterState, ServerResult } from '../../../api/models/common';
import AdminDeviceForm from './AdminDeviceForm';
import ServerMsgBar from '../../UIItems/ServerMsgBar';
import { CRUType } from '../../../api/models/device';

export default observer(function PreDeliveryList() {
  const { deviceStore, globalStore,filterStore } = useStore();
  const { listToDownload, setlistToDownload } = globalStore;
  const {
    devices,
    loadDevice,
    loadDevices,
    setPagingParams,
    adminApplySort,
    devicePagination,
    sortAdapter,
    deviceSortingParams,
    filterSet,CRUTypes,loadCRUTypes
  } = deviceStore;
  const [loadingNext, setLoadingNext] = useState(false);
  const [prodType, setProdType] = useState(1);
  const [prodName, setProdName] = useState('CRU-512');
  const [printState, setPrintState] = useState(false);
  const [printIdAuto, setPrintIdAuto] = useState('');
  const [showUpdateDevice, setShowUpdateDevice] = useState(false);
  const [showDevicesFilter, setShowDevicesFilter] = useState(false);
  const refFilterBox = useRef<HTMLDivElement>(null);
  const [state, dispatch] = React.useReducer(sortAdapter, {
    column: null,
    direction: null,
  });
  const { column, direction } = state;
  const [filterState, setFilterState] = useState<FilterState>({
    frequencyList: [],
    statusList: [],
    searchTxt: '',
    fromDate: null,
    toDate: null,
    companyId:undefined,
    deviceId:undefined
  });
  const [responseState, setResponseState] = useState<ServerResult | undefined>(
    undefined
  );
  let headers = [
    { label: '', key: 'catalogNumber' },
    { label: '', key: 'deviceSN' },
  ];

  function handlSelectDevice(id: number) {
    loadDevice(id.toString());
  }
  const updateDevice = (id: string) => {
    loadDevice(id);
    setShowUpdateDevice(true);
  };
  function changeDownloadList(deviceSN: string) {
    let newArr = [...listToDownload];
    let arrLength = listToDownload.length;
    let index = listToDownload.findIndex((obj) => obj.deviceSN === deviceSN);
    if (index > -1) {
      newArr.splice(index, 1);
    } else {
      if (arrLength === 1 && listToDownload[0].deviceSN === '') {
        newArr.splice(index, 1);
      }
      newArr.push({
        catalogNumber: 'Cyber Recovery Unit 512GB',
        deviceSN: deviceSN,
      });
    }
    setlistToDownload(newArr);
  }

  function checkedSN(deviceSN: string) {
    let index = listToDownload.findIndex((obj) => obj.deviceSN === deviceSN);
    if (index > -1) return true;
    else return false;
  }

  function setListPublished() {
    var listSN = listToDownload.map(function (a) {
      return a.deviceSN;
    });
    deviceStore.setPublished(listSN);
  }

  function sendPrint(deviceId: string) {
    setPrintIdAuto(deviceId);
    deviceStore
      .setForPrint(deviceId, prodType)
      .then((res) => {
        setPrintState(true);
      })
      .catch((error) => setPrintState(false));
  }

  function runPaging(
    event: React.MouseEvent<HTMLAnchorElement>,
    data: PaginationProps
  ) {
    var activePage: number = +data.activePage!;
    setLoadingNext(true);
    setPagingParams(new PagingParams(activePage));
    loadDevices().then(() => setLoadingNext(false));
  }

  function setType(selectedType: CRUType) {
    console.log("cruType : " + selectedType.id);
    setProdType(selectedType.id);
    setProdName(selectedType.name);
  }
  function showHideFilter() {
    setShowDevicesFilter(!showDevicesFilter);
  }
  function useOnClickOutside(
    ref: React.RefObject<HTMLElement>,
    handler: (event: MouseEvent) => void
  ) {
    useEffect(() => {
      const handleClick = (event: MouseEvent) => {
        if (!ref.current || ref.current.contains(event.target as Node)) {
          return;
        }
        handler(event);
      };

      document.addEventListener('mousedown', handleClick);
      return;
    }, [ref, handler]);
  }
  useOnClickOutside(refFilterBox, showHideFilter);
  function setResponseStateReceived(responseState: ServerResult | null) {
    if (responseState) setResponseState(responseState);
  }

  function hideResponseState() {
    if (responseState) setResponseState(undefined);
  }
  useEffect(() => {
    loadCRUTypes();
    if (filterSet && filterSet.size > 0) filterStore.displayFilter(filterSet,setFilterState);
  }, [filterSet]);

  return (
    <Grid columns={2} onClick={hideResponseState}>
      <GridRow>
        <GridColumn width={10}></GridColumn>
        <GridColumn width={6} className="mainTable">
          <div className="filterBox admin" onClick={showHideFilter}>
          <Button
                type="button"
                className={`filterButton ${ filterSet.size !== 0 ? 'filterActivated' : ''  }`}>
                <span>
                  <Image src="/img/tune.svg"  /> Filters
                </span>
            </Button>
          </div>
        </GridColumn>
        <GridColumn width={16}>
          <Table stackable sortable style={{ display: 'block' }}>
            <Table.Header className="TableHeader">
              <Table.Row>
                <Table.HeaderCell
                  className="firstColumn anchor"
                  sorted={
                    deviceSortingParams.sortField === 'deviceSN'
                      ? deviceSortingParams.sortAscending === 'true'
                        ? 'ascending'
                        : 'descending'
                      : undefined
                  }
                  onClick={() => adminApplySort('deviceSN')}
                >
                  Device SN
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={
                    deviceSortingParams.sortField === 'statusName'
                      ? deviceSortingParams.sortAscending === 'true'
                        ? 'ascending'
                        : 'descending'
                      : undefined
                  }
                  onClick={() => adminApplySort('statusName')}
                >
                  Status
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={
                    deviceSortingParams.sortField === 'publishedDate'
                      ? deviceSortingParams.sortAscending === 'true'
                        ? 'ascending'
                        : 'descending'
                      : undefined
                  }
                  onClick={() => adminApplySort('publishedDate')}
                >
                  Published
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={
                    deviceSortingParams.sortField === 'companyName'
                      ? deviceSortingParams.sortAscending === 'true'
                        ? 'ascending'
                        : 'descending'
                      : undefined
                  }
                  onClick={() => adminApplySort('companyName')}
                >
                  Company
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center" collapsing className='thAdminTbl nonSortable'>
                  <CSVLink
                    data={listToDownload}
                    headers={headers}
                    filename={'listNewSNNumbers.csv'}
                    target="_blank"
                    asyncOnClick={true}
                    onClick={setListPublished}
                  >
                    <Image src="/img/docs.svg" className="excelImg" />
                  </CSVLink>
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width="3" collapsing className='thAdminTbl nonSortable' >
                  <Dropdown text="Type">
                    <Dropdown.Menu>
                    {CRUTypes.map((option) => (
                            <Dropdown.Item
                            value={option.id}
                            text={option.name}
                            description={option.description}
                            onClick={() => setType(option)}
                          />
                          ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  {prodName}
                </Table.HeaderCell>
                <Table.HeaderCell className="lastColumn nonSortable"></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {devices.map((device: any) => (
                <Table.Row key={device.idAuto} className="tableRow">
                  <Table.Cell
                    width={2}
                    onClick={() => handlSelectDevice(device.idAuto)}
                  >
                    <Button
                      animated="fade"
                      size="mini"
                      compact
                      onClick={() => handlSelectDevice(device.idAuto)}
                      className="SNBox"
                    >
                      <Button.Content visible>
                        {' '}
                        {device.deviceSN}
                      </Button.Content>
                      <Button.Content hidden> {device.thisSN}</Button.Content>
                    </Button>
                  </Table.Cell>
                  <Table.Cell
                    width={3}
                    onClick={() => handlSelectDevice(device.idAuto)}
                  >
                    {device.statusName}{' '}
                  </Table.Cell>
                  <Table.Cell
                    width={3}
                    onClick={() => handlSelectDevice(device.idAuto)}
                  >
                    {device.publishedDate}
                  </Table.Cell>
                  <Table.Cell
                    width={4}
                    onClick={() => handlSelectDevice(device.idAuto)}
                  >
                    {device.companyName}
                  </Table.Cell>
                  <Table.Cell width={1} textAlign="center">
                    <Checkbox
                      checked={checkedSN(device.thisSN)}
                      onClick={() => changeDownloadList(device.thisSN)}
                    />
                  </Table.Cell>
                  <Table.Cell
                    width={2}
                    textAlign="center"
                    onClick={() => sendPrint(device.idAuto)}
                    className="clearFilter"
                  >
                    {printIdAuto === device.idAuto && printState ? (
                      <Label basic className='payAttention' ribbon="right">
                        Send to Print
                      </Label>
                    ) : (
                      <></>
                    )}
                    <Icon name="print" />
                  </Table.Cell>
                  <Table.Cell style={{ width: '19px' }}>
                          <Image
                            src="/img/edit.svg"
                            className="hidenIcon"
                            onClick={() => updateDevice(device.idAuto)}
                          />
                    </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <FormPaging
            currentPage={devicePagination!.currentPage}
            totalPages={devicePagination!.totalPages}
            onPageChange={runPaging}
          />
        </GridColumn>

        {showDevicesFilter && (
          <div ref={refFilterBox}>
           <DevicesFilter
                hideFilter={showHideFilter}
                filtersState={filterState}
                setFiltersState={setFilterState}
              />
          </div>
        )}
        {showUpdateDevice && (
          <AdminDeviceForm
            hideBox={() => setShowUpdateDevice(false)}
            setResponseState={setResponseStateReceived}
          />
        )}
      </GridRow>
      {responseState && <ServerMsgBar runActionResult={responseState} />}
    </Grid>
  );
});
