import { ApprovePasswordValues, ResetPasswordValues, User, UserFormValues, UserProfile } from "../models/userProfile";
import { makeAutoObservable ,runInAction} from "mobx";
import agent from "../agent";
import { store } from "./store";
import { RegestrySet } from "../models/regestrySet";
import { TabProps } from "semantic-ui-react";

export default class AccountStore{
    activeUser: User | null = null;
    resetPassword:boolean = false;
    activeTabIndex:string | number | undefined = 0;
    menuActiveItem :string |undefined = undefined;
    constructor() {
        makeAutoObservable(this)
    }

    setActiveTabIndex = (tabProps: TabProps|undefined, index:number|undefined) => {
        if (index)
            this.activeTabIndex = index;
        if (tabProps)
            this.activeTabIndex = tabProps!.activeIndex;
    }
    login = async (creds: UserFormValues) => {
        try {
            const user = await agent.Account.login(creds);
            store.globalStore.setToken(user.token);
            store.globalStore.setActiveUser(user);
      runInAction(() => {
        this.activeUser = user;
        if (user.listCompanies) {
           const clientCompanies = user.listCompanies.filter(
             (company) => company.companyName !== "Salvador Partners"
           );
          store.globalStore.setCompanyList(clientCompanies);
                }
            })

         
        } catch (error) {
            throw error;
        }
    }
  setMenuActiveItem =(menuItem : string ) =>{
    this.menuActiveItem = menuItem;
  }
    setResetPassword =(state:boolean) =>{


        
        this.resetPassword = state;
    }
    sendResetPassword = async(creds:ResetPasswordValues) =>{
        try {
             await agent.Account.resetPassword(creds.email);
        } catch (error) {
            throw error;
        }
    }

    approvePassword =  async(creds:ApprovePasswordValues) =>{
        try {
            const user =  await agent.Account.approvePassword(creds);
            store.globalStore.setToken(user.token);
            store.globalStore.setActiveUser(user);
            runInAction(()=>{
                window.localStorage.setItem('logU', JSON.stringify(user));
                this.activeUser = user
                if (user.listCompanies.length > 0)
                {
                    store.globalStore.setCompanyList (user.listCompanies);
                    window.localStorage.setItem('compL', JSON.stringify(user.listCompanies));

                }
            })
        } catch (error) {
            throw error;
        }
    }
   
    approveEmail =  async(creds:ApprovePasswordValues) =>{
        try {
            await agent.Account.approveEmail(creds);
        } catch (error) {
            throw error;
        }
    }

    logout = () => {
        store.globalStore.setToken(null);
        store.globalStore.setActiveUser(null);
        this.activeUser = null;
        window.localStorage.removeItem('jwt');
        window.localStorage.removeItem('compL');
        window.localStorage.removeItem('logU');
    }

    getUser = async () => {
        try {
            const user = await agent.Account.current();
            runInAction(() => this.activeUser = user);
        } catch (error) {
            window.localStorage.removeItem('jwt');
            window.localStorage.removeItem('compL');
            window.localStorage.removeItem('logU');
        }
    }

    register = async (creds: UserProfile) => {
        try {
            creds.id = '';
            await agent.Account.register(creds);
        } catch (error) {
            creds.id = '';
            throw error;
        }
    }
    registerSet = async (creds: RegestrySet ) => {
        try {
            await agent.Account.registerSet(creds);
        } catch (error) {
            throw error;
        }
    }

} 

