import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import {
  Button,
  CheckboxProps,
  FormField,
  Header,
  Icon,
  Label,
  Radio,
  Segment,
} from 'semantic-ui-react';
import FormTextInput from '../../UIItems/FormTextInput';
import { useStore } from '../../../api/stores/store';
import * as Yup from 'yup';
import { uploadResDTO } from '../../../api/models/pagination';

export default observer(function UploadRes() {
  const { resourcesStore } = useStore();
  const formData = new FormData();
  const { selectedResource,activeResources,loadActiveResources, setSelectedResource,} = resourcesStore;

  useEffect(() => {
    if (selectedResource === undefined) loadActiveResources();
  }, []);

  const [uploadRes, setUploadRes] = useState({
    fileType: selectedResource ? selectedResource.fileType : '',
    fileVersion: selectedResource ? selectedResource.fileVersion : '',
    fileName: selectedResource ? selectedResource.fileName : '',
    insertDate: selectedResource ? selectedResource.insertDate : '',
    fileRsc: null,
  });

  return (
    <Formik
      initialValues={{
        fileType: uploadRes.fileType,
        fileVersion: uploadRes.fileVersion,
        insertDate: uploadRes.insertDate,
        activeVersion: uploadRes.fileVersion,
        fileName: '',
        file: null,
        error: null,
      }}
      onSubmit={(values, { setStatus }) => {
        if (values.file) {
          formData.append('file', values.file);
          formData.append(
            'fileType',
            values.fileType ? values.fileType.toString() : '1'
          );
          formData.append('fileVersion', values.fileVersion);
          formData.append('fileName', values.fileName);
          resourcesStore
            .uploadRes(formData)
            .then((_res) => {
              setStatus({
                sent: true,
                msg: 'File Successfully Uploaded',
              });
            })
            .catch((error) => {
              setStatus({
                sent: false,
                msg: error,
              });
            });
        } else
          setStatus({
            sent: false,
            msg: 'Not  selected file for Uploading',
          });
      }}
      validationSchema={Yup.object({
        file: Yup.mixed().required(),
      })}
    >
      {({ handleSubmit, setFieldValue, status, values, isValid }) => {
        //

        const hendlFileChange = function(
          event: React.ChangeEvent<HTMLInputElement>
        ) {
          if (event.currentTarget.files)
            setFieldValue('file', event.currentTarget.files[0]);
        };
        const hendlTypeChange = function(
          event: React.FormEvent<HTMLInputElement>,
          data: CheckboxProps
        ) {
          switch (data.value) {
            case '1': {
              setSelectedResource(
                activeResources.find((i) => i.fileType === 1)!
              );
              break;
            }
            case '2': {
              setSelectedResource(
                activeResources.find((i) => i.fileType === 2)!
              );

              break;
            }
            case '3': {
              let newRes: uploadResDTO = {
                fileName: '',
                fileType: 3,
                fileUrl: '',
                fileVersion: '',
                insertDate: '',
                file: null,
              };
              setSelectedResource(newRes);
              break;
            }
            default: {
              setSelectedResource(
                activeResources.find((i) => i.fileType === 0)!
              );
              break;
            }
          }
        };
        return (
          <Form
            className="ui form error internalForm mainPageCard"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Segment raised compact>
              <Header
                as="h2"
                content="Upload Resources"
                className="SalvadorHeader1"
                textAlign="center"
              />
              <Label as="a" className="collorSet3" ribbon="right">
                LastVersion {values.activeVersion}
                <br></br>
                From {values.insertDate}
              </Label>

              <FormField>
                <Radio
                  label="Backup and Recovery Software"
                  name="radioGroup"
                  value="1"
                  checked={values.fileType.toString() === '1'}
                  onChange={hendlTypeChange}
                />
              </FormField>
              <FormField>
                <Radio
                  label="Installation Guide"
                  name="radioGroup"
                  value="2"
                  checked={values.fileType.toString() === '2'}
                  onChange={hendlTypeChange}
                />
              </FormField>
              <FormField>
                <Radio
                  label="Other Resource"
                  name="radioGroup"
                  value="3"
                  checked={values.fileType.toString() === '3'}
                  onChange={hendlTypeChange}
                />
              </FormField>
              {values.fileType !== '2' && (
                <FormTextInput name="fileVersion" placeholder="File Version" />
              )}
              {values.fileType === '3' && (
                <FormTextInput name="fileName" placeholder="File Display Name" />
              )}
              <input
                type="file"
                name="file"
                onChange={hendlFileChange}
                accept=".zip, .pdf "
                className="uploadType"
              />
              <Button
                disabled={!isValid}
                positive
                type="submit"
                fluid
                icon
                labelPosition="right"
                className="ActiveButton"
              >
                Upload
                <Icon name="upload" />
              </Button>

              {status && status.msg && (
                <p
                  className={`alert ${
                    status.sent ? 'alert-success' : 'alert-error'
                  }`}
                >
                  {status.msg}
                </p>
              )}
            </Segment>
          </Form>
        );
      }}
    </Formik>
  );
});
