import { useField } from 'formik';
import React from 'react';
import { Form, Label, Select  } from 'semantic-ui-react';

interface FormTimerProperties {
    placeholder: string;
    name: string;
    label?: string;
    required?:boolean;
}
export default function FormTimer(props: FormTimerProperties) {
    const [field, meta, helpers] = useField(props.name); 

    const generateHourOptions = (): { text: string; value: string }[] => {
        const hours: { text: string; value: string }[] = [];
        hours.push({ text: '', value: '' });
        for (let i = 0; i < 24; i++) {
            const hour = `${(i < 10 ? '0' : '') + i}:00 ${(i < 12 ? 'AM' : 'PM')}`;
            hours.push({ text: hour, value: hour });
        }
        return hours;
    };

    const hourOptions = generateHourOptions();
    const cssClass ='formSelectInput '+ (meta.error ? ' error ':'') + (props.required ? 'required' : '') ;
    return (
        <Form.Field error={meta.touched && !!meta.error} className={cssClass}>
            <label>{props.label}</label>
            <Select className='formTimerInput'
                options={hourOptions}
                value={field.value || ''}
                onChange={(e, d) => helpers.setValue(d.value)}
                onBlur={() => helpers.setTouched(true)}
                placeholder={props.placeholder}
                
            />
            {meta.touched && meta.error ? (
                <Label basic color='red'>{meta.error}</Label>
            ) : null}
        </Form.Field>
    )
} 
