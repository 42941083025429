import { observer } from 'mobx-react-lite';
import React from 'react';
import { Grid, GridRow  } from 'semantic-ui-react';
import DBAdmin from './DBAdmin';
import SysUpdate from './SysUpdate';


export default observer(function SiteAdminDashBoard() {
    
  return (

    <Grid columns={2} >
      <GridRow>
         <Grid.Column width={8}>
          <SysUpdate/>
         </Grid.Column>
         <Grid.Column width={8}>
         <DBAdmin/>
         </Grid.Column>
      </GridRow>
    </Grid>
  );
});