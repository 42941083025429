export interface Pagination {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  totalPages: number;
}
export interface forPrintDTO {
  idAuto: number;
  productType: number;
}
export interface uploadResDTO {
  fileType: number;
  fileName: string;
  fileUrl: string;
  fileVersion: string;
  insertDate: string;
  // activeVersion: string;
  file: File | null;
}
export class PaginatedResult<T> {
  data: T;
  pagination: Pagination;

  constructor(data: T, pagination: Pagination) {
    this.data = data;
    this.pagination = pagination;
  }
}

export class PagingParams {
  pageNumber;
  pageSize;
  constructor(pageNumber = 1, pageSize = 10) {
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
  }
}
export class SortingParams {
  sortField: string;
  sortAscending: string;
  constructor(sortField = '', sortAscending = 'false') {
    this.sortField = sortField;
    this.sortAscending = sortAscending;
  }
}
