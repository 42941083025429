import { makeAutoObservable } from "mobx";
import { FilterState } from "../models/common";
import { CRUTexts } from "../models/device";
import agent from "../agent";
import {  FORMAL_DAY_FORMAT, MIN_LENGTH_FOR_SEARCH  } from "../options/constants";
import { parse } from 'date-fns';
import { store } from "./store";
import { CruTextOptions, StatusOptionNames, StatusOptions } from "../models/enums";
import { string } from "yup";

export default class FilterStore   {
    cruTexts: CRUTexts[] = [];
    constructor() {
      makeAutoObservable(this)
  }
    handleFrequencyChanged  (frequency:string,filtersState: FilterState,setFiltersState: React.Dispatch<React.SetStateAction<FilterState>>)  {
        var frequencyList =  filtersState!.frequencyList?  filtersState!.frequencyList:[];
        const frequencIndex = frequencyList.indexOf(frequency);

        if (frequencIndex !== -1) {
          frequencyList.splice(frequencIndex, 1);
        } else {
          frequencyList.push(frequency);
        }
        setFiltersState!((prevState) => ({
          ...prevState,
          frequencyList: frequencyList,
        }));
  };

   handleStatusChanged  (status:number,filtersState: FilterState,setFiltersState: React.Dispatch<React.SetStateAction<FilterState>>) {
    var statusList: number[] =  filtersState.statusList?  filtersState.statusList:[];
    const statusIndex = statusList.indexOf(status);

    if (statusIndex !== -1) {
        statusList.splice(statusIndex, 1);
    } else {
          statusList.push(status);
    }
    setFiltersState((prevState) => ({
      ...prevState,
      statusList: statusList,
    }));
};

 handlFromDate( fromDate:Date|null,setFiltersState: React.Dispatch<React.SetStateAction<FilterState>>) {
    setFiltersState((prevState) => ({
      ...prevState,
      fromDate: fromDate,
    }));
  }
  handlToDate( toDate:Date|null,setFiltersState: React.Dispatch<React.SetStateAction<FilterState>>) {
    setFiltersState((prevState) => ({
      ...prevState,
      toDate: toDate,
    }));
  }

   handleSearchTxtChange (newText: string,setFiltersState: React.Dispatch<React.SetStateAction<FilterState>>)  {
    setFiltersState((prevState) => ({
      ...prevState,
      searchTxt: newText,
    }));
   };

    handlSelectedText(id: number, type: number, name:string,setFiltersState: React.Dispatch<React.SetStateAction<FilterState>>) {
    this.handleSearchTxtChange(name ,setFiltersState);
    if (type === CruTextOptions.CompanyName) {
      setFiltersState((prevState) => ({
          ...prevState,
          companyId: id,
        }));
        setFiltersState((prevState) => ({
          ...prevState,
          deviceId: undefined,
        }));
  } else {
    setFiltersState((prevState) => ({
      ...prevState,
      companyId: undefined,
    }));
    setFiltersState((prevState) => ({
      ...prevState,
      deviceId: id,
    }));
  }
  }

   loadCRUTexts = async (strParam: string) => {
    this.cruTexts = [];
    {
      const result = await agent.Devices.cruTexts(strParam);
      this.cruTexts = result.data;
    }
  };
   
  getFormatedDate(inputDate:Date )
  {
    var formatedDate:string = ''
    formatedDate = `${String(inputDate.getDate()).padStart( 2, '0')}/${String(inputDate.getMonth() + 1).padStart(2, '0')}/${String(inputDate.getFullYear())}`;
    return formatedDate; 
  }

  searchByText(newValue:string, 
      showSearchTextBox :React.Dispatch<React.SetStateAction<boolean>>,
      setFiltersState: React.Dispatch<React.SetStateAction<FilterState>>) {
      if (newValue.length >= MIN_LENGTH_FOR_SEARCH) {
            this.handleSearchTxtChange(newValue,setFiltersState);
            this.loadCRUTexts(newValue);
            showSearchTextBox(true);
      } else {
            showSearchTextBox(false);
      }
}
  
  clearFilter(setFiltersState: React.Dispatch<React.SetStateAction<FilterState>>) {
     setFiltersState((prevState) => ({
      ...prevState,
      statusList: [],
    }));
    setFiltersState((prevState) => ({
      ...prevState,
      frequencyList: [],
    }));
    setFiltersState((prevState) => ({
      ...prevState,
      searchTxt: '',
    }));
    setFiltersState((prevState) => ({
      ...prevState,
      fromDate: null,
    }));
    setFiltersState((prevState) => ({
      ...prevState,
      toDate: null,
    }));
    setFiltersState((prevState) => ({
      ...prevState,
      companyId: undefined,
    }));
    setFiltersState((prevState) => ({
      ...prevState,
      deviceId: undefined,
    }));
  }

   displayFilter(   filterSet: Map<any, any>,setFiltersState: React.Dispatch<React.SetStateAction<FilterState>>) {
    filterSet.forEach((value, key) => {
     if (key === 'frequency') {
         var frequencyList: string[]=[]
         frequencyList.push(value);
         setFiltersState((prevState) => ({
           ...prevState,
           frequencyList: frequencyList,
         }));
      }
     if (key === 'statusList') {
       var statusList: number[]  =[];
       statusList.push(value);
       setFiltersState((prevState) => ({
         ...prevState,
         statusList: statusList,
       }));
     }
     if (key === 'fromDate') {
         setFiltersState((prevState) => ({
           ...prevState,
           fromDate: parse(value, FORMAL_DAY_FORMAT, new Date()),
         }));
     }
     if (key === 'toDate') {
           setFiltersState((prevState) => ({
           ...prevState,
           toDate: parse(value, FORMAL_DAY_FORMAT, new Date()),
         }));
     }
     if (key === 'searchTxt') {
          setFiltersState((prevState) => ({
           ...prevState,
           searchTxt: value,
         }));
       }
    })
   }

    getStatusOptionName(deviceStatus: StatusOptions)
    {
      const optionName = Object.keys(StatusOptions).find(key => StatusOptions[key as keyof typeof StatusOptions] === deviceStatus);     
      return optionName;
    }

    
    
} 


