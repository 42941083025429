export const adminLevelOptions = [
    {text: 'User', value: 21},
    {text: 'SiteAdministrator', value: 20},
    {text: 'Partner', value: 10},
    {text: 'SystemAdmin', value: 2},
    {text: 'SuperAdmin', value: 1}
] 

export const userLevelOptions = [
    {text: 'User', value: 21},
    {text: 'SiteAdministrator', value: 20}
] 
 
export const statusOptions= [
    {text: 'Backup Succeeded', value: 1},
    {text: 'Warning', value: 2},
    {text: 'Critical Error', value: 3},
    {text: 'Registered', value: 4},
    {text: 'Published', value: 5},
    {text: 'Uninstalled', value: 6},
    {text: 'GeneratedSN', value: 7},
    {text: 'Registration Failed', value: 9},
    {text: 'No Backup Occurred', value: 11},
]

export const frequencyOptions = [
    {text: 'Daily', value: '1'},
    {text: '2 Day', value: '2'},
    {text: 'Weekly', value: '7'}
] 
export const dateFormatOptions = [
    {text: 'YYYY-MM-DD', value: 'yyyy-MM-dd'},
    {text: 'MM/DD/YYYY', value:  'MM/dd/yyyy'},
    {text: 'DD.MM.YYYY', value: 'dd.MM.yyyy'},
    {text: 'DD MMM YYYY', value: 'dd MMM yyyy'}
   
] 


