import { observer } from 'mobx-react-lite';
import React from 'react';
import MyTextInput from './MUIItems/MyTextInput';
import { Button,  Header,  Label } from 'semantic-ui-react';
import { useStore } from '../../api/stores/store';



interface Props {
  status:any;
  notActive:boolean;
}
export default observer(function MForgotPasswordPart(props: Props) {
  const {accountStore,} = useStore();
  const{setResetPassword} = accountStore;
  return (
    
               <>

               <Header>  Change Password</Header>
                    <Label>Please enter your email address.
                    We will send you an email to reset your password.</Label>
                    <MyTextInput name="email" placeholder="Email" icon='at' iconPosition='left'/>
                    {props.status && props.status.msg && (
                          <p className={`alert ${ props.status.sent ? "alert-success" : "alert-error"}`}>
                    {props.status.msg}
                   </p>
                   )}
                    <Button  disabled={props.notActive} content="Send Reset Password Link" type="submit" fluid className='collorSet3'  />
                   
                    <Label  as='a' basic color='blue' size='large' content='Cancel'
                       onClick={() =>  setResetPassword(false) } >
                  </Label>
               </>

      )});
   
