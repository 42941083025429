import {  Pagination } from 'semantic-ui-react';
import React from 'react';

interface FormPagingProperties {
  currentPage: number;
  totalPages: number;
  onPageChange :any;

}

export default function FormPaging(props: FormPagingProperties) {
  return (
    <Pagination
      boundaryRange={0}
      size='mini'
      defaultActivePage={props.currentPage}
      ellipsisItem={null}
      firstItem={null}
      lastItem={null}
      siblingRange={1}
      totalPages={props.totalPages}
      onPageChange={props.onPageChange}

    />
  );
}
