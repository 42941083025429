import { observer } from 'mobx-react-lite';
import React  from 'react';
import { ErrorMessage } from 'formik';
import { Button,   Label, Segment, TabProps } from 'semantic-ui-react';
import { useStore } from '../../api/stores/store';
import MyTextInput from './MUIItems/MyTextInput';

interface Props {
  errors:any;
}
export default observer(function MLoginPart(props: Props) {

  const { accountStore } = useStore();
  const{setResetPassword,setActiveTabIndex } = accountStore;
  function  gotoTabCompany() {
    let tabProps:TabProps |undefined = undefined;
    setActiveTabIndex(tabProps, 2);
  }
 
  return (
            
               <>
                  <MyTextInput name="email" placeholder="Email" icon='at' iconPosition='left'/>
                  
                  <MyTextInput name="password" placeholder="Password" type="password" icon='lock' iconPosition='left' />
                  <ErrorMessage  name="error" render={() => (
                        <Label  style={{ marginBottom: 10 }} basic  color="red" content={props.errors.error}  />)}
                  />
                  <Button  content="Login" type="submit" fluid className='collorSet3'/>
                
                  <Label  as='a' basic color='blue' size='large' content='Forgot Password?' 
                               onClick={() =>  setResetPassword(true) } >
                   </Label>

                 <Segment  piled   >  First Time User? <a className='activeLink' onClick={() => gotoTabCompany()}>Register your company</a></Segment>
              </>
      
      )});
   
