import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Button,  Form,  Grid,  GridColumn,  GridRow } from 'semantic-ui-react';
import { useStore } from '../../../api/stores/store';
import {Image} from 'semantic-ui-react';
import LoginTextInput from '../../UIItems/LoginTextInput';
import { Formik } from 'formik';
import ServerMsgBar from '../../UIItems/ServerMsgBar';
import { ServerResult } from '../../../api/models/common';



export default observer(function ForgotPasswordPart() {
  const {accountStore,} = useStore();
  const{setResetPassword} = accountStore;
  const [formResponseState, setFormResponseState] = useState< ServerResult | undefined>(undefined);
  return (
    <Formik
    initialValues={{ email: '', password: '', username:'',error: null }}
    onSubmit={(values,  { setErrors,setStatus}) =>{
  
              accountStore.sendResetPassword(values) 
              .then(res =>{
                var successResult = {
                  isSuccess: true,
                  msg: 'The Email Has Been Sent Successfully',
                };
                setFormResponseState(successResult);
              })
              .catch((error) =>  {
                var failResult = {
                  isSuccess: false,
                  msg: error,
                };
                setFormResponseState(failResult);
              })
             

    }}
  >
    {({ handleSubmit,  errors,status }) => (
      <Form   onSubmit={handleSubmit} autoComplete="off" className="vertical-form" >
        
    <Grid columns={1}  className='loginGrid justifyCenter' >
                 <GridRow>
                  <GridColumn width={2}>
                            <Image    src="/img/left.svg" className='mediumImg'   onClick={() =>  setResetPassword(false) }/>
                  </GridColumn>
                  <GridColumn width={12}>
                            <span className='loginGroupLabel justifyCenter'>Change Password</span>
                  </GridColumn>
  </GridRow>

    <GridRow  className='loginRow'>
    <Grid.Column style={{ height:'100%' }}>
          <LoginTextInput name="email" placeholder="Email"    title='Please enter your email address.
                  We will send you an email to reset your password' />
     </Grid.Column> </GridRow>
     <GridRow  className='loginRow'>
       <Grid.Column  style={{ width:'auto' }}>     
                    <Button  content="   Send Reset Password Link   " type="submit" fluid className='loginBtn'/>
       </Grid.Column> </GridRow>
       <GridRow>  
              {formResponseState && (
                  <ServerMsgBar runActionResult={formResponseState} loginForm />
              )}
         </GridRow>
      </Grid>

      </Form>
      )}
    </Formik>

      )});
   
