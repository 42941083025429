import { observer } from 'mobx-react-lite';
import { useStore } from '../../api/stores/store';
import _ from 'lodash';
import React, { useState } from 'react'
import {  Input,Table,Checkbox,  Grid,GridRow , Button, Icon, Label, CheckboxProps} from 'semantic-ui-react'

type SelectCompanyProperties = {
  runOkBtn: () => void;
  runCuncelBtn: () => void;
  setSelectedCompanyList: (companyList:number[]) => void;
  setSelectedCompanyId?: (companyId :number, companyName:string) => void;
  sourceCompanyList:number[];
  className?:string;
  showButtons?:boolean;
  oneselect?:boolean;
};

export default observer(function SelectCompany(props: SelectCompanyProperties) {
  const { globalStore} = useStore();
  const {companyList} = globalStore;
  const [searchByName, setSearchByName] = useState('');

  const [filteredCompanyList, setFilteredCompanyList] = useState<Array<{ key: number; text: string; value: number }>>
  (_.map(companyList, (company) => ({
    key: company.companyId,
    text: company.companyName,
    value:  company.companyId
  })));
  const [selectedCompanyList, setSelectedCompanyList] = useState(props.sourceCompanyList && props.sourceCompanyList.length ?
 [...props.sourceCompanyList]:[])

    function changeCompanyList(
      event: React.FormEvent<HTMLInputElement>,
      data: CheckboxProps,
      idAuto: number){
     let newArray = [...selectedCompanyList]; 
        if (data.checked === true)
        {
          newArray.push(idAuto)
        }
         else
        {
             let index = newArray.indexOf(idAuto)
             newArray.splice(index, 1);
        }
        setSelectedCompanyList(newArray);
        props.setSelectedCompanyList(newArray);
      }

       function  setSelectedCompanyId(companyId :number, companyName:string)
       {
        if (props.setSelectedCompanyId)
            props.setSelectedCompanyId(companyId,companyName);
       }
      function searchCompany(event: React.FormEvent<HTMLInputElement>)
       {
        let mainCompanyList= _.map(companyList, (company) => ({
          key: company.companyId,
          text: company.companyName,
          value:  company.companyId
        }))
        setSearchByName(event.currentTarget.value);
        let filterParam = event.currentTarget.value.toLowerCase();
        setFilteredCompanyList( mainCompanyList.filter(i => i.text.toLowerCase().startsWith (filterParam)));
       }

       function runReset()
       {
        let mainCompanyList= _.map(companyList, (company) => ({
          key: company.companyId,
          text: company.companyName,
          value:  company.companyId
        }))
         setFilteredCompanyList( mainCompanyList);
         setSearchByName('');
         setSelectedCompanyList([]);
         props.setSelectedCompanyList([]);
         props.runCuncelBtn();
       }
  return (
    
      <Grid className={props.className} columns={1} seled >
               <GridRow>
                        <Grid.Column >
                              <Input iconPosition='left' name='companyName'  
                                  className='searchText' onChange={searchCompany} value={searchByName} >
                                  <Icon name='search' />
                                  <input   />
                              </Input>
                        </Grid.Column>
                        <Grid.Column >
                          
                        </Grid.Column>
                 </GridRow>
                <GridRow >
                        <Grid.Column >  
                              <div className='scrolblTable'>
                                <Table basic='very'  collapsing compact singleLine unstackable >
                                        {filteredCompanyList!.map((company: any) => (
                                        <Table.Row key={company.key} onClick={()=>setSelectedCompanyId(company.key, company.text)}>
                                          {!props.oneselect && (
                                            <Table.Cell style ={{marginTop:'7px'}}>
                                                <Checkbox className='formCheckBox'  checked={ selectedCompanyList.indexOf( company.value) > -1} onClick={(event, data) => changeCompanyList(event, data, company.value)}/>
                                            </Table.Cell>
                                           )}
                                            <Table.Cell >
                                                    {company.text}
                                            </Table.Cell>
                                        </Table.Row>
                                        ))}
                                </Table>
                                </div>
                        </Grid.Column>
                </GridRow>
                {props.showButtons && 
                <GridRow >
                      <Grid.Column width={8} >  
                              <Label basic className='resetBtnS' onClick={runReset} >Reset</Label>
                       </Grid.Column>
                       <Grid.Column width={8}>
                       <Button  content="Apply"   type="button"  className='applyBtnS' onClick={props.runOkBtn}/>
                </Grid.Column>
                </GridRow>}
            </Grid>
   );
});

