import { useField } from 'formik';
import React  from 'react';
import { Dropdown, Form, Label } from 'semantic-ui-react';

interface MyDropDownProperties {
    placeholder: string;
    name: string;
    options: any;
    label?: string;
    defaultValue?:number;
    value?:number|string;
    required?:boolean;
    title?: string;
  }

export default function FormDropDown(props: MyDropDownProperties) {
    const [field,meta , helpers] = useField(props.name); 
    const cssClass ='formSelectInput '+ (meta.error ? ' error ':'') ;

    return (
        <Form.Field error={meta.touched && !!meta.error} className={cssClass}>
           <label className={props.required ? 'required' : ''} >{props.title}</label>
               <Dropdown  
                        options={props.options}
                        placeholder={props.placeholder}
                        selected={field.value || props.value}
                        onChange={(e, d) => helpers.setValue(d.value)}
                        onBlur={() => helpers.setTouched(true)}
                        icon='chevron down' 
                        value={props.value}
            />
            {meta.touched && meta.error ? (
                <Label basic className='errorLbl'>{meta.error}</Label>
            ) : null}
            
        </Form.Field>
    )

} 
