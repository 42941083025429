import { observer } from 'mobx-react-lite';
import React, { useEffect, useState }  from 'react'
import {   Grid,GridRow ,Label, Icon, Input, GridColumn, Button,Image} from 'semantic-ui-react'
import { useStore } from '../../../api/stores/store';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchTextBox from '../../UIItems/SearchTextBox';
import { ReportStatusOptions } from '../../../api/models/enums';
import { FORMAL_DAY_FORMAT } from '../../../api/options/constants';
import { FilterState } from '../../../api/models/common';

type ReportsFilterProperties = {
  hideFilter: () => void;
  filtersState: FilterState;
  setFiltersState: React.Dispatch<React.SetStateAction<FilterState>>;
};
  
export default observer(function LogsFilter(filterProps: ReportsFilterProperties) {
   
const { backupsStore,deviceStore,filterStore } = useStore();
const [showSearchCRU,setShowSearchCRU] = useState(false);
const {setOptionFilterItem,setFilterItem,resetSentFilter, loadBackups,reSetFilterItem} = backupsStore
const {selectedDevice,unselectDevice} = deviceStore
const {handleStatusChanged,handlFromDate,
          handlToDate,clearFilter,getFormatedDate} = filterStore;
const [inputValue, setInputValue] = useState('');

function onChangeSelectedText(id: number, type: number, name:string) {
      setInputValue(name);
      filterStore.handlSelectedText(id,type,name,filterProps.setFiltersState);
      setShowSearchCRU(false);
}

function applayFilter()
{
  resetSentFilter();
     if (filterProps.filtersState.statusList)
      {
        filterProps.filtersState.statusList.forEach((element) => {
              setOptionFilterItem('statusList',element.toString());
         });
      }
    if (filterProps.filtersState.fromDate) setFilterItem('fromDate', getFormatedDate(filterProps.filtersState.fromDate));
    if (filterProps.filtersState.toDate) setFilterItem('toDate',  getFormatedDate(filterProps.filtersState.toDate));
   
    if (filterProps.filtersState.searchTxt) 
        setFilterItem('searchTxt', filterProps.filtersState.searchTxt);
    if (filterProps.filtersState.companyId) 
          setFilterItem('companyId', filterProps.filtersState.companyId);
    if (filterProps.filtersState.deviceId) 
          setFilterItem('id', filterProps.filtersState.deviceId);    
    loadBackups();
    filterProps.hideFilter();  
}

function clearReportsFilter()
{
      clearFilter(filterProps.setFiltersState);
      if (selectedDevice )
      {
        unselectDevice();
      }
      resetSentFilter();
      loadBackups();
}
useEffect(() => {
  if (filterProps.filtersState.searchTxt)
       setInputValue(filterProps.filtersState.searchTxt)
 }, [])
 
  return ( 
      <Grid className='filterPanel' columns={2}  >
            <GridRow>
                <Grid.Column width={13}>
                  <div className="filterLeftMarker">
                    <Image src="/img/tune.svg" />
                    Filters
                  </div>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Image
                    src="/img/close.svg"
                    className="closeWindow"
                    onClick={()=>filterProps.hideFilter()}
                  />
                </Grid.Column>
              </GridRow>

              <GridRow>
              <Grid.Column width={16}>
                <Input
                  iconPosition="left"
                  name="searchImput"
                  className="searchText"
                  placeholder="Search for SN or computer name"
                  onChange={(event, data) => {
                    setInputValue(data.value);
                    filterStore. searchByText(data.value, setShowSearchCRU, filterProps.setFiltersState);
                  }}
                  value={inputValue}>
                  <Icon name="search" />
                  <input />
                </Input>
              </Grid.Column>
              {showSearchCRU && (
                <SearchTextBox handlSelectUnit={onChangeSelectedText} />
              )}
            </GridRow>
            <GridRow className="filterTitleRow">
                      <Grid.Column width={16}>
                        <span className="filtersTitle">By Status</span>
                      </Grid.Column>
             </GridRow>

              <GridRow>  
                    <Grid.Column  width={16} >
                    <Button
                        icon type="button"
                        className={
                        `filtersOption ${filterProps.filtersState.statusList!.indexOf(ReportStatusOptions.BackupStarted) !== -1  ? ' selected' : ''  }`}
                        onClick={()=>handleStatusChanged(ReportStatusOptions.BackupStarted, filterProps.filtersState ,filterProps.setFiltersState)} >
                        <Icon name="circle" className='statusBackupStarted' />
                        Backup Started
                  </Button>
                  <Button
                        icon type="button"
                        className={
                        `filtersOption ${filterProps.filtersState.statusList!.indexOf(ReportStatusOptions.RecoveryStarted) !== -1  ? ' selected' : ''  }`}
                        onClick={()=>handleStatusChanged(ReportStatusOptions.RecoveryStarted, filterProps.filtersState ,filterProps.setFiltersState)} >
                        <Icon name="circle" className='statusRecoveryStarted' />
                        Recovery Started
                  </Button>

                 </Grid.Column>
                </GridRow>
                <GridRow >  
                    <Grid.Column  width={16} >
                    <Button
                        icon type="button"
                        className={
                        `filtersOption ${filterProps.filtersState.statusList!.indexOf(ReportStatusOptions.BackupFinished) !== -1  ? ' selected' : ''  }`}
                        onClick={()=>handleStatusChanged(ReportStatusOptions.BackupFinished, filterProps.filtersState ,filterProps.setFiltersState)} >
                        <Icon name="circle" className='statusBackupFinished' />
                        Backup Finished
                  </Button>
                  <Button
                        icon type="button"
                        className={
                        `filtersOption ${filterProps.filtersState.statusList!.indexOf(ReportStatusOptions.RecoveryFinished) !== -1  ? ' selected' : ''  }`}
                        onClick={()=>handleStatusChanged(ReportStatusOptions.RecoveryFinished, filterProps.filtersState ,filterProps.setFiltersState)} >
                        <Icon name="circle" className='statusRecoveryFinished' />
                        Recovery Finished
                  </Button>
                  </Grid.Column>
                </GridRow>
                <GridRow >  
                    <Grid.Column  width={16} >
                    <Button
                        icon type="button"
                        className={
                        `filtersOption ${filterProps.filtersState.statusList!.indexOf(ReportStatusOptions.BackupCancelled) !== -1  ? ' selected' : ''  }`}
                        onClick={()=>handleStatusChanged(ReportStatusOptions.BackupCancelled, filterProps.filtersState ,filterProps.setFiltersState)} >
                        <Icon name="circle" className='statusBackupCancelled' />
                        Backup Cancelled
                  </Button>

                  <Button
                        icon type="button"
                        className={
                        `filtersOption ${filterProps.filtersState.statusList!.indexOf(ReportStatusOptions.RecoveryCancelled) !== -1  ? ' selected' : ''  }`}
                        onClick={()=>handleStatusChanged(ReportStatusOptions.RecoveryCancelled, filterProps.filtersState ,filterProps.setFiltersState)} >
                        <Icon name="circle" className='statusRecoveryCancelled' />
                        Recovery Cancelled
                  </Button>
                </Grid.Column>
                </GridRow>
            <GridRow className="filterTitleRow">
                      <Grid.Column width={16}>
                        <span className="filtersTitle">Report Date</span>
                      </Grid.Column>
             </GridRow>
             <GridRow > 
                  <GridColumn  width={8} > 
                          <Label basic className='filtersLabel'>From</Label>
                          <DatePicker className='calendarImput'
                             selected={filterProps.filtersState.fromDate}
                              onChange={(date) => handlFromDate(date,filterProps.setFiltersState)}
                              isClearable
                              dateFormat={FORMAL_DAY_FORMAT}
                              placeholderText={FORMAL_DAY_FORMAT.toUpperCase()}
                              selectsStart
                              startDate={filterProps.filtersState.fromDate}
                              endDate={filterProps.filtersState.toDate}
                              maxDate={filterProps.filtersState.toDate}
                         />
                   </GridColumn>
                   <GridColumn  width={8} > 
                          <Label basic className='filtersLabel'>To</Label>
                          <DatePicker className='calendarImput' 
                              selected={filterProps.filtersState.toDate} 
                              isClearable
                              dateFormat={FORMAL_DAY_FORMAT}   
                              onChange={(date) => handlToDate(date,filterProps.setFiltersState)}  
                              placeholderText={FORMAL_DAY_FORMAT.toUpperCase()}
                              selectsEnd
                              startDate={filterProps.filtersState.fromDate}
                              endDate={filterProps.filtersState.toDate}
                              minDate={filterProps.filtersState.fromDate}
                             />
                   </GridColumn>
                </GridRow>
                <GridRow className="buttonsRow">
                    <Grid.Column width={10}>
                        <Label basic className='resetBtn' onClick={clearReportsFilter} > Clear all</Label>
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <Button content="Apply" type="button"   onClick={() => { applayFilter(); }} className="applyBtn" />
                    </Grid.Column>
                  </GridRow>
            </Grid>

   );
});

