import { useField } from 'formik';
import React, { useRef, useState } from 'react';
import { Button,  Form,  Input,  Label, Image, Segment  } from 'semantic-ui-react';

interface VerificationBoxProperties {
    name: string;
    title:string;
    subTitle?:string;
    id:string;
    runOnApprove: (id :string, verificationCode:string) => void;
    hideVerificationBox: () => void;
}
export default function VerificationBox(props: VerificationBoxProperties) {
  const [field, meta] = useField(props.name); 
  const [verificationCode, setVerificationCode] = useState('');
  function  handleRunClick(verificationCode:string)
  {
    props.runOnApprove(props.id, verificationCode);
  }
  return (
    <Segment className='verificationBox'>
      <> <Image src="/img/close.svg" className="closeWindow"  onClick={()=>props.hideVerificationBox()}/>
   
      <Form.Field error={meta.touched && !!meta.error} >
         <label  >{props.title}</label>
         <label  >{props.subTitle}</label>
         <div className='verificationDiv'>
          <Input   name='verificationCode'  icon="lock"  type="password" 
                         iconPosition="left" autoComplete="new-password"
                         onChange={(event) => setVerificationCode(event.target.value)}  className='formTextInput error' />
        <Button  className='filterActivated' onClick={() => handleRunClick( verificationCode)} >Approve</Button></div>
           {!verificationCode? (
                <Label basic >Enter verification code</Label>
            ) : null}
      </Form.Field>   </>
      </Segment>
  )
     }