import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Button, Grid, GridColumn,  GridRow, Icon, Image,  Segment} from 'semantic-ui-react';
import FormTextInput from '../../UIItems/FormTextInput';
import { useStore } from '../../../api/stores/store';
import { ServerResult  } from '../../../api/models/common';
import ServerMsgBar from '../../UIItems/ServerMsgBar';
import * as Yup from 'yup';
import { MAX_INPUT_LENGTH } from '../../../api/options/constants';
type Props = {
  hideBox: () => void;
  setResponseState: (updateStatus: ServerResult |null) => void;
};
export default observer(function DeviceForm(props:Props) {
  const { deviceStore } = useStore();
  const {selectedDevice} = deviceStore;
  const [formResponseState, setFormResponseState] = useState<ServerResult  | undefined>(
    undefined
  );
  const handleCloseClick = () => {
    props.hideBox();
  };

  function  setResponseState(responseState :ServerResult )
  {
   if (props.setResponseState)
       props.setResponseState(responseState);
  }
   const handleKeyDown = (event: React.KeyboardEvent) => {
     if (event.key === 'Enter') {
       event.preventDefault();
     }
   };
   var msgInputLengthWarning = `Field should not exceed ${ MAX_INPUT_LENGTH } characters`;
  return (
    <Formik
      initialValues={{
        idAuto: selectedDevice!.idAuto,
        deviceSN: selectedDevice!.deviceSN,
        companyIDAuto: selectedDevice!.companyIDAuto,
        status: selectedDevice!.status,
        computerName: selectedDevice!.computerName,
        computerDescript: selectedDevice!.computerDescript,
        siteLocation: selectedDevice!.siteLocation,
        frequency: selectedDevice!.frequency,
        version: selectedDevice!.version,
        error: null,
      }}
      onSubmit={(values, { setStatus }) => {
        deviceStore
          .updateDevice(values)
          .then((res) => {
            var successResult = {
              isSuccess: true,
              msg: 'Device is successfully updated '
            };
            setResponseState (successResult);
           props.hideBox();

          })
          .catch((error) => {
            var failResult = {
              isSuccess:false,
              msg: 'Update device failed ' + error,
            };
            setFormResponseState(failResult);
          });
      }}
      validationSchema={Yup.object({
        computerDescript: Yup.string().nullable().max(MAX_INPUT_LENGTH, msgInputLengthWarning),
        computerName: Yup.string().nullable().max(MAX_INPUT_LENGTH, msgInputLengthWarning),
        siteLocation: Yup.string().nullable().max(MAX_INPUT_LENGTH, msgInputLengthWarning),
      })} >
      {({handleSubmit, status, }) => {
        return (

          <Segment raised compact className="addFormBox">
          <Form
            onKeyDown={handleKeyDown}
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Grid className="addForm">

              <GridRow style={{ padding: '14px' }}>
                      <GridColumn width={14}>
                              <span className="formSubTitle">Asset Definition</span>
                      </GridColumn>
                      <GridColumn width="2" floated="right">
                                <Image
                                  src="/img/close.svg"
                                  className="closeWindow"
                                  onClick={handleCloseClick}
                                />
                      </GridColumn>
            </GridRow>
            <br />
            <GridRow  className='assetTitle'>
                  <GridColumn >
                          <Icon name="hdd outline" />
                            {selectedDevice!.deviceSN}
                  </GridColumn>
            </GridRow>
             <GridRow className="formFieldRow">
                    <GridColumn>
                          <FormTextInput
                            title="Computer Name"
                            name="computerName"
                            placeholder="Computer Name"
                          />
                      </GridColumn>
               </GridRow>
               
              <GridRow className="formFieldRow">
                    <GridColumn>
                          <FormTextInput
                            title="Site Location"
                            name="siteLocation"
                            placeholder="Site Location"
                          />
                    </GridColumn>
              </GridRow>
              <GridRow className="formFieldRow">
                    <GridColumn className='areaInput'>
                          <FormTextInput
                            title="Description"
                            name="computerDescript"
                            placeholder=""
                          />
                    </GridColumn>
              </GridRow>
              <GridRow className="panelButtonsRow">
                <Grid.Column width={10}>
                  
                </Grid.Column>
                <Grid.Column width={5}>
                  <Button
                    content='Update'
                    type="submit"
                    className="applyBtn"
                  />
                </Grid.Column>
              </GridRow>
              <br />
              {formResponseState && (
                <ServerMsgBar runActionResult={formResponseState} />
              )}
        </Grid>
        </Form>
        </Segment>
        );
      }}
    </Formik>
  );
});
