import React from 'react';
import { Outlet } from "react-router-dom"
import { Container } from 'semantic-ui-react';
import MSideBar from './MSideBar';
import MNavBar from "./MNavBar"
import './m_styles.css';

export function MLayout () {
    return (
     <Container   >
         <MSideBar />
         <MNavBar />
         <Outlet />
      </Container>

    )
}


