import { observer } from 'mobx-react-lite';
import { useStore } from '../../api/stores/store';
import React, { useState } from 'react'
import {Image,  Menu, MenuItem, MenuMenu} from 'semantic-ui-react'
import { User } from '../../api/models/userProfile';
import { PermissionLevel } from '../../api/models/enums';
import { NavLink, useNavigate } from 'react-router-dom';




interface UserBoxProperties {
    activeUser:User;
    onClickHandler: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  }
  
export default observer(function UserBox(props: UserBoxProperties) {
      const { accountStore,globalStore} = useStore();
      const [menuActiveItem,setMenuActiveItem] = useState('');
      const {getIsAdmin } = globalStore;
      const isAdmin: boolean = getIsAdmin();
      const navigate = useNavigate();
      const handleLogOut = () => {
            accountStore.logout();
            navigate('/login');
       };
      function onClickTab(event:React.MouseEvent<HTMLAnchorElement>) {
             props.onClickHandler(event);
      }

  return (
        
<Menu vertical className={'techMenu' + (!isAdmin?' simpleTechMenu':'')}>
      <MenuMenu>
            <MenuItem className='techMenuItem'
                  name='profile' as={NavLink}  to='/sysTab/profile'
                  active={menuActiveItem === 'profile'}
                  onClick={(event) => onClickTab(event)} >
                  <Image className='smallImg'    src="/img/account_circle.svg" />
                  Profile
            </MenuItem>
            {(isAdmin ) && 
            <>
            <MenuItem  className='techMenuItem'
                  name='companyInfo' as={NavLink}  to="/sysTab/companyInfo"
                  active={menuActiveItem === 'companyInfo'}
                  onClick={(event) => onClickTab(event)} >
                  <Image   className='smallImg'  src="/img/info.svg" />
                  Company Info
            </MenuItem>
            {(((props.activeUser!.level === PermissionLevel.SuperAdmin || props.activeUser!.level === PermissionLevel.SystemAdmin) 
                && props.activeUser!.cloudVersion)||
              (props.activeUser!.level === PermissionLevel.SiteAdministrator && !props.activeUser!.cloudVersion)     
            ) && 
            <MenuItem  className='techMenuItem'
                  name='siteSettings' as={NavLink}  to="/sysTab/siteSettings"
                  active={menuActiveItem === 'siteSettings'}
                  onClick={(event) => onClickTab(event)} >
                  <Image   className='smallImg'  src="/img/settings.svg" />
                  Site Settings
            </MenuItem>}
            {(!props.activeUser!.cloudVersion && isAdmin) && 
            <MenuItem  className='techMenuItem'
                  name='systemUpdate' as={NavLink}  to="/sysTab/systemUpdate"
                  active={menuActiveItem === 'systemUpdate'}
                  onClick={(event) => onClickTab(event)} >
                  <Image   className='smallImg'  src="/img/autorenew.svg" />
                  System Update
            </MenuItem>}
            </>}
           
        </MenuMenu>
        <MenuMenu className='exitMenu'>
            <MenuItem  className='techMenuItem'
                  onClick={handleLogOut} >
                  <Image   className='smallImg'  src="/img/logout.svg" />
                  Sign out
            </MenuItem>
        </MenuMenu>
 </Menu>

   );
});

