import React from 'react';
import { ServerResult  } from '../../api/models/common';
interface ServerMsgBarProperties {
    runActionResult: ServerResult ;
    loginForm?:boolean;
}

export default function ServerMsgBar(props: ServerMsgBarProperties) {
  const cssClass = (props.loginForm ? ' loginResponseMessage ' : 'responseMessage ')  + (props.runActionResult.isSuccess ? ' success ' : 'fail')  ;
  return (

            <div className={cssClass } >
              <p> {props.runActionResult.msg} </p> 
          </div>            
  );
}
