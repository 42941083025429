import { makeAutoObservable,runInAction ,reaction} from "mobx";
import agent from "../agent";
import { PartnerDTO } from "../models/company";
import { UserProfile, UserRemovalRequest } from "../models/userProfile";
import { Pagination, PagingParams, SortingParams, } from '../models/pagination';




export default class UserProfileStore{

    userProfileRegestry = new Map<string,UserProfile>();
    listPartners:UserProfile[] |undefined=undefined;
    userProfiles:UserProfile[] =[];
    selectedUser : UserProfile |undefined =undefined;
    selectedPartner :boolean  =false;
    usersSortingParams = new   SortingParams();
    usersPagingParams = new PagingParams();
    usersPagination : Pagination  = {currentPage:1,itemsPerPage:10,totalItems:0,totalPages:0};
    loadinInitial=false;
    filterSet = new Map().set('status', 0);
    listSorted=false;
    constructor() {
        makeAutoObservable(this);

        reaction(
            () =>  this.filterSet,
            () => {
                   this.loadUserProfiles();
             }
        )
    }

     setCompanyFilter = ( value: any) => {
          this.filterSet.set('companyList', value);
      } 

     reSetFilterItem = (predicate: string) => {
        this.filterSet.delete(predicate);
    } 

    setPagingParams = (pagingParams : PagingParams) =>   {
        this.usersPagingParams = pagingParams;
    }

      get axiosParams () {

        const params = new URLSearchParams();
        params.append('pageNumber', this.usersPagingParams.pageNumber.toString());
        params.append('pageSize', this.usersPagingParams.pageSize.toString());
        params.append('orderField', this.usersSortingParams.sortField);
        params.append('orderAscending', this.usersSortingParams.sortAscending);
        this.filterSet.forEach((value, key) => {
          if (key === 'companyList' && value && Array.isArray(value)) {
            value.map((companyId: any) => params.append(key, companyId));
          } else {
            params.append(key, value);
          }
        });
        return params;
   }

    loadUserProfiles = async ()=>
    {
        this.userProfileRegestry.clear();
        this.loadinInitial = true;
        try{
            const result =  await agent.UserProfiles.list(this.axiosParams);
            runInAction(() =>{
                        this.setPagination(result.pagination);
                        this.userProfiles =  result.data;
                        this.loadinInitial = false;
              })
            this.userProfiles.forEach(user => {
                this.setUserProfile(user);  
            })
        }
        catch(error)
                { throw error;}
        finally{this.setLoadinInitial(false);}
    }
    setPagination = (pagination: Pagination) => {
        this.usersPagination = pagination;
    }
    
     get usersByCompany (){
        return Array.from(this.userProfileRegestry.values()).sort((a,b) =>
         a.companyId - b.companyId);
    }

    get groupedUsers() {
        return Object.entries(
            this.usersByCompany.reduce((userProfiles,user) => {
                const company = user.companyName;
                userProfiles[company] = userProfiles[company]? [...userProfiles[company],user] : [user];
                return userProfiles;
            },{} as {[key:string] : UserProfile[]})
        )
    }

    applySort = (sortField :string ) =>{
        if (this.usersSortingParams.sortField === sortField)
        {
            if(this.usersSortingParams.sortAscending === 'true')
                this.usersSortingParams.sortAscending = 'false';
            else
                this.usersSortingParams.sortAscending = 'true';
        }
        else
        {
            this.usersSortingParams.sortField = sortField;
            this.usersSortingParams.sortAscending = 'true' ;
        }
        this.loadUserProfiles();
        this.listSorted= true;
    }
  
    getUserProfile = async (id:string)=>
    {
        let user = this.userProfileRegestry.get(id);
        if (user){
                this.selectedUser  = user;
                return user;
            }
        else
        {
            this.loadinInitial = true;
            try{
                let user = await agent.UserProfiles.details(id);
                runInAction(() =>{
                   this.selectedUser = user;
                   this.userProfileRegestry.set(user.id,user) ; 
                })
                return user;
            } catch(error){
                throw error;
            }
            finally{
                this.setLoadinInitial(false);}
            }
    }

     setUserProfile = (user :UserProfile)=>{
        this.userProfileRegestry.set(user.id,user) ; 
        this.selectedUser = user;
    }
   
    unselectUser = () =>
    {
        this.selectedUser =undefined;
        this.selectedPartner =false;
    }

    setLoadinInitial =(state:boolean) =>{
        this.loadinInitial = state;
    }
 
    
    saveUserProfile = async (userProfile: UserProfile) =>
    {
       this.loadinInitial = true;
       try{
            if (userProfile.id.length === 0)
                    return agent.Account.register(userProfile);
            else
                    return agent.UserProfiles.update(userProfile);
          
        }catch(error){
            throw error;
        }
        finally{this.setLoadinInitial(false);}
    }

    updateUserProfile = async (userProfile: UserProfile) =>
    {
        this.loadinInitial = true;
        try{
                await agent.UserProfiles.update(userProfile);
                let user = await agent.UserProfiles.details(userProfile.id)
                this.userProfileRegestry.set(user.id,user) ; 
                this.selectedUser = user;
                this.setLoadinInitial(false);

                if (this.selectedPartner || this.listPartners !== undefined)
                {
                    this.listPartners =  Array.from(this.userProfileRegestry.values()).filter(a => a.level === 10) ;
                }
        }catch(error){throw error;}
        finally{  this.loadinInitial = false; }
   
    }


    deleteUserProfile = async (user: UserRemovalRequest) =>
    {
        this.loadinInitial = true;
        try{
            await agent.UserProfiles.delete(user);
            runInAction(()=>{
                this.userProfileRegestry.delete(user.id);
                this.selectedUser =undefined;
            })
        }catch(error){
            throw error;
        }
        finally
        {  this.loadinInitial = false; }
    }
    assignToPartner = async(partnerInfo:PartnerDTO)=>
    {
        this.loadinInitial = true;
        try{
            await agent.UserProfiles.assignToPartner(partnerInfo);
        }catch(error){
            throw error;
        }
        finally
        {  this.loadinInitial = false; }
    }

}


