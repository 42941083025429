import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Grid,
  GridColumn,
  GridRow,
  Image,
  PaginationProps,
  Segment,
  Table,
} from 'semantic-ui-react';
import FormPaging from '../../UIItems/FormPaging';
import LoadingComponent from '../../UICommon/LoadingComponent';
import { Backup } from '../../../api/models/backup';
import { PagingParams } from '../../../api/models/pagination';
import { useStore } from '../../../api/stores/store';
import LogsFilter from './LogsFilter';
import { FilterState } from '../../../api/models/common';
import { ReportStatusOptions, getStatusOptionDisplayText  } from '../../../api/models/enums';

export default observer(function BackupsList() {
  const { id } = useParams<{ id: string }>();
  const { backupsStore, deviceStore, accountStore,filterStore } = useStore();
  const { setMenuActiveItem } = accountStore;
  const {
    backups,
    loadBackups,
    setPagingParams,
    backupPagination,
    filterSet,
    setDeviceIdFilter,
    applySort,
    backupSortingParams,reSetFilterItem 
  } = backupsStore;
  const { loadDevice, selectedDevice } = deviceStore;
  const [showReportsFilter, setShowReportsFilter] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [filterState, setFilterState] = useState<FilterState>({
    frequencyList: [],
    statusList: [],
    searchTxt: '',
    fromDate: null,
    toDate: null,
    companyId:undefined,
    deviceId:undefined
  });
  const refFilterBox = useRef<HTMLDivElement>(null);

  function showHideFilter() {
    setShowReportsFilter(!showReportsFilter);
  }

  function useOnClickOutside(
    ref: React.RefObject<HTMLElement>,
    handler: (event: MouseEvent) => void
  ) {
    useEffect(() => {
      const handleClick = (event: MouseEvent) => {
        if (!ref.current || ref.current.contains(event.target as Node)) {
          return;
        }
        handler(event);
      };

      document.addEventListener('mousedown', handleClick);
      return;
    }, [ref, handler]);
  }
  useOnClickOutside(refFilterBox, showHideFilter);

  useEffect(() => {
    setMenuActiveItem('Logs & Reports');
    if (id && id !== '' && id !== '0') {
      setDeviceIdFilter(id);
    }
     else{
        reSetFilterItem('id')
     }
      loadBackups();
      loadDevice(id!);
     filterStore.displayFilter(filterSet,setFilterState);
  }, [id]);

  function runPaging(
    event: React.MouseEvent<HTMLAnchorElement>,
    data: PaginationProps
  ) {
    var activePage: number = +data.activePage!;
    setLoadingNext(true);
    setPagingParams(new PagingParams(activePage));
    loadBackups().then(() => setLoadingNext(false));
  }

  if (backupsStore.loadinInitialBackup && !loadingNext)
    return <LoadingComponent content="Loading App" />;

  return (
    <>
      <Grid className="mainPageGrid">
        <GridRow>
          <GridColumn width={14} className="pageTitleColumn">
            <span className="pageTitle">Logs & Reports</span>
          </GridColumn>
          <GridColumn width={2} className="pageTitleColumn">
            <div className="filterBox" onClick={showHideFilter}>
            <Button
                type="button"
                className={`filterButton ${ filterSet.size !== 0 ? 'filterActivated' : ''  }`}>
                <span>
                  <Image src="/img/tune.svg"  /> Filters
                </span>
            </Button>
            </div>
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn className="mainTable">
            {selectedDevice && (
              <Segment raised compact>
                <span className="pageSubTitle">
                  {'For Asset: ' + selectedDevice!.deviceSN}{' '}
                </span>
                Company: <strong>{selectedDevice!.companyName}</strong> ;
                Frequency :{' '}
                <strong>
                  {' '}
                  {selectedDevice!.frequency === '1'
                    ? 'Daily'
                    : selectedDevice!.frequency === '2'
                    ? '2 Days'
                    : selectedDevice!.frequency === '3' ||
                      selectedDevice!.frequency === '7'
                    ? 'Weekly'
                    : ''}
                </strong>{' '}
                ; Asset Status : <strong> {getStatusOptionDisplayText(selectedDevice!.status)} </strong>
              </Segment>
            )}

            <Table stackable sortable style={{ display: 'block' }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    width={1}
                    sorted={
                      backupSortingParams.sortField === 'deviceSN'
                        ? backupSortingParams.sortAscending === 'true'
                          ? 'ascending'
                          : 'descending'
                        : undefined
                    }
                    onClick={() => applySort('deviceSN')}
                  >
                    Device SN
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={2}
                    sorted={
                      backupSortingParams.sortField === 'computerName'
                        ? backupSortingParams.sortAscending === 'true'
                          ? 'ascending'
                          : 'descending'
                        : undefined
                    }
                    onClick={() => applySort('computerName')}
                  >
                    Computer Name
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={3}
                    sorted={
                      backupSortingParams.sortField === 'insertDate'
                        ? backupSortingParams.sortAscending === 'true'
                          ? 'ascending'
                          : 'descending'
                        : undefined
                    }
                    onClick={() => applySort('insertDate')}
                  >
                    Report Date
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className=""
                    width={3}
                    sorted={
                      backupSortingParams.sortField === 'statusName'
                        ? backupSortingParams.sortAscending === 'true'
                          ? 'ascending'
                          : 'descending'
                        : undefined
                    }
                    onClick={() => applySort('statusName')}
                  >
                    Status
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {backups.map((backup: Backup) => (
                  <Table.Row key={backup.idAuto} className="tableRow">
                    <Table.Cell>{backup.deviceSN}</Table.Cell>
                    <Table.Cell>{backup.computerName}</Table.Cell>
                    <Table.Cell>{backup.insertDate}</Table.Cell>
                    <Table.Cell
                      className={
                        backup.status === ReportStatusOptions.AttackDetected
                          ? 'statusCriticalError'
                          : backup.status === ReportStatusOptions.BackupFinished
                          ? 'statusBackupSuccess'
                          : ''
                      }
                      textAlign="left"
                    >
                      {backup.statusName}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </GridColumn>
        </GridRow>
        <GridRow>
          <FormPaging
            currentPage={backupPagination.currentPage}
            totalPages={backupPagination.totalPages}
            onPageChange={runPaging}
          />
        </GridRow>
        {showReportsFilter && (
          <div ref={refFilterBox}>
            <LogsFilter
              hideFilter={showHideFilter}
              filtersState={filterState}
              setFiltersState={setFilterState}
            />
          </div>
        )}
      </Grid>
    </>
  );
});
