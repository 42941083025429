import { observer } from 'mobx-react-lite';
import React from 'react'
import {   Grid,GridRow , Table} from 'semantic-ui-react'
import { useStore } from '../../api/stores/store';
import FilterStore from '../../api/stores/filterStore';
import {  CruTextOptions } from '../../api/models/enums';



interface SearchTextBoxProperties {
    handlSelectUnit: (id:number, type:number, name:string) => void;
}

export default observer(function SearchTextBox(props: SearchTextBoxProperties) {
      const { filterStore } = useStore();
      const {cruTexts} = filterStore
  return (
      <Grid className='textSearchBox'  >
               <GridRow >
               <Table   stackable   sortable   >
                    <Table.Body>
                                {cruTexts.map((cru:any) => (
                                      <Table.Row key={cru.idAuto} onClick={() => props.handlSelectUnit(cru.idAuto,cru.type, cru.searchTxt)}   >
                                            <Table.Cell  >
                                                 {cru.searchTxt}<br/>
                                                  <span className='addInfo'>{cru.type===CruTextOptions.SerialNumber?'Device SN':cru.type===CruTextOptions.ComputerName?'Computer':'Company'}</span></Table.Cell>
                                      </Table.Row>
                                ))}
                       </Table.Body>
              </Table>
            </GridRow>
            </Grid>
   );
});

