import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import {  Form, Formik} from 'formik';
import LoginPart from './MLoginPart';
import ForgotPasswordPart from './MForgotPasswordPart';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../api/stores/store';

export default observer(function MTabLogin() {
  const { accountStore } = useStore();
  const{resetPassword} = accountStore;
  const [sendingReset,setSendingReset] = useState(false);
  const navigate = useNavigate();
  return (
    <Formik
      initialValues={{ email: '', password: '', username:'',error: null }}
      onSubmit={(values,  { setErrors,setStatus}) =>{
        if (resetPassword) {
                setSendingReset(true);
                accountStore.sendResetPassword(values) 
                .then(res =>{
                  setStatus({sent: true, msg: "The Email Has Been Sent Successfully" });
                  setSendingReset(false);
                })
                .catch((error) =>  {
                  setStatus({sent: false, msg:error });
                  setSendingReset(false);
                })
               
        } else {
              accountStore.login(values).then(() => navigate('/devicesDashBoard'))
              .catch((error) => setErrors({ error: 'Invalid Email or Password' }))
        }
      }}
    >
      {({ handleSubmit,  errors,status }) => (
        <Form className="ui form" onSubmit={handleSubmit} autoComplete="off">
              {!resetPassword  ? (
                  <LoginPart  errors={errors} />
              ) : 
                  <ForgotPasswordPart status={status} notActive={sendingReset}/>}
        </Form>
      )}
    </Formik>
  );
});
