import { observer } from 'mobx-react-lite';
import React  from 'react';
import {  useNavigate } from 'react-router-dom';
import {Button, Menu, Image} from 'semantic-ui-react';
import { useStore } from '../../../api/stores/store';

export default observer(function MNavBar() {
  const { accountStore  } = useStore();
   const navigate = useNavigate();
  
  function logoutUser() {
    accountStore.logout();
    navigate('/login');
  }

  return (
    <Menu inverted fixed="top" color='black' className={'fixedTopMenuMobile'}>
       <Image src='/img/V1.55/NavBackup.png' size='medium' />

            <Menu.Item>
            <Button
                circular
                size='mini'
                onClick={logoutUser}
                text="Logout"
                icon="power"
                floated="right"
            />
       </Menu.Item>

  </Menu>
   
  );
});
